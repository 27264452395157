import { Button, Checkbox, Col, Popover, Result, Row, Table, Tag } from "antd";
import React from "react";
import Menu from "../../../components/menu/Menu";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";
import { Icon } from "@iconify/react";
import Header from "../../../components/reuse/Header";
import { useLocation, useNavigate } from "react-router-dom";
import "./ReportLocation.scss";
import { FilterOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import useSelection from "antd/es/table/hooks/useSelection";
import {
  getReportLocation,
  getReportLocationCount,
  showReportLocation,
  showReportLocationCount,
} from "../../../features/toiletManagerSlice";

//popover filter
const content = () => {
  return (
    <div className="rating-each-toilet-filter-container">
      <p className="rating-each-toilet-filter-header-text">
        Lọc theo đánh giá kém
      </p>
      <div className="rating-each-toilet-filter-checkbox">
        <Checkbox>Nhà vệ sinh không tồn tại</Checkbox>
      </div>
      <div className="rating-each-toilet-filter-checkbox">
        <Checkbox>Nhà vệ sinh không mở cửa</Checkbox>
      </div>
      <div className="rating-each-toilet-filter-checkbox">
        <Checkbox>Nhà vệ sinh không đúng vị trí định vị</Checkbox>
      </div>
      <p className="rating-each-toilet-filter-header-text-star">
        Lọc theo hiện trạng
      </p>
      <div className="rating-each-toilet-filter-checkbox">
        <Checkbox>Đã giải quyết</Checkbox>
      </div>
      <div className="rating-each-toilet-filter-checkbox">
        <Checkbox>Chưa giải quyết</Checkbox>
      </div>
      <div className="rating-each-toilet-filter-checkbox">
        <Checkbox>Không giải quyết</Checkbox>
      </div>
      <div className="rating-each-toilet-filter-btn-container">
        <Button type="text">Hủy</Button>
        <Button type="primary">Lọc</Button>
      </div>
    </div>
  );
};

function ReportLocation(props) {
  const [current, setCurrent] = useState(1);
  const [screenSize, getDimension] = useState(window.innerWidth);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const dataReportLocation = useSelector(showReportLocation);
  const dataReportLocationCount = useSelector(showReportLocationCount);
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${dataReportLocationCount}`;

  //pagination
  const onChange = (page) => {
    setCurrent(page);
  };

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  //call api
  useEffect(() => {
    const dataLocation = {
      id: state.id,
      pageIndex: current,
    };
    dispatch(getReportLocation(dataLocation));
    dispatch(getReportLocationCount(state.id));
  }, [current]);

  console.log(dataReportLocation);

  //cột table
  const ManageReportLocationColumn = [
    {
      title: "STT",
      dataIndex: "stt",
      ellipsis: true,
      width: "8%",
      align: "center",
    },
    {
      title: "Ngày",
      dataIndex: "createDate",
      ellipsis: true,
      width: "24%",
    },
    {
      title: "Nội dung",
      dataIndex: "message",
      ellipsis: true,
      width: "48%",
    },
    {
      title: "Giải quyết",
      width: "20%",
      dataIndex: "status",
      ellipsis: true,
      align: "center",
      render: (item) => {
        return (
          <div>
            {item !== null ? (
              item === "Đã đồng ý" ? (
                <Tag color="processing">{item}</Tag>
              ) : item === "Đã từ chối" ? (
                <Tag color="error">{item}</Tag>
              ) : (
                <div className="report-location-container">
                  <CheckCircleOutlined className="report-location-check" />
                  <CloseCircleOutlined
                    className="report-location-close"
                    onClick={() =>
                      swal({
                        title: "Xác nhận",
                        text: "Bạn có chắc chắn muốn từ chối giải quyết báo cáo này không?",
                        icon: "warning",
                        buttons: ["Hủy", "Xác nhận"],
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          // dispatch(handleUpdateRating("Từ chối giải quyết"));
                        } else {
                        }
                      })
                    }
                  />
                </div>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="report-each-toilet-header">
        {props.report === 0 ? (
          <p>Chưa có báo cáo</p>
        ) : (
          // <p>Tổng lượt báo cáo {props.report} lượt</p>
          <p>Tổng lượt báo cáo: {dataReportLocationCount} lượt</p>
        )}
        <Popover placement="bottomLeft" content={content} trigger="click">
          <Button type="default">
            <FilterOutlined /> Bộ lọc
          </Button>
        </Popover>
      </div>
      {screenSize <= 1024 ? (
        <Table
          size="small"
          locale={locale}
          scroll={{x: '100vw'}}
          className="table"
          bordered
          columns={ManageReportLocationColumn}
          dataSource={dataReportLocation.map((item, index) => {
            return {
              stt: current === 1 ? index + 1 : (current - 1) * 10 + (index + 1),
              createDate: item.createDate,
              message: item.message,
              status: item.status,
              id: item.id,
            };
          })}
          pagination={{
            current: current,
            showTotal: showTotal,
            onChange: onChange,
            position: ["bottomCenter"],
          }}
        />
      ) : (
        <Table
          size="small"
          className="table"
          locale={locale}
          bordered
          columns={ManageReportLocationColumn}
          dataSource={dataReportLocation.map((item, index) => {
            return {
              stt: current === 1 ? index + 1 : (current - 1) * 10 + (index + 1),
              createDate: item.createDate,
              message: item.message,
              status: item.status,
              id: item.id,
            };
          })}
          pagination={{
            current: current,
            showTotal: showTotal,
            onChange: onChange,
            position: ["bottomCenter"],
          }}
        />
      )}
    </div>
  );
}

export default ReportLocation;

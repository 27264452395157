import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import configApi from '../service/configApi'
import jwt_decode from 'jwt-decode'

const URL = `https://toiletmap.azurewebsites.net/api`

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ username, password, remember }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const { data } = await configApi.post(
        `/auth`,
        { username, password },
        config
      )
      // store user's token in local storage
      console.log(data)
      localStorage.setItem('userToken', data.data.accessToken)
      localStorage.setItem('role', jwt_decode(data.data.accessToken).role)
      localStorage.setItem('accountId', jwt_decode(data.data.accessToken).sub)
      localStorage.setItem(
        'usernameAcc',
        jwt_decode(data.data.accessToken).username
      )
      localStorage.setItem('Exp', jwt_decode(data.data.accessToken).exp)
      // localStorage.setItem("comId", jwt_decode(data.data.accessToken).username);
      if (remember) {
        localStorage.setItem('username', username)
        localStorage.setItem('password', password)
        localStorage.setItem('remember', remember)
      } else {
        localStorage.removeItem('username')
        localStorage.removeItem('password')
        localStorage.removeItem('remember')
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userRole: null,
    userAccId: null,
    userToken: null,
    usernameAcc: null,
    status: 'idle'
  },
  reducers: {
    logout: state => {
      state.userToken = localStorage.removeItem('userToken')
      state.userRole = localStorage.removeItem('role')
      state.usernameAcc = localStorage.removeItem('usernameAcc')
      state.userAccId = localStorage.removeItem('accountId')
      localStorage.removeItem('comId')
      localStorage.removeItem('Exp')
    }
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state, { payload }) => {
      state.status = 'loading'
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded'
      state.userRole = localStorage.getItem('role')
      state.userAccId = localStorage.getItem('accountId')
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.status = 'error'
    }
  }
})

export default authSlice.reducer
export const showRole = state => state.auth.userRole
export const showStatus = state => state.auth.status
export const { logout } = authSlice.actions

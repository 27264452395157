import { Button, Input } from "antd";
import React, { useState } from "react";
import "./ConfigInfor.scss";

function ConfigAccountAdmin() {
  const [changePass, setChangePass] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  return (
    <div className="config-account-admin-container">
      {changePass ? (
        <div>
          <div className="config-account-admin-input-container">
            <p>Tài khoản</p>
            <Input
              className="config-account-admin-input"
              value="dvvsq1"
              disabled
            />
          </div>
          <div className="config-account-admin-input-container">
            <p>Mật khẩu cũ</p>
            <Input.Password
              placeholder="Nhập mật khẩu cũ"
              className="config-account-admin-input"
              type="password"
            />
          </div>
          <div className="config-account-admin-input-container">
            <p>Mật khẩu mới</p>
            <Input.Password
              placeholder="Nhập mật khẩu mới"
              className="config-account-admin-input"
              type="password"
            />
          </div>
          <div className="config-account-admin-input-container">
            <p>Xác nhận mật khẩu mới</p>
            <Input.Password
              placeholder="Xác nhận mật khẩu mới"
              className="config-account-admin-input"
              type="password"
            />
          </div>
          <div className="config-account-admin-btn-edit-container">
            <Button
              onClick={() => setChangePass(false)}
              className="config-account-admin-btn config-account-admin-save-btn"
              type="text"
            >
              Trở về
            </Button>
            <Button
              onClick={() => setChangePass(false)}
              className="config-account-admin-btn config-account-admin-save-btn"
              type="primary"
            >
              Lưu
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="config-account-admin">
            <p>Tài khoản</p>
            <p>ADMIN</p>
          </div>
          {/* <div className="config-account-admin">
            <p>Mật khẩu</p>
            <p>****</p>
          </div> */}
          <div className="config-account-admin-btn-container">
            <Button
              onClick={() => setChangePass(true)}
              className="config-account-admin-btn"
              type="primary"
            >
              Đổi mật khẩu
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfigAccountAdmin;

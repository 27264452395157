import { Icon, InlineIcon } from "@iconify/react";
import React from "react";
import '../../../manager/manage/ManageToilet.scss'
import { reportColumns, reportData } from "./ReportEachToiletColumn";
import { DatePicker, Table } from "antd";
import { useRef } from "react";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { useState } from "react";
dayjs.locale("vi");

function ReportEachToiletUnit() {
  const tableRefReport = useRef(null);
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(-1, "year"), dayjs());
  const format = "DD/MM/YYYY";

  //action
  const onChange = (range) => {
    setStartDate(range[0].format(format));
    setEndDate(range[1].format(format));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRefReport.current,
    filename: "Tổng số tiền tạm thu nhà vệ sinh",
    sheet: "Revenue",
  });

  return (
    <div className="report-each-toilet-container">
      <div className="report-each-toilet">
        <div className="report-each-toilet-number-container">
          <div className="report-each-toilet-number-header">
            <p className="report-each-toilet-count-number">700</p>
            <Icon
              className="report-each-toilet-count-icon"
              icon="material-symbols:person-celebrate-rounded"
            />
          </div>
          <p className="report-each-toilet-number">Số lượt khách</p>
          <p className="report-each-toilet-rate">
            <InlineIcon
              className="report-each-toilet-icon"
              icon="solar:arrow-right-up-linear"
            />{" "}
            2.56% so với tháng trước
          </p>
        </div>
        <div className="report-each-toilet-number-container">
          <div className="report-each-toilet-number-header">
            <p className="report-each-toilet-count-number">110,000,000</p>
            <Icon
              className="report-each-toilet-count-icon"
              icon="material-symbols:person-celebrate-rounded"
            />
          </div>
          <p className="report-each-toilet-number">Tổng số tiền tạm thu</p>
          <p className="report-each-toilet-rate">
            <InlineIcon
              className="report-each-toilet-icon"
              icon="solar:arrow-right-up-linear"
            />{" "}
            2.56% so với tháng trước
          </p>
        </div>
      </div>
      <div className="report-each-toilet-content-excel-container">
        <RangePicker
          locale={locale}
          defaultValue={[dayjs(startDate, format), dayjs(endDate, format)]}
          placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
          className="statistic-table-header-date"
          format="DD/MM/YYYY"
          presets={[
            {
              label: "Tuần trước",
              value: [dayjs().add(-7, "d"), dayjs()],
            },
            {
              label: "Tháng trước",
              value: [dayjs().add(-1, "month"), dayjs()],
            },
            {
              label: "Năm trước",
              value: [dayjs().add(-1, "year"), dayjs()],
            },
          ]}
          onChange={onChange}
        />

        <button
          onClick={onDownload}
          className="report-each-toilet-content-excel-btn"
        >
          Xuất file excel số tiền tạm thu
        </button>
      </div>
      <Table
        ref={tableRefReport}
        className="report-each-toilet-content"
        columns={reportColumns}
        dataSource={reportData}
        pagination={false}
      />
    </div>
  );
}

export default ReportEachToiletUnit;

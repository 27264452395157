import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  theme,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import ModalReuse from "../../../components/reuse/ModalReuse";
import { Icon } from "@iconify/react";
import {
  deleteCommonComment,
  getCommonComment,
  postCommonComment,
  showCommonComment,
  showDelCommonComment,
  showNewCommonComment,
  showUpdateCommonComment,
  updateCommonComment,
} from "../../../features/adminSlice";

function ConfigTag() {
  const { token } = theme.useToken();
  const [tags, setTags] = useState(["Nhà vệ sinh dơ"]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const dispatch = useDispatch();
  const commonCommentData = useSelector(showCommonComment);
  const newCommonComment = useSelector(showNewCommonComment);
  const updateComment = useSelector(showUpdateCommonComment);
  const delComment = useSelector(showDelCommonComment);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [current, setCurrent] = useState(1);
  const [tagName, setTagName] = useState("");

  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //get Toilet type
  useEffect(() => {
    dispatch(getCommonComment());
  }, [newCommonComment, updateComment, delComment]);

  const handleDelCmt = (id) => {
    swal({
      title: "Xác nhận",
      text: "Bạn có chắc chắn muốn xóa danh mục đánh giá này không?",
      icon: "warning",
      buttons: ["Hủy", "Xác nhận"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteCommonComment(id));
      } else {
      }
    });
  };

  // const handleInputConfirm = (name) => {
  //   const data = {
  //     name: name,
  //   };
  //   dispatch(postCommonComment(data));
  //   setInputVisible(false);
  //   setInputValue("");
  // };

  // const handleEditInputConfirm = (id, name) => {
  //   const data = {
  //     name: name,
  //     id: id
  //   };
  //   dispatch(updateCommonComment(data));
  //   setEditInputIndex(-1);
  //   setEditInputValue("");
  // };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "16%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Thiết bị vệ sinh",
      dataIndex: "name",
      ellipsis: true,
      width: "50%",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            style={{ cursor: "pointer" }}
            // onClick={() =>  dispatch(deleteFacilityType(item)}
            color="#0B79D9"
            icon="material-symbols:edit"
          />
        );
      },
      width: "6%",
      align: "right",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelCmt(item)}
            icon="material-symbols:delete"
          />
        );
      },
      width: "6%",
      align: "right",
    },
  ];

  //handle modal change status
  const showModalChangeStatus = () => {
    setOpenChangeStatus(true);
  };

  const handleOkChangeStatus = () => {
    const data = {
      name: tagName,
    };
    dispatch(postCommonComment(data));
    setOpenChangeStatus(false);
  };

  const handleCancelChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  return (
    <div className="config-header" style={{ marginTop: "4%" }}>
      <p>Danh mục đánh giá</p>
      <div className="config-container">
        <div className="config-add-new">
          <p></p>
          <div className="config-add-new-button-container">
            <Button onClick={showModalChangeStatus} type="primary">
              Thêm danh mục đánh giá
            </Button>
          </div>
        </div>
        <Table
          bordered
          className="table"
          size="small"
          pagination={{
            showTotal: showTotal,
            position: ["bottomCenter"],
          }}
          columns={columns}
          dataSource={
            commonCommentData !== undefined
              ? commonCommentData.map((item, index) => {
                  return {
                    id: item.id,
                    name: item.name,
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 8 + (index + 1),
                  };
                })
              : ""
          }
        />
        {/* Modal change status*/}
        <ModalReuse
          open={openChangeStatus}
          title="Tạo danh mục đánh giá mới"
          handleOk={handleOkChangeStatus}
          handleCancel={handleCancelChangeStatus}
          titleOk="Tạo mới"
          width={400}
          children={
            <div className="config-change-date-service-container">
              <Row>
                <Col span={24}>
                  <label>Danh mục đánh giá</label>
                  <Input
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    placeholder="Nhập danh mục đánh giá"
                  />
                </Col>
              </Row>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ConfigTag;

import { Col, Row } from "antd";
import React from "react";
import Menu from "../../../components/menu/Menu";
import TagHeader from "../../../components/reuse/TagHeader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getInternalAdvertisementById, showSpecificInternalAdvertisement } from "../../../features/staffSlice";
import { useEffect } from "react";
import Breadcrumbs from "../../../components/reuse/Breadcrumbs";

function ManageInAppAdvertisementDetail() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showData = useSelector(showSpecificInternalAdvertisement);

  console.log(showData)

  useEffect(() => {
    dispatch(getInternalAdvertisementById(state.id));
  }, []);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleAdvertise="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <TagHeader
            header="header-line"
            title="Quản lý quảng cáo"
            display="none"
            displaySearch="none"
            label1="Quảng cáo khuyến mãi"
            label2="Quảng cáo Website"
            label3="Quảng cáo ứng dụng"
            defaultActiveKey="1"
            disabled2={true}
            disabled3={true}
            displayRightButton='none'
            children1={
              <div>
                <Breadcrumbs onRemove={() => navigate(-1)} pageName="Thông tin quảng cáo"/>
                <div className="manage-ads-details-container">
                  <div>
                    {showData !== {} ? (
                      <img
                        className="manage-ad-detail-img"
                        src={showData.imageSource}
                        alt="Hình quảng cáo"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {showData !== {} ? (
                      <div>
                        <div className="manage-ad-detail-content">
                          <p>Tiêu đề</p>
                          <p>{showData.title}</p>
                        </div>
                        <div className="manage-ad-detail-content">
                          <p>Mô tả</p>
                          <p>{showData.description}</p>
                        </div>
                        <div className="manage-ad-detail-content">
                          <p>Thời gian áp dụng</p>
                          <p>Từ {showData.startDate} đến {showData.endDate}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
    </div>
  );
}

export default ManageInAppAdvertisementDetail;

import { InlineIcon } from "@iconify/react";
import { Breadcrumb } from "antd";
import React from "react";
import "../reuse/Breadcrumbs.scss";
import { useNavigate } from "react-router-dom";

function Breadcrumbs(props) {
  const navigate = useNavigate();
  return (
    <div className="breadcrumbs-container">
      <InlineIcon
        className="breadcrumbs-icon"
        icon="material-symbols:arrow-back-rounded"
        onClick={() => props.onRemove()}
      />
      <Breadcrumb
        className="breadcrumbs-text"
        separator={
          <InlineIcon
            className="breadcrumbs-icon"
            icon="material-symbols:chevron-right-rounded"
            style={{ display: props.displayRight }}
          />
        }
        items={[
          {
            title: props.title,
            href: "",
          },
          {
            title: props.pageName,
          },
        ]}
      />
    </div>
  );
}

export default Breadcrumbs;

import {
  Button,
  Col,
  Popconfirm,
  Popover,
  Result,
  Row,
  Table,
  Tag,
  message,
} from "antd";
import React from "react";
import Menu from "../../../components/menu/Menu";
import Header from "../../../components/reuse/Header";
import { ExclamationCircleTwoTone, ExportOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecommend, showRecommendData } from "../../../features/adminSlice";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { RecommendColumn } from "./RecommendColumn";
import "./Recommend.scss";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
  ExcelExportGroupHeader,
} from "@progress/kendo-react-excel-export";
import { Icon } from "@iconify/react";
import { TITLE7 } from "../../../Constant";

function Recommend() {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const tableRef = useRef(null);
  const [current, setCurrent] = useState(1);
  const dispatch = useDispatch();
  const fileName = "Gợi ý mở thêm nhà vệ sinh";
  const dataRecommend = useSelector(showRecommendData);
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //export excel
  const _exporter = React.createRef();
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  //config empty table
  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  //table field
  const ManageRecommendColumn = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
      fixed: 'left',
    },
    {
      title: "Tên nhà vệ sinh",
      dataIndex: "name",
      width: "30%",
      ellipsis: true,
      fixed: 'left',
    },
    {
      title: "Địa chỉ",
      dataIndex: "ad",
      width: "42%",
      ellipsis: true,
    },
    {
      title: "Gợi ý",
      dataIndex: "suggestions",
      width: "22%",
      ellipsis: true,
      align: "center",
      render: (item, record) =>
        item !== null ? (
          item[0].isAccepted === null || item[1].isAccepted === null ? (
            <Popconfirm
              title={`Gợi ý cho Quý ${dayjs().quarter()} - ${dayjs().year()}`}
              icon={
                <ExclamationCircleTwoTone
                  twoToneColor="#faad14"
                  className="statistic-advice-icon"
                />
              }
              description={
                item !== null ? (
                  <div className="manage-toilet-suggestion-each-quarter-container">
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" && record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <p>{item[0].message}</p>
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" ||  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" || record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <p className="manage-toilet-suggestion-each-quarter-header">
                        Đã <strong>quá 4 quý</strong> bạn không xây nhà vệ sinh
                        mới, chúng tôi sẽ không thực hiện nhắc nhở nữa!
                      </p>
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" ? (
                      <p className="manage-toilet-suggestion-each-quarter-header">
                        Số liệu vượt ngưỡng{" "}
                        <strong>{record.suggestionMessage}</strong>
                      </p>
                    ) : (
                      ""
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" && record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <div>
                        <p className="manage-toilet-suggestion-each-quarter-header">
                          <strong>Trung bình 2 quý gần đây</strong>
                        </p>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[0].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[1].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~{" "}
                              {handleConvertNumber(
                                (item[0].expectedCount +
                                  item[1].expectedCount) /
                                  2
                              )}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~{" "}
                              {handleConvertNumber(
                                (item[0].actualCount + item[1].actualCount) / 2
                              )}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <p className="manage-toilet-suggestion-each-quarter-header">
                          <strong>Cụ thể</strong>
                        </p>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[1].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[1].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[1].expectedCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[1].actualCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[0].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[0].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[0].expectedCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[0].actualCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )
              }
              okText="Đóng"
              showCancel={false}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
            >
              {record.suggestionMessage === "Vượt ngưỡng 2 quý liên tục" ? (
                <Tag style={{ cursor: "pointer" }} color="yellow">
                  {record.suggestionMessage}
                </Tag>
              ) : record.suggestionMessage === "Vượt ngưỡng 3 quý liên tục" ? (
                <Tag style={{ cursor: "pointer" }} color="orange">
                  {record.suggestionMessage}
                </Tag>
              ) : (
                <Tag style={{ cursor: "pointer" }} color="volcano">
                  {record.suggestionMessage}
                </Tag>
              )}
            </Popconfirm>
          ) : item[0].isAccepted === true || item[1].isAccepted === true ? (
            <Popconfirm
              title={`Gợi ý cho Quý ${dayjs().quarter()} - ${dayjs().year()}`}
              icon={
                <ExclamationCircleTwoTone
                  twoToneColor="#faad14"
                  className="statistic-advice-icon"
                />
              }
              description={
                item !== null ? (
                  <div className="manage-toilet-suggestion-each-quarter-container">
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" && record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <p>{item[0].message}</p>
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" ||  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" || record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <p className="manage-toilet-suggestion-each-quarter-header">
                        Đã <strong>quá 4 quý</strong> bạn không xây nhà vệ sinh
                        mới, chúng tôi sẽ không thực hiện nhắc nhở nữa!
                      </p>
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" ? (
                      <p className="manage-toilet-suggestion-each-quarter-header">
                        Số liệu vượt ngưỡng{" "}
                        <strong>{record.suggestionMessage}</strong>
                      </p>
                    ) : (
                      ""
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" && record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <div>
                        <p className="manage-toilet-suggestion-each-quarter-header">
                          <strong>Trung bình 2 quý gần đây</strong>
                        </p>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[0].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[1].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~{" "}
                              {handleConvertNumber(
                                (item[0].expectedCount +
                                  item[1].expectedCount) /
                                  2
                              )}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~{" "}
                              {handleConvertNumber(
                                (item[0].actualCount + item[1].actualCount) / 2
                              )}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <p className="manage-toilet-suggestion-each-quarter-header">
                          <strong>Cụ thể</strong>
                        </p>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[1].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[1].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[1].expectedCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[1].actualCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[0].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[0].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[0].expectedCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[0].actualCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )
              }
              okText="Đóng"
              showCancel={false}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
            >
              <Tag style={{ cursor: "pointer" }} color="green">
                {record.suggestionMessage}
              </Tag>
            </Popconfirm>
          ) : item[0].isAccepted === false || item[1].isAccepted === false ? (
            <Popconfirm
              title={`Gợi ý cho Quý ${dayjs().quarter()} - ${dayjs().year()}`}
              icon={
                <ExclamationCircleTwoTone
                  twoToneColor="#faad14"
                  className="statistic-advice-icon"
                />
              }
              description={
                item !== null ? (
                  <div className="manage-toilet-suggestion-each-quarter-container">
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" && record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <p>{item[0].message}</p>
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" ||  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" || record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" || record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <p className="manage-toilet-suggestion-each-quarter-header">
                        Đã <strong>quá 4 quý</strong> bạn không xây nhà vệ sinh
                        mới, chúng tôi sẽ không thực hiện nhắc nhở nữa!
                      </p>
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" ? (
                      <p className="manage-toilet-suggestion-each-quarter-header">
                        Số liệu vượt ngưỡng{" "}
                        <strong>{record.suggestionMessage}</strong>
                      </p>
                    ) : (
                      ""
                    )}
                    {record.suggestionMessage !== "Vượt ngưỡng 2 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&  record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" && record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" && record.suggestionMessage !== "Vượt ngưỡng 4 quý liên tục" ? (
                      ""
                    ) : (
                      <div>
                        <p className="manage-toilet-suggestion-each-quarter-header">
                          <strong>Trung bình 2 quý gần đây</strong>
                        </p>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[0].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[1].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~{" "}
                              {handleConvertNumber(
                                (item[0].expectedCount +
                                  item[1].expectedCount) /
                                  2
                              )}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~{" "}
                              {handleConvertNumber(
                                (item[0].actualCount + item[1].actualCount) / 2
                              )}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <p className="manage-toilet-suggestion-each-quarter-header">
                          <strong>Cụ thể</strong>
                        </p>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[1].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[1].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[1].expectedCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[1].actualCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <p className="manage-toilet-suggestion-each-quarter">
                          Từ{" "}
                          <strong>
                            {handleDisplayDate(item[0].startDate)}
                          </strong>{" "}
                          đến{" "}
                          <strong>{handleDisplayDate(item[0].endDate)}</strong>
                        </p>
                        <div className="manage-toilet-suggestion-container">
                          <p>Ước tính</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[0].expectedCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                        <div className="manage-toilet-suggestion-container">
                          <p>Thực tế</p>
                          <p>
                            <strong>
                              ~ {handleConvertNumber(item[0].actualCount)}
                            </strong>{" "}
                            lượt
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )
              }
              okText="Đóng"
              showCancel={false}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
            >
              <Tag color="red" style={{ cursor: "pointer" }}>
                {record.suggestionMessage}
              </Tag>
              {/* <WarningTwoTone
                twoToneColor="#f78022"
                className="statistic-advice-icon"
              /> */}
            </Popconfirm>
          ) : (
            ""
          )
        ) : (
          ""
        ),
    },
  ];

  //convert number of turn to ~K
  const handleConvertNumber = (num, precision = 0) => {
    const map = [
      { suffix: "T", threshold: 1e12 },
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];
    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted =
        (num / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }
    return num;
  };

  //handle document title
  useEffect(() => {
    document.title = TITLE7;
  }, [])

  //handle date display
  const handleDisplayDate = (date) => {
    return dayjs(date).format("DD-MM-YYYY");
  };

  //Get Data
  useEffect(() => {
    dispatch(getRecommend());
  }, []);

  //pagination
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleRecommend="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="manage-toilet-unit-container">
            <Header
              header="header-no-line"
              title="Quản lý gợi ý"
              displaySearch="none"
              btn={
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "0",
                  }}
                >
                  <Icon
                    icon="clarity:export-solid"
                    style={{ marginRight: "8px" }}
                  />{" "}
                  Xuất file excel
                </span>
              }
              displayPlus="none"
              onClick={excelExport}
            />
            <ExcelExport
              data={dataRecommend.map((item, index) => {
                return {
                  stt:
                    current === 1
                      ? index + 1
                      : (current - 1) * 10 + (index + 1),
                  name: item.name,
                  ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                  suggestionMessage: item.suggestionMessage,
                  suggestions: item.suggestions[0].message,
                  expectedAverage: (
                    (item.suggestions[0].expectedCount +
                      item.suggestions[1].expectedCount) /
                    2
                  ).toLocaleString("en-US"),
                  actualAverage: (
                    (item.suggestions[0].actualCount +
                      item.suggestions[1].actualCount) /
                    2
                  ).toLocaleString("en-US"),
                  suggestions: item.suggestions[0].message,
                  startDateAverage: handleDisplayDate(
                    item.suggestions[0].startDate
                  ),
                  endDateAverage: handleDisplayDate(
                    item.suggestions[1].endDate
                  ),
                  startFirstDate: handleDisplayDate(
                    item.suggestions[0].startDate
                  ),
                  endFirstDate: handleDisplayDate(item.suggestions[0].endDate),
                  startSecondDate: handleDisplayDate(
                    item.suggestions[1].startDate
                  ),
                  endSecondDate: handleDisplayDate(item.suggestions[1].endDate),
                  expectedFirstQuarter:
                    item.suggestions[0].expectedCount.toLocaleString("en-US"),
                  expectedSecondQuarter:
                    item.suggestions[1].expectedCount.toLocaleString("en-US"),
                  actualFirstQuarter:
                    item.suggestions[0].actualCount.toLocaleString("en-US"),
                  actualSecondQuarter:
                    item.suggestions[1].actualCount.toLocaleString("en-US"),
                };
              })}
              fileName="Gợi ý mở thêm nhà vệ sinh.xlsx"
              ref={_exporter}
            >
              <ExcelExportColumnGroup
                title="BÁO CÁO THỐNG KÊ CỦA NHỮNG NHÀ VỆ SINH VƯỢT NGƯỠNG"
                cellOptions={{
                  borderBottom: "1px solid #000",
                  borderRight: "1px solid #000",
                  borderTop: "1px solid #000",
                  borderLeft: "1px solid #000",
                }}
                headerCellOptions={{
                  textAlign: "center",
                  verticalAlign: "center",
                  borderBottom: "1px solid #000",
                  borderRight: "1px solid #000",
                  borderTop: "1px solid #000",
                  borderLeft: "1px solid #000",
                  background: "#ADADAD",
                  bold: true,
                  fontSize: 16,
                }}
              >
                <ExcelExportColumn
                  field="stt"
                  title="STT"
                  cellOptions={{
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    textAlign: "center",
                  }}
                  headerCellOptions={{
                    textAlign: "center",
                    verticalAlign: "center",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    background: "#4096FF",
                    bold: true,
                    fontSize: 16,
                  }}
                />
                <ExcelExportColumn
                  field="name"
                  title="Tên nhà vệ sinh"
                  width={300}
                  cellOptions={{
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                  }}
                  headerCellOptions={{
                    textAlign: "center",
                    verticalAlign: "center",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    background: "#4096FF",
                    bold: true,
                    fontSize: 16,
                  }}
                />
                <ExcelExportColumn
                  field="ad"
                  title="Địa chỉ"
                  cellOptions={{
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                  }}
                  headerCellOptions={{
                    textAlign: "center",
                    verticalAlign: "center",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    background: "#4096FF",
                    bold: true,
                    fontSize: 16,
                  }}
                />
                <ExcelExportColumn
                  field="suggestionMessage"
                  title="Số quý vượt ngưỡng"
                  width={200}
                  cellOptions={{
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                  }}
                  headerCellOptions={{
                    textAlign: "center",
                    verticalAlign: "center",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    background: "#4096FF",
                    bold: true,
                    fontSize: 16,
                  }}
                />
                <ExcelExportColumn
                  field="suggestions"
                  title="Gợi ý"
                  cellOptions={{
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                  }}
                  headerCellOptions={{
                    textAlign: "center",
                    verticalAlign: "center",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    background: "#4096FF",
                    bold: true,
                    fontSize: 16,
                  }}
                />
                <ExcelExportColumnGroup
                  title="Trung bình 2 quý gần đây (lượt)"
                  cellOptions={{
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                  }}
                  headerCellOptions={{
                    textAlign: "center",
                    verticalAlign: "center",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    background: "#4096FF",
                    bold: true,
                    fontSize: 16,
                  }}
                >
                  <ExcelExportColumn
                    field="startDateAverage"
                    title="Ngày bắt đầu quý"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="endDateAverage"
                    title="Ngày kết thúc quý"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="expectedAverage"
                    title="Sức chứa ước tính"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="actualAverage"
                    title="Lượt đi thực tế"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                </ExcelExportColumnGroup>
                <ExcelExportColumnGroup
                  title="Quý thứ nhất"
                  cellOptions={{
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                  }}
                  headerCellOptions={{
                    textAlign: "center",
                    verticalAlign: "center",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    background: "#4096FF",
                    bold: true,
                    fontSize: 16,
                  }}
                >
                  <ExcelExportColumn
                    field="startFirstDate"
                    title="Ngày bắt đầu quý"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="endFirstDate"
                    title="Ngày kết thúc quý"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="expectedFirstQuarter"
                    title="Sức chứa ước tính"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="actualFirstQuarter"
                    title="Lượt đi thực tế"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                </ExcelExportColumnGroup>
                <ExcelExportColumnGroup
                  title="Quý thứ hai"
                  cellOptions={{
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                  }}
                  headerCellOptions={{
                    textAlign: "center",
                    verticalAlign: "center",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    background: "#4096FF",
                    bold: true,
                    fontSize: 16,
                  }}
                >
                  <ExcelExportColumn
                    field="startSecondDate"
                    title="Ngày bắt đầu quý"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="endSecondDate"
                    title="Ngày kết thúc quý"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="expectedSecondQuarter"
                    title="Sức chứa ước tính"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                  <ExcelExportColumn
                    field="actualSecondQuarter"
                    title="Lượt đi thực tế"
                    width={200}
                    cellOptions={{
                      textAlign: "right",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                    }}
                    headerCellOptions={{
                      textAlign: "right",
                      verticalAlign: "center",
                      borderBottom: "1px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderLeft: "1px solid #000",
                      background: "#4096FF",
                      bold: true,
                      fontSize: 16,
                    }}
                  />
                </ExcelExportColumnGroup>
              </ExcelExportColumnGroup>
            </ExcelExport>
            {screenSize < 1024 ? (
              <Table
                size="small"
                bordered
                locale={locale}
                scroll={{
                  x: "120vw",
                }}
                className="table"
                columns={ManageRecommendColumn}
                dataSource={dataRecommend.map((item, index) => {
                  return {
                    id: item.id,
                    name: item.name,
                    isAccepted: item.isAccepted,
                    suggestions: item.suggestions,
                    suggestionMessage: item.suggestionMessage,
                    ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  pageSize: 10,
                  // total: 10,
                  position: ["bottomCenter"],
                }}
              />
            ) : (
              <Table
                bordered
                className="table"
                locale={locale}
                size="small"
                columns={ManageRecommendColumn}
                dataSource={dataRecommend.map((item, index) => {
                  return {
                    id: item.id,
                    name: item.name,
                    isAccepted: item.isAccepted,
                    suggestionMessage: item.suggestionMessage,
                    suggestions: item.suggestions,
                    ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  pageSize: 10,
                  // total: 10,
                  position: ["bottomCenter"],
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Recommend;

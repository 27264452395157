import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getDistrictByProvince = createAsyncThunk(
  "district/getDistrict",
  async () => {
    const response = await axios.get(
      `https://vn-public-apis.fpo.vn/districts/getByProvince?provinceCode=79&limit=-1`,
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data.data;
  }
);

export const districtSlice = createSlice({
  name: "district",
  initialState: {
    data: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDistrictByProvince.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
  },
});

export default districtSlice.reducer;
export const showGetDistrictData = (state) => state.district.data;

import { Icon } from "@iconify/react";
import { Badge, Button, Popover } from "antd";
import { useNavigate } from "react-router-dom";

const HandleContent = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <p
        onClick={() => navigate("/manageEachToilet", {state: {id: props.id}})}
        style={{ cursor: "pointer" }}
        className="statistic-toilet-popover-see-infor"
      >
        Xem thông tin
      </p>
    </div>
  );
};

export const StatisticToiletUnitColumn = [
  {
    title: "STT",
    dataIndex: "stt",
    ellipsis: true,
    width: "6%",
    align: 'center',
    fixed: "left"
  },
  {
    title: "Tên đơn vị vệ sinh",
    dataIndex: "companyName",
    key: "companyName",
    width: "26%",
    ellipsis: true,
    fixed: "left"
  },
  {
    title: "Tổng số tiền tạm thu (VND)",
    dataIndex: "totalRevenue",
    key: "totalRevenue",
    width: "16%",
    align: "right",
    render: (item) => item === "0" ? <p style={{ textAlign: 'center', fontStyle: 'italic', opacity: '80%' }}>Chưa có dữ liệu</p> : <p>{item}</p>,
    onCell: (item) => ({
      colSpan: item.totalRevenue !== "0" ? 1 : 5,
      // console.log(item.totalRevenue)
    }),
  },
  {
    title: "Khách vãng lai",
    ellipsis: true,
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "walkInGuestRevenue",
        width: "14%",
        key: "walkInGuestRevenue",
        align: "right",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
      {
        title: "Số lượt khách",
        width: "12%",
        dataIndex: "walkInGuestCount",
        align: "right",
        key: "walkInGuestCount",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
    ],
  },
  {
    title: "Thành viên sử dụng gói",
    ellipsis: true,
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "usingTurnRevenue",
        align: "right",
        width: "14%",
        key: "usingTurnRevenue",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
      {
        title: "Số lượt khách",
        dataIndex: "usingTurnCount",
        width: "12%",
        key: "usingTurnCount",
        align: "right",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
    ],
  },
];


//for export excel
export const StatisticToiletUnitColumnExportExcel = [
  {
    title: "STT",
    dataIndex: "stt",
    ellipsis: true,
    width: "6%",
    sorter: (a, b) => a.stt.length - b.stt.length,
    align: 'center'
  },
  {
    title: "Tên đơn vị vệ sinh",
    dataIndex: "companyName",
    key: "companyName",
    sorter: (a, b) => a.companyName.length - b.companyName.length,
    width: "28%",
    ellipsis: true,
  },
  {
    title: "Tổng số tiền tạm thu (VND)",
    dataIndex: "totalRevenue",
    key: "totalRevenue",
    width: "12%",
    sorter: (a, b) => a.totalRevenue - b.totalRevenue,
    ellipsis: true,
    align: "right",
  },
  {
    title: "Khách vãng lai",
    ellipsis: true,
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "walkInGuestRevenue",
        width: "12%",
        ellipsis: true,
        key: "walkInGuestRevenue",
        sorter: (a, b) => a.walkInGuestRevenue - b.walkInGuestRevenue,
        align: "right",
      },
      {
        title: "Số lượt khách",
        width: "12%",
        dataIndex: "walkInGuestCount",
        ellipsis: true,
        align: "right",
        key: "walkInGuestCount",
      },
    ],
  },
  {
    title: "Thành viên sử dụng gói",
    ellipsis: true,
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "usingTurnRevenue",
        ellipsis: true,
        align: "right",
        width: "12%",
        key: "usingTurnRevenue",
      },
      {
        title: "Số lượt khách",
        dataIndex: "usingTurnCount",
        ellipsis: true,
        width: "12%",
        key: "usingTurnCount",
        align: "right",
      },
    ],
  },
];
import { Icon } from "@iconify/react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import CreateUpdateServicePrice from "./CreateUpdateServicePrice";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useEffect } from "react";
import ModalReuse from "../../../components/reuse/ModalReuse";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceToilet,
  postServiceToilet,
  showComStatus,
  showDataService,
  showStatusPostService,
  showUpdateTurnPriceService,
  updateTurnPriceServiceToilet,
} from "../../../features/adminSlice";

function ConfigServicePrice() {
  const [openNewService, setOpenNewService] = useState(false);
  const [openChangeDateService, setOpenChangeDateService] = useState(false);
  const [openChangePackageService, setOpenChangePackageService] =
    useState(false);
  const [price, setPrice] = useState([]);
  const [turnPrice, setTurnPrice] = useState();
  const [current, setCurrent] = useState(1);
  const dispatch = useDispatch();
  const data = useSelector(showDataService);
  const postStatus = useSelector(showStatusPostService);
  const updateTurnPrice = useSelector(showUpdateTurnPriceService);
  const status = useSelector(showComStatus);

  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  useEffect(() => {
    dispatch(getServiceToilet());
  }, [postStatus, updateTurnPrice]);

  useEffect(() => {
    setPrice(
      data.map((item) => {
        return {
          id: item.id,
          price: item.price,
          name: item.name,
          turnPrice: item.turnPrice,
        };
      })
    );
  }, [data]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "4%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Dịch vụ",
      dataIndex: "name",
      width: "8%",
      ellipsis: true,
    },
    {
      title: "Giá vãng lai (VND)",
      dataIndex: "price",
      align: "right",
      width: "6%",
      ellipsis: true,
    },
    {
      title: "Giá lượt (VND)",
      dataIndex: "turnPrice",
      align: "right",
      width: "6%",
      ellipsis: true,
    },
    {
      title: "Lượt",
      dataIndex: "turn",
      align: "right",
      width: "6%",
      ellipsis: true,
    },
  ];

  //handle modal new service
  const showModalNewService = () => {
    setOpenNewService(true);
  };

  const handleOkNewService = () => {
    setOpenNewService(false);
  };

  const handleCancelNewService = () => {
    setOpenNewService(false);
  };

  //handle modal change date service
  const showModalChangeDateService = () => {
    setOpenChangeDateService(true);
  };

  const handleCancelChangeDateService = () => {
    setOpenChangeDateService(false);
  };

  //handle modal change date service
  const showModalChangePackageService = () => {
    setOpenChangePackageService(true);
  };

  const handleCancelChangePackageService = () => {
    setOpenChangePackageService(false);
  };

  const updateServicePrice = () => {
    const newPrice = price.map((item) => {
      return {
        id: item.id,
        price: item.price,
      };
    });
    dispatch(postServiceToilet(newPrice));
  };

  useEffect(() => {
    if (status === "succeeded") {
      setOpenChangeDateService(false);
    }
  }, [status]);

  const handleServicePrice = (e, id) => {
    let result = [...price];
    result = result.map((x) => {
      //<- use map on result to find element to update using id
      if (x.id === id) x.price = Number(e.target.value);
      return x;
    });
    console.log(result);
    setPrice(result); //<- update price with value
  };

  //
  const handleUpdateTurnPrice = () => {
    const data = {
      turnPrice: turnPrice,
    };
    dispatch(updateTurnPriceServiceToilet(data));
    setOpenChangePackageService(false)
  };

  return (
    <div className="config-header">
      <p>Dịch vụ </p>
      <div className="config-container" style={{ minHeight: "290px" }}>
        <div className="config-add-new">
          <p className="config-infor-update"></p>
          <div className="config-add-new-button-container">
            <Button onClick={showModalChangePackageService} type="primary">
              Cập nhật giá lượt
            </Button>
            <Button onClick={showModalChangeDateService} type="primary">
              Cập nhật giá
            </Button>
          </div>
        </div>
        <Table
          bordered
          className="table"
          size="small"
          pagination={{
            pageSize: 10,
            showTotal: showTotal,
            position: ["bottomCenter"],
          }}
          columns={columns}
          dataSource={
            data.length !== 0
              ? data.map((item, index) => {
                  return {
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                    name: item.name,
                    price: item.price.toLocaleString("en"),
                    turnPrice: item.turnPrice.toLocaleString("en"),
                    turn: item.turn,
                    id: item.id,
                  };
                })
              : data
          }
        />
        {/* Modal change date*/}
        <ModalReuse
          open={openChangeDateService}
          title="Cập nhật giá dịch vụ"
          handleOk={() => updateServicePrice()}
          handleCancel={handleCancelChangeDateService}
          titleOk="Cập nhật"
          width={600}
          children={
            <div className="config-change-date-service-container">
              {price.length !== 0
                ? price.map((item) => {
                    return (
                      <div key={item.id}>
                        <Row gutter={[16, 16]}>
                          <Col span={16}>
                            <label>Dịch vụ</label>
                            <Input value={item.name} disabled />
                          </Col>
                          <Col span={8}>
                            <label>Giá</label>
                            <Input
                              suffix="VND"
                              value={item.price}
                              onChange={(e) => handleServicePrice(e, item.id)}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                : ""}
            </div>
          }
        />

        <ModalReuse
          open={openChangePackageService}
          title="Cập nhật giá dịch vụ của gói"
          handleOk={() => handleUpdateTurnPrice()}
          handleCancel={handleCancelChangePackageService}
          titleOk="Cập nhật"
          width={400}
          children={
            <div className="config-change-date-service-container">
              <div>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <label>Giá</label>
                    <Input
                      suffix="VND"
                      value={turnPrice}
                      onChange={(e) => setTurnPrice(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ConfigServicePrice;

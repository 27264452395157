export const reportColumns = [
  {
    title: "STT",
    dataIndex: "stt",
    width: "8%",
    align: "center",
    fixed: "left",
    ellipsis: true
  },
  {
    title: "Dịch vụ",
    dataIndex: "serviceName",
    key: "serviceName",
    width: "18%",
    ellipsis: true,
    fixed: "left"
  },
  {
    title: "Tổng số tiền tạm thu (VND)",
    dataIndex: "totalRevenue",
    // ellipsis: true,
    key: "totalRevenue",
    width: "20%",
    align: "right",
    render: (item) => item === "0" ? <p style={{ textAlign: 'center', fontStyle: 'italic', opacity: '80%' }}>Chưa có dữ liệu</p> : <p>{item}</p>,
    onCell: (item) => ({
      colSpan: item.totalRevenue !== "0" ? 1 : 5,
      // console.log(item.totalRevenue)
    }),
  },
  {
    title: "Khách vãng lai",
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "walkInGuestRevenue",
        // ellipsis: true,
        width: "16%",
        key: "walkInGuestRevenue",
        align: "right",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
      {
        title: "Số lượt khách",
        dataIndex: "walkInGuestCount",
        // ellipsis: true,
        width: "12%",
        key: "walkInGuestCount",
        align: "right",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
    ],
  },
  {
    title: "Thành viên sử dụng gói",
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        width: "16%",
        dataIndex: "usingTurnRevenue",
        key: "usingTurnRevenue",
        // ellipsis: true,
        align: "right",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
      {
        title: "Số lượt khách",
        dataIndex: "usingTurnCount",
        key: "usingTurnCount",
        width: "12%",
        // ellipsis: true,
        align: "right",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
    ],
  },
];

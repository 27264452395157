import { Button, Col, Popover, Radio, Row, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Menu from "../../../components/menu/Menu";
import TagHeader from "../../../components/reuse/TagHeader";
import Search from "antd/es/input/Search";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getExternalAdvertisement,
  getExternalAdvertisementCount,
  getExternalAppAdvertisement,
  getExternalAppAdvertisementCount,
  getInternalAdvertisement,
  getInternalAdvertisementCount,
  showExternalAdvertisement,
  showExternalAdvertisementCount,
  showExternalAppAdvertisement,
  showExternalAppAdvertisementCount,
  showInternalAdvertisement,
  showInternalAdvertisementCount,
} from "../../../features/staffSlice";
import { TITLE4 } from "../../../Constant";
//PopoverInApp
const HandleContentInApp = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <p
        onClick={() =>
          navigate(`inapp/${props.id}`, {
            state: { id: props.id },
          })
        }
        className="manage-advertisement-popover"
      >
        Xem thông tin
      </p>
    </div>
  );
};

//PopoverExApp (2)
const HandleContentExApp = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <p
        onClick={() =>
          navigate(`exapp/${props.id}`, {
            state: { id: props.id },
          })
        }
        className="manage-advertisement-popover"
      >
        Xem thông tin
      </p>
    </div>
  );
};

//PopoverExApp (3)
const HandleContentExAppNew = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <p
        onClick={() =>
          navigate(`ex/${props.id}`, {
            state: { id: props.id },
          })
        }
        className="manage-advertisement-popover"
      >
        Xem thông tin
      </p>
    </div>
  );
};

//Main function
function ViewAdvertisement() {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const dataInApp = useSelector(showInternalAdvertisement);
  const dataExApp = useSelector(showExternalAdvertisement);
  const dataExWebApp = useSelector(showExternalAppAdvertisement);
  const dataInAppCount = useSelector(showInternalAdvertisementCount);
  const dataExAppCount = useSelector(showExternalAdvertisementCount);
  const dataExWebAppCount = useSelector(showExternalAppAdvertisementCount);
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //handle document title
  useEffect(() => {
    document.title = TITLE4;
  }, [])

  //Table for ex Ads (2)
  const manageExAdColumns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      width: "24%",
      ellipsis: true,
    },
    {
      title: "Mô tả",
      ellipsis: true,
      width: "36%",
      dataIndex: "description",
    },
    {
      title: "Liên kết",
      ellipsis: true,
      width: "28%",
      dataIndex: "url",
      render: (text) => (
        <a target="_blank" href={text}>
          {text}
        </a>
      ),
    },
    {
      title: " ",
      dataIndex: "id",
      width: "8%",
      align: "right",
      ellipsis: true,
      render: (item, record) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={<HandleContentExApp id={item} />}
              trigger="click"
            >
              <Button className="manage-toilet-unit-btn-more">
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  //Table for ex (3)
  const manageExColumns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      width: "24%",
      ellipsis: true,
    },
    {
      title: "Mô tả",
      ellipsis: true,
      width: "36%",
      dataIndex: "description",
    },
    {
      title: "Liên kết",
      ellipsis: true,
      width: "28%",
      dataIndex: "url",
      render: (text) => (
        <a target="_blank" href={text}>
          {text}
        </a>
      ),
    },
    {
      title: " ",
      dataIndex: "id",
      width: "8%",
      align: "right",
      render: (item, record) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={<HandleContentExAppNew id={item} />}
              trigger="click"
            >
              <Button className="manage-toilet-unit-btn-more">
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  //Table for in Ads
  const manageInAdColumns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Mô tả",
      ellipsis: true,
      width: "36%",
      dataIndex: "description",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      width: "16%",
      ellipsis: true,
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "endDate",
      width: "16%",
      ellipsis: true,
    },
    {
      title: " ",
      dataIndex: "id",
      width: "8%",
      align: "right",
      render: (item, record) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={<HandleContentInApp id={item} />}
              trigger="click"
            >
              <Button className="manage-toilet-unit-btn-more">
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  //Action resize screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //Action for get pagination data when changing page
  const onChange = (page) => {
    setCurrent(page);
  };

  //Action get data in/ex app
  useEffect(() => {
    const dataInAds = {
      type: "Internal",
      pageIndex: current,
    };
    const dataExAds = {
      type: "External",
      pageIndex: current,
    };
    const dataExAppAds = {
      type: "External-App",
      pageIndex: current,
    };
    dispatch(getInternalAdvertisement(dataInAds));
    dispatch(getExternalAdvertisement(dataExAds));
    dispatch(getExternalAppAdvertisement(dataExAppAds));
    dispatch(getInternalAdvertisementCount("Internal"));
    dispatch(getExternalAdvertisementCount("External"));
    dispatch(getExternalAppAdvertisementCount("External-App"));
  }, [current]);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleAdvertise="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div>
            <TagHeader
              header="header-line"
              title="Quảng cáo"
              display="none"
              displaySearch="none"
              label1="Quảng cáo khuyến mãi"
              label2="Quảng cáo Website"
              label3="Quảng cáo ứng dụng"
              displayRightButton="none"
              children3={
                <div>
                  {screenSize <= 876 ? (
                    <Table
                      size="small"
                      style={{ marginTop: "0" }}
                      scroll={{ x: "140vw" }}
                      bordered
                      className="table"
                      columns={manageExColumns}
                      dataSource={dataExWebApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          url: item.url,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataExWebAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  ) : (
                    <Table
                      size="small"
                      style={{ marginTop: "0" }}
                      bordered
                      className="table"
                      columns={manageExColumns}
                      dataSource={dataExWebApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          url: item.url,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataExWebAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  )}
                </div>
              }
              children2={
                <div>
                  {screenSize <= 876 ? (
                    <Table
                      size="small"
                      style={{ marginTop: "0" }}
                      bordered
                      scroll={{ x: "140vw" }}
                      className="table"
                      columns={manageExAdColumns}
                      dataSource={dataExApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          url: item.url,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataExAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  ) : (
                    <Table
                      size="small"
                      style={{ marginTop: "0" }}
                      bordered
                      className="table"
                      columns={manageExAdColumns}
                      dataSource={dataExApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          url: item.url,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataExAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  )}
                </div>
              }
              children1={
                <div>
                  {screenSize <= 876 ? (
                    <Table
                      size="small"
                      bordered
                      style={{ marginTop: "0" }}
                      scroll={{ x: "140vw" }}
                      className="table"
                      columns={manageInAdColumns}
                      dataSource={dataInApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          startDate: item.startDate,
                          endDate: item.endDate,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataInAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  ) : (
                    <Table
                      size="small"
                      bordered
                      style={{ marginTop: "0" }}
                      className="table"
                      columns={manageInAdColumns}
                      dataSource={dataInApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          startDate: item.startDate,
                          endDate: item.endDate,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataInAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  )}
                </div>
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ViewAdvertisement;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toiletAPI from "../service/configApi.js";
import dayjs from "dayjs";

export const getToiletManager = createAsyncThunk(
  "toiletManager/getToilet",
  async (data) => {
    const response = await toiletAPI.get(
      `/toilets?company-id=${localStorage.getItem(
        "comId"
      )}&keyword=${data.keyword}&pageIndex=${data.pageIndex}&pageSize=10`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getToiletCountManager = createAsyncThunk(
  "toiletManager/getCountToilet",
  async (keyword) => {
    const response = await toiletAPI.get(
      `/toilets/count?company-id=${localStorage.getItem("comId")}&keyword=${keyword}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const updateStatusToilet = createAsyncThunk(
  "toiletManager/updateStatus",
  async (data) => {
    const response = await toiletAPI.patch(`/toilets/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    ;
    return response.data;
  }
);

export const updateRecommend = createAsyncThunk(
  "toiletManager/putRecommend",
  async (data) => {
    const response = await toiletAPI.put(
      `/suggestions/acceptance?suggestion-ids=${data.sugId1}&suggestion-ids=${data.sugId2}&is-accepted=${data.accepted}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    ;
    return response.data;
  }
);

export const getCheckinToilet = createAsyncThunk(
  "company/getToiletCheckin",
  async (pageIndex) => {
    const today = dayjs().format("DD/MM/YYYY");
    const threeDayAfter = dayjs().add(3, "day").format("DD/MM/YYYY");
    const response = await toiletAPI.get(
      `/check-in?company-id=${localStorage.getItem('comId')}&from-date=${today}&to-date=${threeDayAfter}&pageIndex=${pageIndex}&pageSize=10`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.data;
  }
);

export const getCheckinToiletCount = createAsyncThunk(
  "company/getToiletCheckinCount",
  async () => {
    const today = dayjs().format("DD/MM/YYYY");
    const threeDayAfter = dayjs().add(3, "day").format("DD/MM/YYYY");
    const response = await toiletAPI.get(`/check-in/count?company-id=${localStorage.getItem('comId')}&from-date=${today}&to-date=${threeDayAfter}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getRatingCountReport = createAsyncThunk(
  "toiletManager/getCountRating",
  async (toiletId) => {
    const response = await toiletAPI.get(
      `/ratings/count?toilet-id=${toiletId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getToiletInfor = createAsyncThunk(
  "toiletManager/getEachToiletInfor",
  async (id) => {
    const response = await toiletAPI.get(`/toilets/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const updateToiletInfor = createAsyncThunk(
  "toiletManager/patchEachToiletInfor",
  async (data) => {
    const response = await toiletAPI.patch(`/toilets/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    ;
    return response.data;
  }
);

export const updateToiletConfig = createAsyncThunk(
  "toiletManager/patchToiletConfig",
  async (data) => {
    const response = await toiletAPI.patch(`/toilets/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    ;
    return response.data;
  }
);

export const getReport = createAsyncThunk(
  "toiletManager/getReportData",
  async (data) => {
    const response = await toiletAPI.get(
      `/statistics?toilet-id=${data.id}&from-date=${data.startDate}&to-date=${data.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.data;
  }
);

export const getLocationReport = createAsyncThunk(
  "toiletManager/getReportLocation",
  async (data) => {
    const response = await toiletAPI.get(
      `/reports/${localStorage}?pageIndex=1&pageSize=10`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.data;
  }
);

export const getMonthReport = createAsyncThunk(
  "toiletManager/getMonthReportData",
  async (id) => {
    const response = await toiletAPI.get(
      `/statistics/total/month?toilet-id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );

    ;
    return response.data.data;
  }
);

export const getFacility = createAsyncThunk(
  "toiletManager/getFacilityData",
  async (type) => {
    const response = await toiletAPI.get(`/facilities/type?type=${type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    ;
    return response.data.data;
  }
);

export const getToiletType = createAsyncThunk(
  "toiletManager/getType",
  async (type) => {
    const response = await toiletAPI.get(`/facilities/type?type=${type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    ;
    return response.data.data;
  }
);

export const postNewToilet = createAsyncThunk(
  "toiletManager/postToilet",
  async (data) => {
    const response = await toiletAPI.post(`/toilets`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    console.log(response);
    return response;
  }
);

export const getRatingReport = createAsyncThunk(
  "toiletManager/getReport",
  async (data) => {
    const response = await toiletAPI.get(
      `/ratings?toilet-id=${data.id}&pageIndex=${data.pageIndex}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    ;
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

// export const getRatingDetails = createAsyncThunk(
//   "toiletManager/getRaDetails",
//   async (data) => {
//     const response = await toiletAPI.get(
//       `/ratings?toilet-id=${data.id}&pageIndex=1&pageSize=8`,
//       {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("userToken")}`,
//         },
//       }
//     );
//     ;
//     // The value we return becomes the `fulfilled` action payload
//     return response.data.data;
//   }
// );

export const getRatingFilterTag = createAsyncThunk(
  "toiletManager/getFilterTag",
  async (data) => {
    const response = await toiletAPI.get(`/common-comments`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(response);
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const updateStatusRatingReport = createAsyncThunk(
  "toiletManager/updateStatusRating",
  async (data) => {
    const response = await toiletAPI.patch(`/ratings/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getRatingByRatingId = createAsyncThunk(
  "toiletManager/getRatingById",
  async (id) => {
    const response = await toiletAPI.get(`/ratings/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getStatisticCount = createAsyncThunk(
  "toiletManager/getCountStatistic",
  async (data) => {
    const response = await toiletAPI.get(
      `/statistics/count?from-date=${data.startDate}&to-date=${data.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    ;
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getStatisticAllToilet = createAsyncThunk(
  "toiletManager/getStaAllToilet",
  async (data) => {
    const response = await toiletAPI.get(
      `/statistics?company-id=${data.id}&from-date=${data.startDate}&to-date=${data.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getPaymentStatisticAllToilet = createAsyncThunk(
  "toiletManager/getPaymentStatAllToilet",
  async (data) => {
    const response = await toiletAPI.get(
      `/statistics/payment-statistics?company-id=${data.id}&from-date=${data.startDate}&to-date=${data.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getReportLocation = createAsyncThunk(
  "toiletManager/getReLocation",
  async (data) => {
    const response = await toiletAPI.get(
      `/reports/${data.id}?pageIndex=${data.pageIndex}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getReportLocationCount = createAsyncThunk(
  "toiletManager/getReLocationCount",
  async (id) => {
    const response = await toiletAPI.get(`/reports/${id}/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const toiletSlice = createSlice({
  name: "toiletManager",
  initialState: {
    data: [],
    inforData: [],
    statusUpdate: {},
    dataReport: [],
    dataRatingId: {},
    updateRecommendData: {},
    dataMonthReport: {},
    dataFacility: [],
    dataPostNewToilet: {},
    dataUpdateNewToilet: {},
    dataRatingFilterTag: [],
    dataRating: [],
    dataStatisticAllToilet: [],
    dataPaymentStatisticAllToilet: [],
    updateRating: {},
    checkinDataToilet: [],
    checkinToiletCount: 0,
    count: 0,
    ratingCount: 0,
    statisticCount: 0,
    status: "idle",
    toiletType: [],
    dataReportLocation: [],
    dataReportLocationCount: 0,
    dataUpdateConfigToilet: {},
  },
  reducers: {
    resetState: (state) => {
      state.dataPostNewToilet = {};
    },
  },
  extraReducers: (builder) => {
    // --- Xử lý trong reducer với case fulfilled ---
    builder
      .addCase(getToiletManager.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getToiletManager.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getToiletManager.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getToiletCountManager.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.count = action.payload;
      });

      builder
      .addCase(getCheckinToilet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCheckinToilet.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.checkinDataToilet = action.payload;
      })

      builder
      .addCase(getCheckinToiletCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCheckinToiletCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.checkinToiletCount = action.payload;
      })

      builder.addCase(getPaymentStatisticAllToilet.pending, (state, action) => {
        state.status = "loading";
      });
      builder.addCase(getPaymentStatisticAllToilet.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataPaymentStatisticAllToilet = action.payload;
      });
      builder.addCase(getPaymentStatisticAllToilet.rejected, (state, action) => {
        state.status = "error";
      });

    builder.addCase(getStatisticAllToilet.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataStatisticAllToilet = action.payload;
    });

    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(updateRecommend.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.updateRecommendData = action.payload;
      });

    builder
      // .addCase(getRatingByRatingId.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getRatingByRatingId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataRatingId = action.payload;
      });

    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getToiletInfor.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.inforData = action.payload;
      });

    builder.addCase(updateStatusToilet.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.statusUpdate = action.payload;
    });

    builder.addCase(updateToiletInfor.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataUpdateNewToilet = action.payload;
    });

    builder.addCase(updateToiletConfig.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataUpdateConfigToilet = action.payload;
    });

    builder.addCase(getReport.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataReport = action.payload;
    });

    builder.addCase(getMonthReport.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataMonthReport = action.payload;
    });

    builder.addCase(getFacility.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataFacility = action.payload;
    });

    builder.addCase(getToiletType.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.toiletType = action.payload;
    });

    builder.addCase(postNewToilet.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(postNewToilet.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataPostNewToilet = action.payload;
    });

    builder.addCase(getRatingReport.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataRating = action.payload;
    });

    builder.addCase(getRatingFilterTag.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataRatingFilterTag = action.payload;
    });

    builder.addCase(updateStatusRatingReport.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateRating = action.payload;
    });

    builder.addCase(getRatingCountReport.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.ratingCount = action.payload;
    });

    builder.addCase(getStatisticCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.statisticCount = action.payload;
    });
    builder.addCase(getReportLocation.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataReportLocation = action.payload;
    });
    builder.addCase(getReportLocationCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataReportLocationCount = action.payload;
    });
  },
});

export default toiletSlice.reducer;
export const showToilet = (state) => state.toiletManager.data;
export const showStatisticAllToilet = (state) =>
  state.toiletManager.dataStatisticAllToilet;
export const showPaymentStatisticAllToilet = (state) =>
  state.toiletManager.dataPaymentStatisticAllToilet;
export const showStatusUpdate = (state) => state.toiletManager.statusUpdate;
export const showDataRating = (state) => state.toiletManager.dataRating;
export const showDataRatingId = (state) => state.toiletManager.dataRatingId;
export const showUpdateRecommend = (state) =>
  state.toiletManager.updateRecommendData;
export const showDataRatingFilterTag = (state) =>
  state.toiletManager.dataRatingFilterTag;
export const showStatusRating = (state) => state.toiletManager.updateRating;
export const showToiletInfor = (state) => state.toiletManager.inforData;
export const showToiletInforUpdate = (state) =>
  state.toiletManager.dataUpdateNewToilet;
export const showToiletConfigUpdate = (state) =>
  state.toiletManager.dataUpdateConfigToilet;
export const showCount = (state) => state.toiletManager.count;
export const showRatingCount = (state) => state.toiletManager.ratingCount;

export const showCheckinDataToilet = (state) => state.toiletManager.checkinDataToilet;
export const showCheckinDataToiletCount = (state) => state.toiletManager.checkinToiletCount;

export const showStatisticCount = (state) => state.toiletManager.statisticCount;
export const showToiletStatus = (state) => state.toiletManager.status;
export const showReport = (state) => state.toiletManager.dataReport;
export const showReportLocation = (state) =>
  state.toiletManager.dataReportLocation;
export const showReportLocationCount = (state) =>
  state.toiletManager.dataReportLocationCount;
export const showMonthReport = (state) => state.toiletManager.dataMonthReport;
export const showFacility = (state) => state.toiletManager.dataFacility;
export const showToiletType = (state) => state.toiletManager.toiletType;
export const showDataPostNewToilet = (state) =>
  state.toiletManager.dataPostNewToilet;

export const { resetState } = toiletSlice.actions;

import {
  AutoComplete,
  Button,
  Col,
  Input,
  Row,
  Steps,
  Upload,
  message,
} from "antd";
import React from "react";
import Logo from "../../../../asset/Logo.png";
import { useState } from "react";
import "@goongmaps/goong-js/dist/goong-js.css";
import "react-image-upload/dist/index.css";
import ReactMapGL, {
  FlyToInterpolator,
  Marker,
} from "@goongmaps/goong-map-react";
import { MdLocationPin } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddressByAutocomplete,
  getAutocompleteMap,
  showAddressBySearch,
  showGetApiMap,
} from "../../../../features/mapSlice";
import { useEffect } from "react";
import "@goongmaps/goong-geocoder-react/dist/goong-geocoder.css";
import TextArea from "antd/es/input/TextArea";
import Menu from "../../../../components/menu/Menu";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { storage } from "../../../../firebaseConfig.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ScrollContainer from "react-indiana-drag-scroll";
import NumericInput from "../../../../components/reuse/NumericInput";
import ImageUploader from "react-image-upload";
import { Icon } from "@iconify/react";

const REACT_APP_GOONG_ACCESS_TOKEN = "cwSBYZP1nDTuYdMb1UM1nDniqkBnevyJVCFaQad0";

// function CreateNewInforUnit( {toiletName, street, latitude, longitude, commune, district, province} ) {
function CreateNewInforUnit() {
  const [current, setCurrent] = useState(0);
  const [phone, setPhone] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).phone
      : 0
  );
  const [screenSize, getDimension] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [toiletName, setToiletName] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).toiletName
      : ""
  );
  const [options, setOptions] = useState([]);
  const showAddress = useSelector(showGetApiMap);
  const address = useSelector(showAddressBySearch);
  const [street, setStreet] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).street
      : ""
  );
  const [latitude, setLatitude] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).lat
      : 10.7447788
  );
  const [longitude, setLongitude] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).long
      : 106.6355914
  );
  const [commune, setCommune] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).commune
      : ""
  );
  const [district, setDistrict] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).district
      : ""
  );
  const [province, setProvince] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).province
      : ""
  );
  const [imgUrl, setImgUrl] = useState(
    JSON.parse(localStorage.getItem("dataInforCom")) !== null
      ? JSON.parse(localStorage.getItem("dataInforCom")).imgArr
      : null
  );
  const navigate = useNavigate();
  const [percent, setPercent] = useState(0);
  const [dataInfor, setDataInfor] = useState({
    toiletName: toiletName,
    street: street,
    commune: commune,
    district: district,
    province: province,
    long: longitude,
    lat: latitude,
    imgArr: imgUrl,
  });

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //render sau khi bấm
  const handleSearch = (value) => {
    let res = [];
    dispatch(getAutocompleteMap(value));
    res = showAddress
      // .map((item) => item.description)
      .map((item) => ({
        label: `${item.description}`,
        value: `${item.structured_formatting.main_text}`,
        key: `${item.place_id}`,
      }));
    setOptions(res);
  };

  const onSelect = (value, options) => {
    dispatch(getAddressByAutocomplete(options.key));
  };

  const uploadImageAsPromise = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const storageRef = ref(storage, `logo/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        alert(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
        });
      }
    );
  };

  useEffect(() => {
    if (address.length !== 0) {
      setLongitude(address.geometry.location.lng);
      setLatitude(address.geometry.location.lat);
      setStreet(address.name);
      setCommune(address.compound.commune);
      setDistrict(address.compound.district);
      setProvince(address.compound.province);
      setDataInfor({
        toiletName: toiletName,
        street: address.name,
        commune: address.compound.commune,
        district: address.compound.district,
        province: address.compound.province,
        long: address.geometry.location.lng,
        lat: address.geometry.location.lat,
        imgArr: imgUrl,
        phone: phone,
      });
    } else if (JSON.parse(localStorage.getItem("dataInforCom")) !== null) {
      setDataInfor({
        toiletName: toiletName,
        imgArr: imgUrl,
        street: street,
        commune: commune,
        district: district,
        province: province,
        long: longitude,
        lat: latitude,
        phone: phone,
      });
    }
  }, [dataInfor, address]);

  useEffect(() => {
    if (address.length === 0) {
      setCommune("");
      setDistrict("");
      setProvince("");
      setLatitude(10.7447788);
      setLongitude(106.6355914);
    }
  }, []);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="create-new-toilet-box-container">
            <Breadcrumbs
              title="Quản lý đơn vị vệ sinh"
              pageName="Tạo đơn vị vệ sinh mới"
              onRemove={() => navigate(-1)}
            />
            <div className="create-new-toilet-step-container">
              {screenSize < 1440 ? (
                <Steps
                  size="small"
                  current={0}
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : (
                <Steps
                  current={0}
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              )}
              <div className="create-new-toilet-input-box-container">
                <div
                  style={{ marginRight: "12%" }}
                  className="create-new-toilet-input-left-container"
                >
                  <div className="create-new-toilet-input-container">
                    <p>
                      Tên đơn vị<span style={{ color: "red" }}> *</span>
                    </p>
                    <Input
                      value={toiletName}
                      onChange={(e) => setToiletName(e.target.value)}
                      className="create-new-toilet-input"
                      placeholder="Nhập tên đơn vị vệ sinh (tối đa 50 kí tự)"
                      required
                      maxLength={50}
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>
                      Địa chỉ<span style={{ color: "red" }}> *</span>
                    </p>
                    <AutoComplete
                      required
                      options={options}
                      defaultValue={street}
                      className="create-new-toilet-input"
                      onSelect={onSelect}
                      onChange={handleSearch}
                    >
                      <TextArea
                        placeholder="Nhập địa chỉ"
                        value={street}
                        className="custom"
                        style={{
                          height: 64,
                        }}
                      />
                    </AutoComplete>
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>Phường/Xã</p>

                    <Input
                      value={commune}
                      className="create-new-toilet-input"
                      disabled
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>Quận/Huyện</p>
                    <Input
                      value={district}
                      className="create-new-toilet-input"
                      disabled
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>Tỉnh/Thành phố</p>
                    <Input
                      value={province}
                      className="create-new-toilet-input"
                      disabled
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>
                      Số điện thoại<span style={{ color: "red" }}> *</span>
                    </p>
                    <Input
                      className="create-new-toilet-input"
                      placeholder="Nhập số điện thoại"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      maxlength="10"
                    />
                  </div>
                </div>
                <div className="create-new-toilet-input-right-container">
                  <label for="inputTag">
                    {imgUrl !== null ? (
                      <div>
                        <div className="create-new-infor-logo-empty-container">
                          <img
                            className="create-new-toilet-unit-image-right"
                            src={imgUrl}
                            alt=""
                            srcset=""
                          />
                          <div className="create-new-infor-logo-empty-position">
                            <div className="create-new-infor-logo-empty-position-in"></div>
                            <p>Thay đổi Logo</p>
                          </div>
                        </div>
                        <Input
                          id="inputTag"
                          className="create-new-toilet-unit-image-right-container"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={uploadImageAsPromise}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="create-new-infor-logo-empty-container">
                          <img
                            className="create-new-infor-logo-empty"
                            src={Logo}
                            alt=""
                          />
                          <div className="create-new-infor-logo-empty-position">
                            <div className="create-new-infor-logo-empty-position-in"></div>
                            <p>Thêm Logo</p>
                          </div>
                        </div>
                        <Input
                          id="inputTag"
                          className="create-new-toilet-unit-image-right-container"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={uploadImageAsPromise}
                        />
                      </div>
                    )}
                  </label>
                </div>
              </div>
              {toiletName !== null && imgUrl !== null && phone.length >= 8 ? (
                <div className="create-new-toilet-footer-container">
                  <Button
                    type="primary"
                    // onClick={() =>
                    //   navigate("/createnewaccount", {
                    //     state: { dataInfor: dataInfor },
                    //   })
                    // }
                    onClick={() => [
                      navigate("/createnewaccount"),
                      localStorage.setItem(
                        "dataInforCom",
                        JSON.stringify(dataInfor)
                      ),
                    ]}
                  >
                    Tiếp theo
                  </Button>
                </div>
              ) : (
                <div className="create-new-toilet-footer-container">
                  <Button
                    disabled
                    // onClick={() =>
                    //   navigate("/createnewaccount", {
                    //     state: { dataInfor: dataInfor },
                    //   })
                    // }
                    onClick={() => [
                      navigate("/createnewaccount"),
                      localStorage.setItem(
                        "dataInforCom",
                        JSON.stringify(dataInfor)
                      ),
                    ]}
                  >
                    Tiếp theo
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateNewInforUnit;

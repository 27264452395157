export const REG = /^\d+$/;

export const TITLE = `Toilet Map`;
export const TITLE1 = `Toilet Map - Báo cáo thống kê`;
export const TITLE2 = `Toilet Map - Quản lý nhà vệ sinh`;
export const TITLE3 = `Toilet Map - Quản lý lượt khách`;
export const TITLE4 = `Toilet Map - Quảng cáo`;
export const TITLE5 = `Toilet Map - Cài đặt thông tin`;
export const TITLE6 = `Toilet Map - Quản lý đơn vị vệ sinh`;
export const TITLE7 = `Toilet Map - Quản lý gợi ý`;
export const TITLE8 = `Toilet Map - Thiết lập hệ thống`;
export const TITLE9 = `Toilet Map - Giả lập`;
export const TITLE10 = `Toilet Map - Nạp tiền / Mua gói`;
export const TITLE11 = `Toilet Map - Quản lý quảng cáo`;
import { Col, Row } from "antd";
import React from "react";
import Menu from "../../../components/menu/Menu";

import TagHeader from "../../../components/reuse/TagHeader";
import RechargeCom from "./RechargeCom"
import RechargeTurn from "./RechargeTurn";
import { TITLE10 } from "../../../Constant";
import { useEffect } from "react";

function Recharge() {

  //handle document title
  useEffect(() => {
    document.title = TITLE10;
  }, [])

  //render
  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleRecharge="menu-content-link-active"/>
        </Col>
        <Col span={19}>
            <TagHeader displayRightButton='none' title="Nạp tiền - Mua gói" label1="Nạp tiền" label2="Mua gói" children1={<RechargeCom />} children2={<RechargeTurn />}/>
        </Col>
      </Row>
    </div>
  );
}

export default Recharge;

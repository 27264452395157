import React, { useRef } from "react";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  TimePicker,
} from "antd";
import { useState } from "react";
import dayjs from "dayjs";
import NumericInput from "../../../../components/reuse/NumericInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getFacility,
  getToiletType,
  showFacility,
  showToiletType,
} from "../../../../features/toiletManagerSlice";
import { useEffect } from "react";
import { REG } from "../../../../Constant";
import Menu from "../../../../components/menu/Menu";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import { json, useLocation, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

function CreateNewConfig() {
  const { state } = useLocation();
  const inputEle = useRef(null);
  const [startTime, setStartTime] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).startTime
      : "08:00"
  );
  const [timeChecked, setTimeChecked] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).timeChecked
      : false
  );
  const [statusChecked, setStatusChecked] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).statusChecked
      : false
  );
  const [options, setOptions] = useState([]);
  const [optionsToilet, setOptionsToilet] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).toiletTags
      : []
  );
  const [endTime, setEndTime] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).endTime
      : "20:00"
  );
  const [free, setFree] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).free
      : false
  );
  const facility = useSelector(showFacility);
  const toiletType = useSelector(showToiletType);
  const [facilityTags, setFacilityTags] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).facilityTags
      : []
  );

  const [nearBy, setNearBy] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).nearBy
      : ""
  );

  const [facilityTagsToShow, setFacilityTagsToShow] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).facilityTagsToShow
      : []
  );
  const [facilityTagsToGet, setFacilityTagsToGet] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).facilityTagsToGet
      : []
  );
  const [facilityTagsToPlus, setFacilityTagsToPlus] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).facilityTagsToPlus
      : []
  );
  const dispatch = useDispatch();
  const [status, setStatus] = useState(
    JSON.parse(localStorage.getItem("configData")) !== null
      ? JSON.parse(localStorage.getItem("configData")).status
      : "Không hoạt động"
  );
  const navigate = useNavigate();
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [dataConfig, setDataConfig] = useState({
    startTime: startTime,
    endTime: endTime,
    facilityTags: facilityTags,
    facilityTagsToShow: facilityTagsToShow,
    free: free,
    status: status,
    toiletTags: optionsToilet,
    nearBy: nearBy,
    facilityTagsToGet: facilityTagsToGet,
    facilityTagsToPlus: facilityTagsToPlus,
    timeChecked: timeChecked,
    statusChecked: statusChecked,
  });

  const format = "HH:mm";

  //actions
  const onChange = (e) => {
    if (e.target.checked) {
      setStartTime("00:00");
      setEndTime("23:59");
      setTimeChecked(true);
    } else {
      setStartTime("08:00");
      setEndTime("20:00");
      setTimeChecked(false);
    }
  };

  const onChangeStatus = (e) => {
    if (e.target.checked) {
      setStatus("Đang hoạt động");
      setStatusChecked(true);
    } else {
      setStatusChecked(false);
      setStatus("Không hoạt động");
    }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setStartTime(dateStrings[0]);
      setEndTime(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  const onChangeValue = (e) => {
    setFree(e.target.value);
  };

  useEffect(() => {
    setDataConfig({
      startTime: startTime,
      endTime: endTime,
      facilityTags: facilityTagsToPlus.concat(
        optionsToilet.map((item) => {
          return {
            facilityId: item.facilityId,
            quantity: item.quantity,
          };
        })
      ),
      facilityTagsToShow: facilityTagsToShow,
      free: free,
      status: status,
      nearBy: nearBy,
      toiletTags: optionsToilet,
      facilityTagsToPlus: facilityTagsToPlus,
      facilityTagsToGet: facilityTagsToGet,
      timeChecked: timeChecked,
      statusChecked: statusChecked,
    });
  }, [dataConfig]);

  const handleChange = (value, options) => {
    setFacilityTagsToShow(
      options.map((item) => {
        return item.label;
      })
    );
    setFacilityTagsToGet(
      options.map((item) => {
        return { label: `${item.label}`, value: item.value };
      })
    );
    setFacilityTagsToPlus(
      options.map((item) => {
        return { facilityId: item.value, quantity: 1 };
      })
    );
    const newValue = value.map((item) => {
      return { facilityId: item, quantity: 1 };
    });
    setFacilityTags(newValue);
  };

  useEffect(() => {
    dispatch(getFacility("Trang thiết bị"));
    dispatch(getToiletType("Phòng"));
  }, []);

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (facility.length !== 0) {
      setOptions(
        facility.map((item, index) => ({
          label: `${item.name}`,
          value: item.id,
        }))
      );
    }
  }, [facility]);

  useEffect(() => {
    if (
      toiletType.length !== 0 &&
      JSON.parse(localStorage.getItem("configData")) === null
    ) {
      setOptionsToilet(
        toiletType.map((item, index) => ({
          facilityId: item.id,
          quantity: item.quantity,
          name: item.name,
        }))
      );
    }
  }, [toiletType]);

  const handleToilet = (e, facilityId) => {
    var result = [...optionsToilet];
    result = result.map((x) => {
      //<- use map on result to find element to update using id
      if (x.facilityId === facilityId) x.quantity = Number(e.target.value);
      return x;
    });
    setOptionsToilet(result); //<- update toilet with value edited
  };

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="create-new-toilet-box-container">
            <Breadcrumbs
              title="Quản lý nhà vệ sinh"
              pageName="Tạo nhà vệ sinh mới"
              onRemove={() => navigate(-1)}
            />
            <div className="create-new-toilet-step-container">
              {screenSize > 1440 ? (
                <Steps
                  current={1}
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : screenSize <= 1440 && screenSize > 1024 ? (
                <Steps
                  current={1}
                  size="small"
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : (
                <Steps
                  current={1}
                  size="small"
                  direction="vertical"
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              )}
              <div className="create-new-config-input-box-container">
                <div className="create-new-config-input-container">
                  <p>Giờ mở cửa<span style={{ color: "red" }}> *</span></p>
                  <TimePicker.RangePicker
                    value={[dayjs(startTime, format), dayjs(endTime, format)]}
                    onChange={onRangeChange}
                    format={format}
                    className="create-new-config-input"
                  />
                </div>
                <div className="create-new-config-input-container">
                  <p></p>
                  <Checkbox
                    className="create-new-config-checkbox"
                    onChange={onChange}
                    checked={timeChecked}
                  >
                    Mở cửa cả ngày
                  </Checkbox>
                </div>
                {optionsToilet.length !== 0
                  ? optionsToilet.map((item, index) => {
                      return (
                        <div
                          key={item.facilityId}
                          className="create-new-config-input-container"
                        >
                          {index === 0 ? <p>{item.name}<span style={{ color: "red" }}> *</span></p> : <p>{item.name}</p>}
                          {index === 0 ? (
                            <NumericInput
                              textAlign="right"
                              value={item.quantity}
                              // defaultValue={item.quantity}
                              handleChange={(e) =>
                                REG.test(e.target.value)
                                  ? handleToilet(e, item.facilityId)
                                  : ""
                              }
                              addon="Phòng"
                              className="create-new-config-input-number"
                              placeholder="1-60"
                              maxLength="2"
                            />
                          ) : (
                            <NumericInput
                              textAlign="right"
                              value={item.quantity}
                              // defaultValue={item.quantity}
                              handleChange={(e) =>
                                REG.test(e.target.value)
                                  ? handleToilet(e, item.facilityId)
                                  : ""
                              }
                              addon="Phòng"
                              className="create-new-config-input-number"
                              placeholder="0-30"
                              maxLength="2"
                            />
                          )}
                        </div>
                      );
                    })
                  : ""}
                <div className="create-new-config-input-container">
                  <p>Tiện ích bổ sung<span style={{ color: "red" }}> *</span></p>
                  <Space
                    className="create-new-config-input-tag"
                    direction="vertical"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Chọn tiện ích"
                      onChange={handleChange}
                      options={options}
                      defaultValue={facilityTagsToGet}
                    />
                  </Space>
                </div>

                <div className="create-new-config-input-container">
                  <p>Tiện ích xung quanh</p>
                  <TextArea placeholder="Nhập tiện ích xung quanh (cách Circle K 500m, siêu thị...) " className="create-new-config-input" value={nearBy} onChange={(e) => setNearBy(e.target.value)}/>
                </div>

                <div className="create-new-config-input-container">
                  <p>Thiết lập nhà vệ sinh<span style={{ color: "red" }}> *</span></p>
                  <Radio.Group onChange={onChangeValue} value={free}>
                    <Radio value={false}>Tính phí</Radio>
                    <Radio value={true}>Miễn phí</Radio>
                  </Radio.Group>
                </div>
                <div className="create-new-config-input-container">
                  <p>Trạng thái hoạt động</p>
                  <Checkbox onChange={onChangeStatus} checked={statusChecked}>
                    Đang hoạt động
                  </Checkbox>
                </div>
              </div>
              <div className="create-new-toilet-footer-container">
                <Button
                  type="default"
                  style={{ marginRight: "1%" }}
                  onClick={() => navigate(-1)}
                >
                  Trở về
                </Button>
                <Button
                  type="primary"
                  onClick={() => [
                    navigate("/createaccounttoilet"),
                    localStorage.setItem(
                      "configData",
                      JSON.stringify(dataConfig)
                    ),
                  ]}
                >
                  Tiếp theo
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateNewConfig;

import {
  Alert,
  Col,
  Descriptions,
  Popconfirm,
  Result,
  Row,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import Menu from "../../../components/menu/Menu";
import Header from "../../../components/reuse/Header";
import "./ManageToilet.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getToiletCountManager,
  getToiletManager,
  showCount,
  showStatusUpdate,
  showToilet,
  showUpdateRecommend,
  showToiletStatus,
  updateRecommend,
  updateStatusToilet,
  resetState,
} from "../../../features/toiletManagerSlice";
import { showInfor } from "../../../features/managerSlice";
import { Icon } from "@iconify/react";
import { Button, Popover, Tag } from "antd";
import swal from "sweetalert";
import {
  ExclamationCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { deleteAddress } from "../../../features/mapSlice";
import { TITLE2 } from "../../../Constant";
import FancyDependencyErrorHandling from "../../../components/reuse/FancyDependencyErrorHandling";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

function ManageToilet() {
  const [current, setCurrent] = useState(1);
  const dispatch = useDispatch();
  const toiletInfor = useSelector(showInfor);
  const toiletData = useSelector(showToilet);
  const toiletCount = useSelector(showCount);
  const { state } = useLocation();
  const dataCount = [];
  const navigate = useNavigate();
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${toiletCount}`;
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [messageApi, contextHolder] = message.useMessage();
  const statusToilet = useSelector(showStatusUpdate);
  const status = useSelector(showToiletStatus);
  const toiletUpdateRecommend = useSelector(showUpdateRecommend);
  const [keyword, setKeyword] = useState("");

  const HandleContent = (props) => {
    const navigate = useNavigate();
    return (
      <div>
        <p
          onClick={() =>
            navigate(`toilet/${props.id}`, { state: { id: props.id } })
          }
          className="manage-toilet-popover-see-infor"
        >
          Xem thông tin
        </p>
        <p
          onClick={() =>
            handleUpdateStatus(props.toiletId, props.status, props.name)
          }
          className="manage-toilet-popover-change-status"
        >
          Đổi trạng thái
        </p>
      </div>
    );
  };

  //cột table
  const ManageToiletColumn = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      align: "center",
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "Tên nhà vệ sinh",
      dataIndex: "toiletName",
      width: "20%",
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "username",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Địa chỉ",
      width: "26%",
      dataIndex: "newAddress",
      ellipsis: true,
    },
    {
      title: "Trạng thái",
      width: "12%",
      dataIndex: "status",
      align: "center",
      render: (item) => {
        return item === "Đang hoạt động" ? (
          <Tag color="processing">Đang hoạt động</Tag>
        ) : (
          <Tag color="error">Không hoạt động</Tag>
        );
      },
    },
    // {
    //   title: "Gợi ý",
    //   dataIndex: "suggestions",
    //   width: "20%",
    //   ellipsis: true,
    //   align: "center",
    //   render: (item, record) =>
    //     item !== null && item.length >= 2 ? (
    //       item[0].isAccepted === null || item[0].isAccepted === null ? (
    //         <Popconfirm
    //           title={`Gợi ý cho Quý ${dayjs().quarter()} - ${dayjs().year()}`}
    //           icon={
    //             <ExclamationCircleTwoTone
    //               twoToneColor="#faad14"
    //               className="statistic-advice-icon"
    //             />
    //           }
    //           description={
    //             item !== null ? (
    //               <div className="manage-toilet-suggestion-each-quarter-container">
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" &&
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <p>{item[0].message}</p>
    //                 )}
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" ||
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" ||
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <p className="manage-toilet-suggestion-each-quarter-header">
    //                     Đã <strong>quá 4 quý</strong> bạn không xây nhà vệ sinh
    //                     mới, chúng tôi sẽ không thực hiện nhắc nhở nữa!
    //                   </p>
    //                 )}
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" &&
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <div>
    //                     <p className="manage-toilet-suggestion-each-quarter-header">
    //                       <strong>Trung bình 2 quý gần đây</strong>
    //                     </p>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[1].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[0].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~{" "}
    //                           {handleConvertNumber(
    //                             (item[0].expectedCount +
    //                               item[1].expectedCount) /
    //                               2
    //                           )}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~{" "}
    //                           {handleConvertNumber(
    //                             (item[0].actualCount + item[1].actualCount) / 2
    //                           )}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <p className="manage-toilet-suggestion-each-quarter-header">
    //                       <strong>Cụ thể</strong>
    //                     </p>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[0].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[0].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[0].expectedCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[0].actualCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[1].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[1].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[1].expectedCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[1].actualCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             ) : (
    //               ""
    //             )
    //           }
    //           okText={record.suggestionMessage.includes('Dưới ngưỡng') ? "Đóng" : "Đồng ý"}
    //           // onConfirm={() => [
    //           //   // handleUpdateRecommend(item[0].id, item[1].id, true),
    //           //   navigate(`/createinfortoilet`, {
    //           //     state: {
    //           //       id: {
    //           //         id1: item[0].id,
    //           //         id2: item[1].id,
    //           //         status: true
    //           //       },
    //           //       address: record.address,
    //           //       ward: record.ward,
    //           //       district: record.district,
    //           //       province: record.province,
    //           //       latitude: record.latitude,
    //           //       longitude: record.longitude,
    //           //     },
    //           //   }),
    //           // ]}
    //           onConfirm={() => [
    //             navigate(`/createinfortoilet`),
    //             //   state: {
    //             //     id: {
    //             //       id1: item[0].id,
    //             //       id2: item[1].id,
    //             //       status: true,
    //             //     },
    //             //     address: record.address,
    //             //     ward: record.ward,
    //             //     district: record.district,
    //             //     province: record.province,
    //             //     latitude: record.latitude,
    //             //     longitude: record.longitude,
    //             //   },
    //             // }),
    //             localStorage.setItem(
    //               "sugId",
    //               JSON.stringify({
    //                 id1: item[0].id,
    //                 id2: item[1].id,
    //                 status: true,
    //               })
    //             ),
    //             localStorage.setItem(
    //               "inforData",
    //               JSON.stringify({
    //                 address: record.address,
    //                 ward: record.ward,
    //                 district: record.district,
    //                 province: record.province,
    //                 latitude: record.latitude,
    //                 longitude: record.longitude,
    //               })
    //             ),
    //           ]}
    //           onCancel={() => {
    //             handleUpdateRecommend(item[0].id, item[1].id, false);
    //           }}
    //           cancelText={"Không đồng ý"}
    //           showCancel={record.suggestionMessage.includes('Dưới ngưỡng') ? false : true}
    //           placement="bottomRight"
    //           arrow={{ pointAtCenter: true }}
    //         >
    //           {/* <ExclamationCircleTwoTone
    //             twoToneColor="#faad14"
    //             className="statistic-advice-icon"
    //           /> */}
    //           {record.suggestionMessage === "Vượt ngưỡng 2 quý liên tục" || record.suggestionMessage === "Dưới ngưỡng 2 quý liên tục" ? (
    //             <Tag style={{ cursor: "pointer" }} color="yellow">
    //               {record.suggestionMessage}
    //             </Tag>
    //           ) : record.suggestionMessage === "Vượt ngưỡng 3 quý liên tục" || record.suggestionMessage === "Dưới ngưỡng 3 quý liên tục" ? (
    //             <Tag style={{ cursor: "pointer" }} color="orange">
    //               {record.suggestionMessage}
    //             </Tag>
    //           ) : record.suggestionMessage === "Vượt ngưỡng 4 quý liên tục" || record.suggestionMessage === "Dưới ngưỡng 4 quý liên tục" ? (
    //             <Tag style={{ cursor: "pointer" }} color="volcano">
    //               {record.suggestionMessage}
    //             </Tag>
    //           ) : (
    //             <MinusCircleTwoTone
    //               style={{ cursor: "pointer" }}
    //               twoToneColor="volcano"
    //             >
    //               {record.suggestionMessage}
    //             </MinusCircleTwoTone>
    //           )}
    //         </Popconfirm>
    //       ) : item[0].isAccepted === true || item[1].isAccepted === true ? (
    //         <Popconfirm
    //           title={`Gợi ý cho Quý ${dayjs().quarter()} - ${dayjs().year()}`}
    //           icon={
    //             <ExclamationCircleTwoTone
    //               twoToneColor="#faad14"
    //               className="statistic-advice-icon"
    //             />
    //           }
    //           description={
    //             item !== null ? (
    //               <div className="manage-toilet-suggestion-each-quarter-container">
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" &&
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <p>{item[0].message}</p>
    //                 )}
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" ||
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" ||
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <p className="manage-toilet-suggestion-each-quarter-header">
    //                     Đã <strong>quá 4 quý</strong> bạn không xây nhà vệ sinh
    //                     mới, chúng tôi sẽ không thực hiện nhắc nhở nữa!
    //                   </p>
    //                 )}
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" &&
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <div>
    //                     <p className="manage-toilet-suggestion-each-quarter-header">
    //                       <strong>Trung bình 2 quý gần đây</strong>
    //                     </p>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[1].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[0].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~{" "}
    //                           {handleConvertNumber(
    //                             (item[0].expectedCount +
    //                               item[1].expectedCount) /
    //                               2
    //                           )}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~{" "}
    //                           {handleConvertNumber(
    //                             (item[0].actualCount + item[1].actualCount) / 2
    //                           )}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <p className="manage-toilet-suggestion-each-quarter-header">
    //                       <strong>Cụ thể</strong>
    //                     </p>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[0].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[0].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[0].expectedCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[0].actualCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[1].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[1].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[1].expectedCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[1].actualCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             ) : (
    //               ""
    //             )
    //           }
    //           okText="Đóng"
    //           showCancel={false}
    //           placement="bottomRight"
    //           arrow={{ pointAtCenter: true }}
    //         >
    //           <Tag style={{ cursor: "pointer" }} color="green">
    //             {record.suggestionMessage}
    //           </Tag>
    //         </Popconfirm>
    //       ) : item[0].isAccepted === false || item[1].isAccepted === false ? (
    //         <Popconfirm
    //           title={`Gợi ý cho Quý ${dayjs().quarter()} - ${dayjs().year()}`}
    //           icon={
    //             <ExclamationCircleTwoTone
    //               twoToneColor="#faad14"
    //               className="statistic-advice-icon"
    //             />
    //           }
    //           description={
    //             item !== null ? (
    //               <div className="manage-toilet-suggestion-each-quarter-container">
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" &&
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <p>{item[0].message}</p>
    //                 )}
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" ||
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" ||
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" ||
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <p className="manage-toilet-suggestion-each-quarter-header">
    //                     Đã <strong>quá 4 quý</strong> bạn không xây nhà vệ sinh
    //                     mới, chúng tôi sẽ không thực hiện nhắc nhở nữa!
    //                   </p>
    //                 )}
    //                 {record.suggestionMessage !==
    //                   "Vượt ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 2 quý liên tục" &&
    //                 record.suggestionMessage !== "Vượt ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 3 quý liên tục" &&
    //                 record.suggestionMessage !== "Dưới ngưỡng 4 quý liên tục" &&
    //                 record.suggestionMessage !==
    //                   "Vượt ngưỡng 4 quý liên tục" ? (
    //                   ""
    //                 ) : (
    //                   <div>
    //                     <p className="manage-toilet-suggestion-each-quarter-header">
    //                       <strong>Trung bình 2 quý gần đây</strong>
    //                     </p>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[1].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[0].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~{" "}
    //                           {handleConvertNumber(
    //                             (item[0].expectedCount +
    //                               item[1].expectedCount) /
    //                               2
    //                           )}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~{" "}
    //                           {handleConvertNumber(
    //                             (item[0].actualCount + item[1].actualCount) / 2
    //                           )}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <p className="manage-toilet-suggestion-each-quarter-header">
    //                       <strong>Cụ thể</strong>
    //                     </p>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[0].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[0].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[0].expectedCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[0].actualCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <p className="manage-toilet-suggestion-each-quarter">
    //                       Từ{" "}
    //                       <strong>
    //                         {handleDisplayDate(item[1].startDate)}
    //                       </strong>{" "}
    //                       đến{" "}
    //                       <strong>{handleDisplayDate(item[1].endDate)}</strong>
    //                     </p>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Ước tính</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[1].expectedCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                     <div className="manage-toilet-suggestion-container">
    //                       <p>Thực tế</p>
    //                       <p>
    //                         <strong>
    //                           ~ {handleConvertNumber(item[1].actualCount)}
    //                         </strong>{" "}
    //                         lượt
    //                       </p>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             ) : (
    //               ""
    //             )
    //           }
    //           okText="Đóng"
    //           showCancel={false}
    //           placement="bottomRight"
    //           arrow={{ pointAtCenter: true }}
    //         >
    //           <Tag color="red" style={{ cursor: "pointer" }}>
    //             {record.suggestionMessage}
    //           </Tag>
    //         </Popconfirm>
    //       ) : (
    //         ""
    //       )
    //     ) : (
    //       <p style={{ fontStyle: "italic" }}>Chưa có dữ liệu</p>
    //     ),
    // },
    {
      title: "",
      dataIndex: "id",
      width: "6%",
      align: "right",
      render: (item, record) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={
                <HandleContent
                  id={item}
                  toiletId={item}
                  status={
                    record.status === "Đang hoạt động"
                      ? "Không hoạt động"
                      : "Đang hoạt động"
                  }
                  name={record.toiletName}
                />
              }
              trigger="click"
            >
              <Button
                style={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  height: "22px",
                }}
              >
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  //handle update status of toilet
  const handleUpdateStatus = (id, status, name) => {
    swal({
      title: "Xác nhận",
      text: `Bạn có chắc chắn muốn đổi trạng thái của "${name}" không?`,
      icon: "warning",
      buttons: ["Hủy", "Xác nhận"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const data = {
          status: status,
          id: id,
        };
        dispatch(updateStatusToilet(data));
      } else {
      }
    });
  };

  //convert number of turn to ~K
  const handleConvertNumber = (num, precision = 0) => {
    const map = [
      { suffix: "T", threshold: 1e12 },
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];
    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted =
        (num / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }
    return num;
  };

  //handle date display
  const handleDisplayDate = (date) => {
    return dayjs(date).format("DD-MM-YYYY");
  };

  //handle confirm update recommend
  const handleUpdateRecommend = (id1, id2, accepted) => {
    const data = {
      sugId1: id1,
      sugId2: id2,
      accepted: accepted,
    };
    dispatch(updateRecommend(data));
  };

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //get data
  useEffect(() => {
    const data = {
      keyword: keyword,
      pageIndex: current,
    };
    dispatch(getToiletManager(data));
    dispatch(getToiletCountManager(keyword));
  }, [
    toiletUpdateRecommend,
    current,
    localStorage.getItem("comId"),
    statusToilet,
    keyword,
  ]);

  //pagination
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  //config empty table
  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  //handle document title
  useEffect(() => {
    document.title = TITLE2;
  }, [])

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="manage-toilet-container">
            {contextHolder}
            <Header
              // searchValue={keyword}
              // handleChange={(e) => setKeyword(e.target.value)}
              // // onClick={() => [
              //   navigate("/createinfortoilet"),
              //   dispatch(deleteAddress()),
              //   dispatch(resetState()),
              //   localStorage.removeItem("inforData"),
              //   localStorage.removeItem("configData"),
              //   localStorage.removeItem("accountData"),
              // ]}
              header="header-no-line"
              title="Quản lý nhà vệ sinh"
              // btn="Tạo nhà vệ sinh mới"
            />
            {screenSize < 1200 && screenSize > 768 ? (
              <Table
                size="small"
                locale={locale}
                scroll={{
                  x: "120vw",
                }}
                style={{ marginTop: "16px" }}
                className="table"
                loading={status === "loading" ? true : false}
                bordered
                columns={ManageToiletColumn}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: () => navigate(`toilet/${record.id}`, { state: { id: record.id } }),
                //   };
                // }}
                onSelect={(record) =>
                  navigate(`toilet/${record.id}`, { state: { id: record.id } })
                }
                dataSource={toiletData.map((item, index) => {
                  return {
                    id: item.id,
                    username: item.username,
                    suggestions: item.suggestions,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    expectedCount: item.expectedCount,
                    suggestionMessage: item.suggestionMessage,
                    actualCount: item.actualCount,
                    toiletName: item.toiletName,
                    isAccepted: item.isAccepted,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    address: item.address,
                    ward: item.ward,
                    district: item.district,
                    province: item.province,
                    newAddress: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                    status: item.status,
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: toiletCount,
                  position: ["bottomCenter"],
                  defaultCurrent: 1,
                }}
              />
            ) : screenSize <= 768 ? (
              <Table
                size="small"
                locale={locale}
                scroll={{
                  x: "200vw",
                }}
                style={{ marginTop: "16px" }}
                className="table"
                loading={status === "loading" ? true : false}
                bordered
                columns={ManageToiletColumn}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: () => navigate(`toilet/${record.id}`, { state: { id: record.id } }),
                //   };
                // }}
                onSelect={(record) =>
                  navigate(`toilet/${record.id}`, { state: { id: record.id } })
                }
                dataSource={toiletData.map((item, index) => {
                  return {
                    id: item.id,
                    username: item.username,
                    suggestions: item.suggestions,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    expectedCount: item.expectedCount,
                    suggestionMessage: item.suggestionMessage,
                    actualCount: item.actualCount,
                    toiletName: item.toiletName,
                    isAccepted: item.isAccepted,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    address: item.address,
                    ward: item.ward,
                    district: item.district,
                    province: item.province,
                    newAddress: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                    status: item.status,
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: toiletCount,
                  position: ["bottomCenter"],
                  defaultCurrent: 1,
                }}
              />
            ) : (
              <Table
                className="table"
                bordered
                locale={locale}
                size="small"
                // scroll={{
                //   x: '100vw',
                // }}
                style={{ marginTop: "2%" }}
                loading={status === "loading" ? true : false}
                columns={ManageToiletColumn}
                onSelect={(record) =>
                  navigate(`toilet/${record.id}`, { state: { id: record.id } })
                }
                dataSource={toiletData.map((item, index) => {
                  return {
                    id: item.id,
                    username: item.username,
                    suggestions: item.suggestions,
                    message: item.message,
                    suggestionMessage: item.suggestionMessage,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    address: item.address,
                    ward: item.ward,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    district: item.district,
                    province: item.province,
                    expectedCount: item.expectedCount,
                    actualCount: item.actualCount,
                    toiletName: item.toiletName,
                    isAccepted: item.isAccepted,
                    newAddress: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                    status: item.status,
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: toiletCount,
                  position: ["bottomCenter"],
                  defaultCurrent: 1,
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ManageToilet;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCe4acOlWoIFH5GoHWjxW3sGN9wg3JQYcE",
  authDomain: "toilet-map-img.firebaseapp.com",
  projectId: "toilet-map-img",
  storageBucket: "toilet-map-img.appspot.com",
  messagingSenderId: "637099960525",
  appId: "1:637099960525:web:a4e0c3f398e64db554a184",
  measurementId: "G-FCTGJS77WS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
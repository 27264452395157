import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API_KEY = 'Bi8nuliwq6j53yp86tNTSY9tKobEFoHnw5bAb8gC'

export const getAutocompleteMap = createAsyncThunk(
  "map/getMap",
  async (input) => {
    const response = await axios.get(
      `https://rsapi.goong.io/Place/AutoComplete?api_key=${API_KEY}&input=${input}`,
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.predictions;
  }
);

export const getAddressByAutocomplete = createAsyncThunk(
  "map/getAddress",
  async (placeId) => {
    const response = await axios.get(
      `https://rsapi.goong.io/geocode?place_id=${placeId}&api_key=${API_KEY}`,
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.results[0];
  }
);

export const mapSlice = createSlice({
  name: "map",
  initialState: {
    data: [],
    dataAddress: [],
    status: "idle",
  },
  reducers: {
    deleteAddress: (state) => {
      state.data = [];
      state.dataAddress = [];
    },
  },
  extraReducers: (builder) => {
    // --- Xử lý trong reducer với case fulfilled ---
    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getAutocompleteMap.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getAddressByAutocomplete.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataAddress = action.payload;
      });
  },
});

export default mapSlice.reducer;
export const showGetApiMap = (state) => state.map.data;
export const showAddressBySearch = (state) => state.map.dataAddress;
export const { deleteAddress } = mapSlice.actions;

import { Result, Table, Tag } from "antd";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllToiletByComId,
  getAllToiletByComIdCount,
  showAllToiletByComId,
  showAllToiletByComIdCount,
} from "../../../../features/adminSlice";
import { useLocation } from "react-router";

function ManageToiletOfCompany() {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const toiletData = useSelector(showAllToiletByComId);
  const toiletDataCount = useSelector(showAllToiletByComIdCount);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const tableRef = useRef(null);
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  //table field
  const ManageToiletOfCompanyColumn = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tên nhà vệ sinh",
      dataIndex: "toiletName",
      width: "30%",
      ellipsis: true,
    },
    {
      title: "Địa chỉ",
      dataIndex: "newAddress",
      width: "48%",
      ellipsis: true,
    },
    {
      title: "Trạng thái",
      width: "20%",
      dataIndex: "status",
      align: "center",
      render: (item) => {
        return item === "Đang hoạt động" ? (
          <Tag color="processing">Đang hoạt động</Tag>
        ) : (
          <Tag color="error">Không hoạt động</Tag>
        );
      },
    },
  ];

  //screen size
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  //config empty table
  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  useEffect(() => {
    const data = {
      id: state.id,
      pageIndex: current,
    };
    dispatch(getAllToiletByComId(data));
    dispatch(getAllToiletByComIdCount(state.id));
  }, [current]);

  //
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Danh sách nhà vệ sinh",
    sheet: "Danh sách nhà vệ sinh",
  });

  return (
    <div>
      <button
        onClick={onDownload}
        className="statistic-toilet-content-excel-btn"
        style={{ cursor: "pointer" }}
      >
        Xuất file excel danh sách nhà vệ sinh
      </button>
      {screenSize < 1200 && screenSize > 768 ? (
        <Table
          size="small"
          locale={locale}
          scroll={{
            x: "100vw",
          }}
          //   loading={status === "loading" ? true : false}
          bordered
          className="table"
          columns={ManageToiletOfCompanyColumn}
          dataSource={toiletData.map((item, index) => {
            return {
              id: item.id,
              toiletName: item.toiletName,
              newAddress: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
              status: item.status,
              stt: current === 1 ? index + 1 : (current - 1) * 8 + (index + 1),
            };
          })}
          pagination={{
            current: current,
            showTotal: showTotal,
            onChange: onChange,
            position: ["bottomCenter"],
            total: toiletDataCount,
            pageSize: 8
          }}
        />
      ) : screenSize <= 768 ? (
        <Table
          size="small"
          //   loading={status === "loading" ? true : false}
          scroll={{
            x: "120vw",
          }}
          bordered
          className="table"
          locale={locale}
          columns={ManageToiletOfCompanyColumn}
          dataSource={toiletData.map((item, index) => {
            return {
              id: item.id,
              toiletName: item.toiletName,
              newAddress: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
              status: item.status,
              stt: current === 1 ? index + 1 : (current - 1) * 8 + (index + 1),
            };
          })}
          pagination={{
            current: current,
            showTotal: showTotal,
            onChange: onChange,
            position: ["bottomCenter"],
            pageSize: 8
          }}
        />
      ) : (
        <Table
          size="small"
          //   loading={status === "loading" ? true : false}
          bordered
          className="table"
          locale={locale}
          columns={ManageToiletOfCompanyColumn}
          dataSource={toiletData.map((item, index) => {
            return {
              id: item.id,
              toiletName: item.toiletName,
              newAddress: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
              status: item.status,
              stt: current === 1 ? index + 1 : (current - 1) * 8 + (index + 1),
            };
          })}
          pagination={{
            current: current,
            showTotal: showTotal,
            onChange: onChange,
            position: ["bottomCenter"],
            total: toiletDataCount,
            pageSize: 8
          }}
        />
      )}

      <Table
        ref={tableRef}
        locale={locale}
        style={{ display: "none" }}
        columns={ManageToiletOfCompanyColumn}
        dataSource={toiletData.map((item, index) => {
          return {
            id: item.id,
            toiletName: item.toiletName,
            newAddress: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
            status: item.status,
            stt: current === 1 ? index + 1 : (current - 1) * 8 + (index + 1),
          };
        })}
        pagination={false}
      />
    </div>
  );
}

export default ManageToiletOfCompany;

import { Button, Checkbox } from "antd";
import React from "react";
import { getRatingFilterTag, showDataRatingFilterTag } from "../../features/toiletManagerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

function FilterRating() {
  const showFilterTag = useSelector(showDataRatingFilterTag);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRatingFilterTag());
  }, []);
  
  return (
    <div>
      <div className="rating-each-toilet-filter-container">
        <p className="rating-each-toilet-filter-header-text">
          Lọc theo đánh giá kém
        </p>
        <div className="rating-each-toilet-filter-checkbox">
          <Checkbox>Tất cả</Checkbox>
        </div>
        {showFilterTag !== undefined
          ? showFilterTag.map((item) => {
              return (
                <div className="rating-each-toilet-filter-checkbox">
                  <Checkbox>{item.name}</Checkbox>
                </div>
              );
            })
          : ""}

        <p className="rating-each-toilet-filter-header-text-star">
          Lọc theo số sao
        </p>
        <div className="rating-each-toilet-filter-checkbox-star">
          <Checkbox>1 sao</Checkbox>
          <Checkbox>2 sao</Checkbox>
          <Checkbox>3 sao</Checkbox>
          <Checkbox>4 sao</Checkbox>
          <Checkbox>5 sao</Checkbox>
        </div>
        <p className="rating-each-toilet-filter-header-text-star">
          Lọc theo hiện trạng
        </p>
        <div className="rating-each-toilet-filter-checkbox">
          <Checkbox>Đã giải quyết</Checkbox>
        </div>
        <div className="rating-each-toilet-filter-checkbox">
          <Checkbox>Chưa giải quyết</Checkbox>
        </div>
        <div className="rating-each-toilet-filter-btn-container">
          <Button type="text">Hủy</Button>
          <Button type="primary">Lọc</Button>
        </div>
      </div>
    </div>
  );
}

export default FilterRating;

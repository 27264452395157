import { Icon } from "@iconify/react";
import { Button, Card, Col, Input, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import NumericInput from "../../../components/reuse/NumericInput";
import "./RechargeCom.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePhone,
  getInforByPhone,
  postInforByPhone,
  showInforByPhone,
  showPostInforByPhone,
  showStatusInfor,
} from "../../../features/rechargeSlice";
import swal from "sweetalert";

function RechargeCom(props) {
  const [selected, setSelected] = useState(-1);
  const [price, setPrice] = useState(0);
  const [phone, setPhone] = useState(0);
  const [screenSize, getDimension] = useState(window.innerWidth);
  const showInfor = useSelector(showInforByPhone);
  const showStatus = useSelector(showStatusInfor);
  const showPostInfor = useSelector(showPostInforByPhone);
  const dispatch = useDispatch();
  const method = "Chuyển khoản";
  const priceList = [
    {
      key: 10000,
      value: "10,000",
    },
    {
      key: 20000,
      value: "20,000",
    },
    {
      key: 30000,
      value: "30,000",
    },
    {
      key: 50000,
      value: "50,000",
    },
    {
      key: 100000,
      value: "100,000",
    },
    {
      key: 200000,
      value: "200,000",
    },
    {
      key: 300000,
      value: "300,000",
    },
    {
      key: 500000,
      value: "500,000",
    },
  ];

  //actions
  const handlePhoneNumber = (phone) => {
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    return phone.match(regexPhoneNumber) ? true : false;
  };

  ;

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const handleGetInforByPhone = (phone) => {
    dispatch(getInforByPhone(phone));
  };

  const handleDelPhone = (phone) => {
    if (phone.length < 10) {
      dispatch(deletePhone(phone));
    }
  };

  useEffect(() => {
    handleDelPhone(phone);
  }, [phone]);

  const handlePostInfor = () => {
    const dataConfig = {
      accountId: showInfor.accountId,
      total: parseInt(price),
      method: method,
    };
    dispatch(postInforByPhone(dataConfig));
  };

  console.log(showStatus);

  console.log(showPostInfor);

  return (
    <div>
      {screenSize <= 768 ? (
        <div>
          <div className="rechargeCom-phone-container">
            <p className="rechargeCom-phone">
              Số điện thoại<span style={{ color: "red" }}> *</span>
            </p>
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <NumericInput
                placeholder="Nhập số điện thoại"
                handleChange={(e) => setPhone(e.target.value)}
                value={phone}
                maxlength="10"
              />
              {phone.length < 10 || phone.length === undefined ? (
                <Button
                  className="rechargeCom-find-btn"
                  type="primary"
                  disabled
                >
                  Tìm
                </Button>
              ) : (
                <Button
                  onClick={() => handleGetInforByPhone(phone)}
                  className="rechargeCom-find-btn"
                  type="primary"
                >
                  Tìm
                </Button>
              )}
            </Space.Compact>
          </div>
          {Object.keys(showInfor).length !== 0 ? (
            <Card
              title="Thông tin khách hàng"
              bordered={true}
              style={{
                width: "100%",
                marginTop: "4%",
              }}
            >
              <div className="rechargeCom-infor-container">
                <div className="rechargeCom-infor-phone-container">
                  <p className="rechargeCom-infor-phone-title">Số điện thoại</p>
                  <p className="rechargeCom-infor-phone">{phone}</p>
                </div>
                <div className="rechargeCom-infor-name-container">
                  <p className="rechargeCom-infor-name-title">Tên khách hàng</p>
                  <p className="rechargeCom-infor-name">{showInfor.fullName}</p>
                </div>
              </div>
              <div className="rechargeCom-infor-price-container">
                <p className="rechargeCom-infor-price-title">
                  Chọn mệnh giá<span style={{ color: "red" }}> *</span>
                </p>
                <div className="rechargeCom-infor-price-card-container">
                  <div className="rechargeCom-infor-price-card">
                    {priceList.slice(0, 4).map((price, index) => {
                      return (
                        <div
                          key={index}
                          onClick={
                            selected !== index || selected === -1
                              ? () => [setSelected(index), setPrice(price.key)]
                              : () => [setSelected(-1), setPrice(0)]
                          }
                        >
                          {index === selected ? (
                            <div className="rechargeCom-infor-price-active">
                              <Icon
                                className="rechargeCom-infor-icon"
                                icon="material-symbols:check-circle-rounded"
                              />
                              <p>{price.value} VND</p>
                            </div>
                          ) : (
                            <div className="rechargeCom-infor-price">
                              <p>{price.value} VND</p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="rechargeCom-infor-price-card">
                    {priceList.slice(4, 8).map((price, index) => {
                      return (
                        <div
                          onClick={
                            selected !== index + 4 || selected === -1
                              ? () => [
                                  setSelected(index + 4),
                                  setPrice(price.key),
                                ]
                              : () => [setSelected(-1), setPrice(0)]
                          }
                        >
                          {index + 4 === selected ? (
                            <div className="rechargeCom-infor-price-active">
                              <Icon
                                className="rechargeCom-infor-icon"
                                icon="material-symbols:check-circle-rounded"
                              />
                              <p>{price.value} VND</p>
                            </div>
                          ) : (
                            <div className="rechargeCom-infor-price">
                              <p>{price.value} VND</p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="rechargeCom-infor-price-container">
                    <p className="rechargeCom-infor-price-title">
                      Nhập số tiển khác
                    </p>
                    {selected !== -1 ? (
                      <NumericInput
                        className="rechargeCom-infor-price-input"
                        placeholder="Nhập số tiền khác"
                        value={price}
                        handleChange={(e) => setPrice(e.target.value)}
                        maxlength="8"
                        disabled="disabled"
                      />
                    ) : (
                      <NumericInput
                        className="rechargeCom-infor-price-input"
                        placeholder="Nhập số tiền khác"
                        value={price}
                        handleChange={(e) => setPrice(e.target.value)}
                        maxlength="8"
                      />
                    )}
                  </div>
                </div>
              </div>
              {price === 0 ? (
                <Button disabled>Nạp tiền</Button>
              ) : (
                <Button
                  onClick={() =>
                    swal({
                      title: `Xác nhận nạp tiền`,
                      text: `Nạp ${price.toLocaleString(
                        "en-US"
                      )} VND cho số điện thoại ${phone}`,
                      icon: "warning",
                      buttons: ["Hủy", "Xác nhận"],
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        handlePostInfor();
                        swal("Nạp thành công", {
                          icon: "success",
                        });
                        setPhone(0);
                        dispatch(deletePhone());
                      } else {
                        swal("Lệnh nạp tiền bị hủy", {
                          icon: "error",
                        });
                      }
                    })
                  }
                  type="primary"
                >
                  Nạp tiền
                </Button>
              )}
            </Card>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Row>
          <Col span={8}>
            <div className="rechargeCom-phone-container">
              <p className="rechargeCom-phone">
                Số điện thoại<span style={{ color: "red" }}> *</span>
              </p>
              <Space.Compact
                style={{
                  width: "80%",
                }}
              >
                <NumericInput
                  placeholder="Nhập số điện thoại"
                  handleChange={(e) => setPhone(e.target.value)}
                  maxlength="10"
                  value={phone}
                />
                {phone.length < 10 || phone.length === undefined ? (
                  <Button
                    className="rechargeCom-find-btn"
                    type="primary"
                    disabled
                  >
                    Tìm
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleGetInforByPhone(phone)}
                    className="rechargeCom-find-btn"
                    type="primary"
                  >
                    Tìm
                  </Button>
                )}
              </Space.Compact>
            </div>
          </Col>
          {Object.keys(showInfor).length !== 0 ? (
            <Col span={16}>
              <Card
                title="Thông tin khách hàng"
                bordered={true}
                style={{
                  width: "100%",
                  marginTop: "2%",
                }}
              >
                <div className="rechargeCom-infor-container">
                  <div className="rechargeCom-infor-phone-container">
                    <p className="rechargeCom-infor-phone-title">
                      Số điện thoại
                    </p>
                    <p className="rechargeCom-infor-phone">{phone}</p>
                  </div>
                  <div className="rechargeCom-infor-name-container">
                    <p className="rechargeCom-infor-name-title">
                      Tên khách hàng
                    </p>
                    <p className="rechargeCom-infor-name">
                      {showInfor.fullName}
                    </p>
                  </div>
                </div>
                <div className="rechargeCom-infor-price-container">
                  <p className="rechargeCom-infor-price-title">
                    Chọn mệnh giá<span style={{ color: "red" }}> *</span>
                  </p>
                  <div className="rechargeCom-infor-price-card-container">
                    <div className="rechargeCom-infor-price-card">
                      {priceList.slice(0, 4).map((price, index) => {
                        return (
                          <div
                            key={index}
                            onClick={
                              selected !== index || selected === -1
                                ? () => [
                                    setSelected(index),
                                    setPrice(price.key),
                                  ]
                                : () => [setSelected(-1), setPrice(0)]
                            }
                            // onClick={() => [
                            //   setSelected(index),
                            //   setPrice(price),
                            // ]}
                          >
                            {index === selected ? (
                              <div className="rechargeCom-infor-price-active">
                                <Icon
                                  className="rechargeCom-infor-icon"
                                  icon="material-symbols:check-circle-rounded"
                                />
                                <p>{price.value} VND</p>
                              </div>
                            ) : (
                              <div className="rechargeCom-infor-price">
                                <p>{price.value} VND</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="rechargeCom-infor-price-card">
                      {priceList.slice(4, 8).map((price, index) => {
                        return (
                          <div
                            onClick={
                              selected !== index + 4 || selected === -1
                                ? () => [
                                    setSelected(index + 4),
                                    setPrice(price.key),
                                  ]
                                : () => [setSelected(-1), setPrice(0)]
                            }
                          >
                            {index + 4 === selected ? (
                              <div className="rechargeCom-infor-price-active">
                                <Icon
                                  className="rechargeCom-infor-icon"
                                  icon="material-symbols:check-circle-rounded"
                                />
                                <p>{price.value} VND</p>
                              </div>
                            ) : (
                              <div className="rechargeCom-infor-price">
                                <p>{price.value} VND</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="rechargeCom-infor-price-container">
                      <p className="rechargeCom-infor-price-title">
                        Nhập số tiển khác
                      </p>
                      {selected !== -1 ? (
                        <NumericInput
                          className="rechargeCom-infor-price-input"
                          placeholder="Nhập số tiền khác"
                          handleChange={(e) => setPrice(e.target.value)}
                          value={price}
                          maxlength="8"
                          disabled="disabled"
                        />
                      ) : (
                        <NumericInput
                          className="rechargeCom-infor-price-input"
                          placeholder="Nhập số tiền khác"
                          handleChange={(e) => setPrice(e.target.value)}
                          value={price}
                          maxlength="8"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* {price === 0 ? <Button disabled>Nạp tiền</Button> : <Button onClick={() => handlePostInfor()} type="primary">Nạp tiền</Button> } */}
                {price === 0 ? (
                  <Button disabled>Nạp tiền</Button>
                ) : (
                  <Button
                    onClick={() =>
                      swal({
                        title: `Xác nhận nạp tiền`,
                        text: `Nạp ${price.toLocaleString(
                          "en-US"
                        )} VND cho số điện thoại ${phone}`,
                        icon: "warning",
                        buttons: ["Hủy", "Xác nhận"],
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          handlePostInfor();
                          swal("Nạp thành công", {
                            icon: "success",
                          });
                          setPhone(0);
                          dispatch(deletePhone());
                        } else {
                          swal("Lệnh nạp tiền bị hủy", {
                            icon: "error",
                          });
                        }
                      })
                    }
                    type="primary"
                  >
                    Nạp tiền
                  </Button>
                )}
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      )}
    </div>
  );
}

export default RechargeCom;

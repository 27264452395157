import { Button, Popover } from "antd";
import React from "react";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import "./Header.scss";
import { useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { useEffect } from "react";

function Header(props) {
  const [screenSize, getDimension] = useState(window.innerWidth);

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <div className={props.header}>
      <p>{props.title}</p>
      {screenSize <= 900 ? (
        <div>
          <Button
            type="primary"
            onClick={props.onClick}
            className="header-btn"
            icon={<PlusCircleOutlined style={{ display: props.displayPlus }} />}
            style={{ display: props.display }}
          />
        </div>
      ) : (
        <div>
          {/* <Search
            className="header-search"
            enterButton
            placeholder="Tìm"
            value={props.searchValue}
            onChange={props.handleChange}
            onPressEnter={props.onPressEnter}
            onSearch={props.onSearch}
            style={{ width: 240, display: props.displaySearch }}
          /> */}
          {/* <Button
            type="primary"
            style={{ display: props.display }}
            onClick={props.onClick}
            className="header-btn"
            icon={<PlusCircleOutlined style={{ display: props.displayPlus }} />}
          >
            {props.btn}
          </Button> */}
          <Popover
            placement="bottomLeft"
            content={props.content}
            trigger="click"
          >
            <Button type="default" style={{ display: props.displayPop  || 'none'}}>
              <FilterOutlined /> Bộ lọc
            </Button>
          </Popover>
        </div>
      )}
    </div>
  );
}

export default Header;

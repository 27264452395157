import { Button, Input } from "antd";
import React, { useState } from "react";
import "./Config.scss";

function ConfigAccount() {
    const [changePass, setChangePass] = useState(false);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    
    return (
      <div className="account-toilet-container">
        {changePass ? (
          <div className="account-toilet-input-container-box">
            <div className="account-toilet-input-container">
              <p>Tài khoản</p>
              <Input className="account-toilet-input" value="dvvsq1" disabled />
            </div>
            <div className="account-toilet-input-container">
              <p>Mật khẩu cũ</p>
              <Input.Password placeholder="Nhập mật khẩu cũ" className="account-toilet-input" type="password"/>
            </div>
            <div className="account-toilet-input-container">
              <p>Mật khẩu mới</p>
              <Input.Password placeholder="Nhập mật khẩu mới" className="account-toilet-input" type="password"/>
            </div>
            <div className="account-toilet-input-container">
              <p>Xác nhận mật khẩu mới</p>
              <Input.Password placeholder="Xác nhận mật khẩu mới" className="account-toilet-input" type="password"/>
            </div>
            <Button onClick={() => setChangePass(false)} className="account-toilet-btn account-toilet-save-btn" type="primary">
              Lưu
            </Button>
          </div>
        ) : (
          <div className="account-toilet-input-container-box">
            <div className="account-toilet">
              <p>Tài khoản</p>
              <p>{localStorage.getItem('usernameAcc')}</p>
            </div>
            <div className="account-toilet">
              <p>Mật khẩu</p>
              <p>****</p>
            </div>
            <Button
              onClick={() => setChangePass(true)}
              className="account-toilet-btn"
              type="primary"
            >
              Đổi mật khẩu
            </Button>
          </div>
        )}
      </div>
    );
}

export default ConfigAccount
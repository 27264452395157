import { Button, Col, Popover, Result, Row, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Menu from "../../../components/menu/Menu";
import {
  manageToiletUnitColumn,
  manageToiletUnitData,
} from "./ManageToiletUnitColumn";
import Header from "../../../components/reuse/Header";
import { useNavigate } from "react-router-dom";
import "./ManageToiletUnit.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompany,
  getCompanyCount,
  searchCompany,
  searchCompanyCount,
  showAllCom,
  showComStatus,
  showCount,
  showSearchData,
  showSearchDataCount,
  showUpdateStatusCom,
  updateStatusCompany,
} from "../../../features/adminSlice";
import swal from "sweetalert";
import { Icon } from "@iconify/react";
import { TITLE6 } from "../../../Constant";

function ManageToiletUnit() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;
  const [screenSize, getDimension] = useState(window.innerWidth);
  const showAllCompany = useSelector(showAllCom);
  const showCountCom = useSelector(showCount);
  const showCountSearch = useSelector(showSearchDataCount);
  const status = useSelector(showComStatus);
  const showStatus = useSelector(showUpdateStatusCom);
  const dispatch = useDispatch();
  const searchData = useSelector(showSearchData);
  const [filterTable, setFilterTable] = useState(null);
  const [keyword, setKeyword] = useState("");

  //handle document title
  useEffect(() => {
    document.title = TITLE6;
  }, [])

  //handle change status in table
  const handleChangeStatus = (id, status, name) => {
    swal({
      title: "Xác nhận",
      text: `Bạn có chắc chắn muốn đổi trạng thái của ${name} không?`,
      icon: "warning",
      buttons: ["Hủy", "Xác nhận"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const data = {
          status: status,
          id: id,
        };
        dispatch(updateStatusCompany(data));
      } else {
      }
    });
  };

  //table field
  const ManageToiletUnitColumn = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tên đơn vị vệ sinh",
      dataIndex: "name",
      width: "24%",
      ellipsis: true,
    },
    {
      title: "Địa chỉ",
      dataIndex: "ad",
      width: "48%",
      ellipsis: true,
    },
    {
      title: "Trạng thái",
      ellipsis: true,
      width: "16%",
      dataIndex: "status",
      align: "center",
      render: (item) => {
        return item === "Đang hoạt động" ? (
          <Tag color="processing">Đang hoạt động</Tag>
        ) : (
          <Tag color="error">Không hoạt động</Tag>
        );
      },
    },
    // {
    //   title: "Số lượt báo cáo",
    //   dataIndex: "numberOfReport",
    //   width: "8%",
    //   align: 'right',
    //   ellipsis: true,
    //   render: (item) => {
    //     return item === 0 ? (
    //       '-'
    //     ) : (
    //       item
    //     );
    //   },
    // },
    {
      title: " ",
      dataIndex: "id",
      width: "8%",
      align: "right",
      render: (item, record) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={
                <div>
                  <p
                    onClick={() =>
                      navigate(`/managecompany/company/${item}`, {
                        state: { id: item, name: record.name },
                      })
                    }
                    className="manage-toilet-popover-see-infor"
                  >
                    Xem thông tin
                  </p>
                  <p
                    onClick={() =>
                      handleChangeStatus(
                        item,
                        record.status === "Đang hoạt động"
                          ? "Không hoạt động"
                          : "Đang hoạt động",
                        record.name
                      )
                    }
                    className="manage-toilet-popover-change-status"
                  >
                    Đổi trạng thái
                  </p>
                </div>
              }
              trigger="click"
            >
              <Button className="manage-toilet-unit-btn-more">
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //pagination
  useEffect(() => {
    dispatch(getCompany(current));
    dispatch(getCompanyCount());
  }, [current, showStatus]);

  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  // //search
  // const handleSearch = (value) => {
  //   dispatch(searchCompany(value));
  // }

  const onSearch = (value) => {
    dispatch(searchCompany(value));
    dispatch(searchCompanyCount(value));
  };

  //config empty table
  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    )
  }

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="manage-toilet-unit-container">
            <Header
              onClick={() => navigate("/createnewcompany")}
              header="header-no-line"
              title="Quản lý đơn vị vệ sinh"
              btn="Tạo đơn vị vệ sinh mới"
              // onPressEnter={onSearch}
              onSearch={onSearch}
            />
            {screenSize < 1200 && screenSize > 768 ? (
              <Table
                size="small"
                locale={locale}
                scroll={{
                  x: "120vw",
                }}
                loading={status === 'loading' ? true : false}
                bordered
                style={{ marginTop: "2%" }}
                className="table"
                columns={ManageToiletUnitColumn}
                dataSource={
                  searchData.length === 0
                    ? showAllCompany.map((item, index) => {
                        return {
                          id: item.id,
                          numberOfReport: item.numberOfReport,
                          name: item.name,
                          ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                          status: item.status,
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 10 + (index + 1),
                        };
                      })
                    : searchData.map((item, index) => {
                        return {
                          id: item.id,
                          numberOfReport: item.numberOfReport,
                          name: item.name,
                          ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                          status: item.status,
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 10 + (index + 1),
                        };
                      })
                }
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: showCountSearch !== 0 ? showCountSearch : showCountCom,
                  position: ["bottomCenter"],
                }}
              />
            ) : screenSize <= 768 ? (
              <Table
                size="small"
                loading={status === 'loading' ? true : false}
                scroll={{
                  x: "200vw",
                }}
                bordered
                style={{ marginTop: "2%" }}
                className="table"
                locale={locale}
                columns={ManageToiletUnitColumn}
                dataSource={
                  searchData.length === 0
                    ? showAllCompany.map((item, index) => {
                        return {
                          id: item.id,
                          numberOfReport: item.numberOfReport,
                          name: item.name,
                          ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                          status: item.status,
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 10 + (index + 1),
                        };
                      })
                    : searchData.map((item, index) => {
                        return {
                          id: item.id,
                          name: item.name,
                          numberOfReport: item.numberOfReport,
                          ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                          status: item.status,
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 10 + (index + 1),
                        };
                      })
                }
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: showCountSearch !== 0 ? showCountSearch : showCountCom,
                  position: ["bottomCenter"],
                }}
              />
            ) : (
              <Table
                size="small"
                loading={status === 'loading' ? true : false}
                bordered
                style={{ marginTop: "2%" }}
                className="table"
                locale={locale}
                columns={ManageToiletUnitColumn}
                dataSource={
                  searchData.length === 0
                    ? showAllCompany.map((item, index) => {
                        return {
                          id: item.id,
                          numberOfReport: item.numberOfReport,
                          name: item.name,
                          ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                          status: item.status,
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 10 + (index + 1),
                        };
                      })
                    : searchData.map((item, index) => {
                        return {
                          id: item.id,
                          name: item.name,
                          numberOfReport: item.numberOfReport,
                          ad: `${item.address}, ${item.ward}, ${item.district}, ${item.province}`,
                          status: item.status,
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 10 + (index + 1),
                        };
                      })
                }
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: showCountSearch !== 0 ? showCountSearch : showCountCom,
                  position: ["bottomCenter"],
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ManageToiletUnit;

import { Button, Col, Drawer, Row } from "antd";

import React, { useEffect, useState } from "react";
import { Icon, InlineIcon } from "@iconify/react";

import "../menu/Menu.scss";
import Logo from "../../asset/Toilet_Logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, showRole } from "../../features/authSlice";
import {
  getAccountInfor,
  inforStatus,
  showInfor,
} from "../../features/managerSlice";

function Menu(props) {
  const navigate = useNavigate();
  const user = localStorage.getItem("role");
  const dispatch = useDispatch();
  const infor = useSelector(showInfor);
  const status = useSelector(inforStatus);
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(logout());
    navigate("/");
  };

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //get data API
  useEffect(() => {
    dispatch(getAccountInfor());
  }, []);

  return screenSize <= 768 ? (
    <div style={{ minHeight: "96vh" }}>
      {user === "Manager" ? (
        <div className="menu-container">
          <Icon
            className="menu-container-icon"
            icon="ri:menu-unfold-line"
            onClick={showDrawer}
          />
          <Drawer
            placement={placement}
            closable={false}
            onClose={onClose}
            open={open}
            key={placement}
            width={250}
          >
            <div className="menu-header-name-container">
              <p>Quản lý</p>
              <h2>{infor.name}</h2>
            </div>
            <div className="menu-content-container">
              <a
                onClick={() => navigate("/manage")}
                className={props.styleManage}
              >
                <InlineIcon icon="ep:place" /> <span>Quản lý nhà vệ sinh</span>
              </a>
              <br />
              <a
                onClick={() => navigate("/user-history")}
                className={props.styleUserHistory}
              >
                <InlineIcon icon="gravity-ui:magnifier" /> <span>Tra cứu</span>
              </a>
              <br />
              <a
                onClick={() => navigate("/statistic")}
                className={props.styleStatistic}
              >
                <InlineIcon icon="mdi:report-finance" />
                <span>Báo cáo thống kê</span>
              </a>
              <br />
              <a
                onClick={() => navigate("/viewcheckintoilet")}
                className={props.styleCheckin}
              >
                <InlineIcon icon="ic:outline-edit-location" />
                <span>Quản lý lượt khách</span>
              </a>
              <br />
              {/* <a
                onClick={() => navigate("/reportlocation")}
                className={props.styleReport}
              >
                <InlineIcon icon="ep:location-filled" />
                <span>Báo cáo vị trí</span>
              </a>
              <br /> */}
              <a
                onClick={() => navigate("/viewads")}
                className={props.styleAdvertise}
              >
                <InlineIcon icon="mingcute:announcement-line" />
                <span>Quảng cáo</span>
              </a>
              <br />
              <a
                onClick={() => navigate("/printCard")}
                className={props.styleCard}
              >
                <InlineIcon icon="material-symbols:credit-card" />
                <span>Cấp thẻ</span>
              </a>
              <br />
              <a
                onClick={() => navigate("/config")}
                className={props.styleConfig}
              >
                <InlineIcon icon="icon-park-outline:setting-config" />
                <span>Cài đặt thông tin</span>
              </a>
            </div>
            <a onClick={handleSubmit} className="menu-log-out">
              <InlineIcon icon="ic:sharp-log-out" /> <span>Đăng xuất</span>
            </a>
          </Drawer>
        </div>
      ) : user === "Admin" ? (
        <div className="menu-container">
          <Icon
            className="menu-container-icon"
            icon="ri:menu-unfold-line"
            onClick={showDrawer}
          />
          <Drawer
            placement={placement}
            closable={false}
            onClose={onClose}
            open={open}
            key={placement}
            width={250}
          >
            <div className="menu-header-name-container">
              <p>Admin</p>
              <h2>Hệ thống quản lý</h2>
            </div>
            <div className="menu-content-container">
              <a
                onClick={() => navigate("/managecompany")}
                className={props.styleManage}
              >
                <InlineIcon icon="ep:place" />{" "}
                <span>Quản lý đơn vị vệ sinh</span>
              </a>
              <br />
              <a
                onClick={() => navigate("/statistictoiletunit")}
                className={props.styleStatistic}
              >
                <InlineIcon icon="mdi:report-finance" />
                <span>Báo cáo thống kê</span>
              </a>
              <br />
              <a
                onClick={() => navigate("/viewcheckincompany")}
                className={props.styleCheckin}
              >
                <InlineIcon icon="ic:outline-edit-location" />
                <span>Quản lý lượt khách</span>
              </a>
              <br />
              {/* <a
                onClick={() => navigate("/recommend")}
                className={props.styleRecommend}
              >
                <InlineIcon icon="iconoir:suggestion" />
                <span>Quản lý gợi ý</span>
              </a>
              <br /> */}

              <a
                onClick={() => navigate("/configtoiletunit")}
                className={props.styleConfigSystem}
              >
                <InlineIcon icon="ant-design:setting-outlined" />
                <span>Thiết lập hệ thống</span>
              </a>
              <br />
              {/* <a
              onClick={() => navigate("/script")}
              className={props.styleEmulator}
            >
              <InlineIcon icon="bx:code-block" />
              <span>Giả lập</span>
            </a>
            <br /> */}
              {/* <a
                onClick={() => navigate("/configinfor")}
                className={props.styleConfig}
              >
                <InlineIcon icon="icon-park-outline:setting-config" />
                <span>Cài đặt thông tin</span>
              </a> */}
            </div>
            <a onClick={handleSubmit} className="menu-log-out">
              <InlineIcon icon="ic:sharp-log-out" /> <span>Đăng xuất</span>
            </a>
          </Drawer>
        </div>
      ) : user === "Staff" ? (
        <div className="menu-container">
          <Icon
            className="menu-container-icon"
            icon="ri:menu-unfold-line"
            onClick={showDrawer}
          />
          <Drawer
            placement={placement}
            closable={false}
            onClose={onClose}
            open={open}
            key={placement}
            width={250}
          >
            <div className="menu-header-name-container">
              <p>Nhân viên</p>
              <h2>Hệ thống thanh toán</h2>
            </div>
            <div className="menu-content-container">
              {/* <a
                onClick={() => navigate("/managerating")}
                className={props.styleRating}
              >
                <InlineIcon icon="fluent:people-money-24-filled" />
                <span>Đánh giá bị báo cáo</span>
              </a>
              <br /> */}
              <a
                onClick={() => navigate("/recharge")}
                className={props.styleRecharge}
              >
                <InlineIcon icon="fluent:people-money-24-filled" />
                <span>Nạp tiền - Mua gói</span>
              </a>
              <br />
              <a
                onClick={() => navigate("/manageadvertisement")}
                className={props.styleAdvertise}
              >
                <InlineIcon icon="ant-design:setting-outlined" />
                <span>Quản lý quảng cáo</span>
              </a>
              <br />
            </div>
            <a onClick={handleSubmit} className="menu-log-out">
              <InlineIcon icon="ic:sharp-log-out" /> <span>Đăng xuất</span>
            </a>
          </Drawer>
        </div>
      ) : (
        navigate("/")
      )}
    </div>
  ) : (
    <div style={{ minHeight: "96vh" }}>
      {user === "Manager" ? (
        <div className="menu-container">
          <img className="menu-logo" src={infor.logo} alt="Logo" />
          <div className="menu-header-name-container">
            <p>Quản lý</p>
            <h2>{infor.name}</h2>
          </div>
          <div className="menu-content-container">
            <a
              onClick={() => navigate("/manage")}
              className={props.styleManage}
            >
              <InlineIcon icon="ep:place" /> <span>Quản lý nhà vệ sinh</span>
            </a>
            <br />
            <a
              onClick={() => navigate("/user-history")}
              className={props.styleUserHistory}
            >
              <InlineIcon icon="gravity-ui:magnifier" /> <span>Tra cứu</span>
            </a>
            <br />
            <a
              onClick={() => navigate("/statistic")}
              className={props.styleStatistic}
            >
              <InlineIcon icon="mdi:report-finance" />
              <span>Báo cáo thống kê</span>
            </a>
            <br />
            <a
              onClick={() => navigate("/viewcheckintoilet")}
              className={props.styleCheckin}
            >
              <InlineIcon icon="ic:outline-edit-location" />
              { <span>Quản lý lượt khách</span>}
            </a>
            <br />
            {/* <a
              onClick={() => navigate("/reportlocation")}
              className={props.styleReport}
            >
              <InlineIcon icon="ep:location-filled" />
              <span>Báo cáo vị trí</span>
            </a>
            <br /> */}
            {/* <a
              onClick={() => navigate("/viewads")}
              className={props.styleAdvertise}
            >
              <InlineIcon icon="mingcute:announcement-line" />
              <span>Quảng cáo</span>
            </a>
            <br />
            <a
              onClick={() => navigate("/printCard")}
              className={props.styleCard}
            >
              <InlineIcon icon="material-symbols:credit-card" />
              <span>Cấp thẻ</span>
            </a>
            <br />
            <a
              onClick={() => navigate("/config")}
              className={props.styleConfig}
            >
              <InlineIcon icon="icon-park-outline:setting-config" />
              <span>Cài đặt thông tin</span>
            </a> */}
          </div>
          <a onClick={handleSubmit} className="menu-log-out">
            <InlineIcon icon="ic:sharp-log-out" /> <span>Đăng xuất</span>
          </a>
        </div>
      ) : user === "Admin" ? (
        <div className="menu-container">
          <img className="menu-logo" src={Logo} alt="Logo" />
          <div className="menu-header-name-container">
            <p>Admin</p>
            <h2>Hệ thống quản lý</h2>
          </div>
          <div className="menu-content-container">
            <a
              onClick={() => navigate("/managecompany")}
              className={props.styleManage}
            >
              <InlineIcon icon="ep:place" /> <span>Quản lý đơn vị vệ sinh</span>
            </a>
            <br />
            <a
              onClick={() => navigate("/statistictoiletunit")}
              className={props.styleStatistic}
            >
              <InlineIcon icon="mdi:report-finance" />
              <span>Báo cáo thống kê</span>
            </a>
            <br />
            <a
              onClick={() => navigate("/viewcheckincompany")}
              className={props.styleCheckin}
            >
              <InlineIcon icon="ic:outline-edit-location" />
              <span>Quản lý lượt khách</span>
            </a>
            <br />
            {/* <a
              onClick={() => navigate("/recommend")}
              className={props.styleRecommend}
            >
              <InlineIcon icon="iconoir:suggestion" />
              <span>Quản lý gợi ý</span>
            </a>
            <br /> */}
            <a
              onClick={() => navigate("/configtoiletunit")}
              className={props.styleConfigSystem}
            >
              <InlineIcon icon="ant-design:setting-outlined" />
              <span>Thiết lập hệ thống</span>
            </a>
            <br />
            {/* <a
              onClick={() => navigate("/script")}
              className={props.styleEmulator}
            >
              <InlineIcon icon="bx:code-block" />
              <span>Giả lập</span>
            </a>
            <br /> */}

            {/* <a
              onClick={() => navigate("/configinfor")}
              className={props.styleConfig}
            >
              <InlineIcon icon="icon-park-outline:setting-config" />
              <span>Cài đặt thông tin</span>
            </a> */}
          </div>
          <a onClick={handleSubmit} className="menu-log-out">
            <InlineIcon icon="ic:sharp-log-out" /> <span>Đăng xuất</span>
          </a>
        </div>
      ) : user === "Staff" ? (
        <div className="menu-container">
          <img className="menu-logo" src={Logo} alt="Logo" />
          <div className="menu-header-name-container">
            <p>Nhân viên</p>
            <h2>Hệ thống thanh toán</h2>
          </div>
          <div className="menu-content-container">
            {/* <a
              onClick={() => navigate("/managerating")}
              className={props.styleRating}
            >
              <InlineIcon icon="fluent:people-money-24-filled" />
              <span>Đánh giá bị báo cáo</span>
            </a>
            <br /> */}
            <a
              onClick={() => navigate("/recharge")}
              className={props.styleRecharge}
            >
              <InlineIcon icon="fluent:people-money-24-filled" />
              <span>Nạp tiền - Mua gói</span>
            </a>
            <br />
            <a
              onClick={() => navigate("/manageadvertisement")}
              className={props.styleAdvertise}
            >
              <InlineIcon icon="ant-design:setting-outlined" />
              <span>Quản lý quảng cáo</span>
            </a>
            <br />
          </div>
          <a onClick={handleSubmit} className="menu-log-out">
            <InlineIcon icon="ic:sharp-log-out" /> <span>Đăng xuất</span>
          </a>
        </div>
      ) : (
        navigate("/")
      )}
    </div>
  );
}

export default Menu;

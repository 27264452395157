import { Badge, Button, Checkbox, Input, Tag } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { Upload } from "antd";
import ToiletImage from "../../../../asset/Toilet_Image.jpg";
import "../ManageToiletUnit.scss";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyById, showComById } from "../../../../features/adminSlice";

function InforEachToiletUnit() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const dataCom = useSelector(showComById);

  useEffect(() => {
    dispatch(getCompanyById(state.id));
  }, []);

  console.log(dataCom);

  return (
    <div className="infor-each-toilet-unit-container">
      <img className="infor-each-toilet-unit-image" src={dataCom.logo} alt="" />
      <div>
        {/* <div className="infor-each-toilet-unit">
          <p>Tài khoản</p>
          <p>dvvsq1</p>
        </div> */}
        <div className="infor-each-toilet-unit">
          <p>Tên đơn vị vệ sinh</p>
          <p>{dataCom.name}</p>
        </div>
        <div className="infor-each-toilet-unit">
          <p>Địa chỉ</p>
          <p>{dataCom.address}</p>
        </div>
        <div className="infor-each-toilet-unit">
          <p>Phường/Xã</p>
          <p>{dataCom.ward}</p>
        </div>
        <div className="infor-each-toilet-unit">
          <p>Quận/Huyện</p>
          <p>{dataCom.district}</p>
        </div>
        <div className="infor-each-toilet-unit">
          <p>Tỉnh/Thành phố</p>
          <p>{dataCom.province}</p>
        </div>
        <div className="infor-each-toilet-unit">
          <p>Trạng thái</p>
          {dataCom.status === "Đang hoạt động" ? (
            <p>
              <Tag color="blue">{dataCom.status}</Tag>
            </p>
          ) : (
            <p>
              <Tag color="red">{dataCom.status}</Tag>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default InforEachToiletUnit;

import React, { useEffect, useState } from "react";
import ToiletImage from "../../../../asset/Toilet_Image.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Row, Steps } from "antd";
import Menu from "../../../../components/menu/Menu";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";
import swal from "sweetalert";
import { postCompany, resetCom, showCom } from "../../../../features/adminSlice";
import { deleteAddress } from "../../../../features/mapSlice";

function CreateNewApproveUnit() {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const navigate = useNavigate();
  const showSuccess = useSelector(showCom);
  const dispatch = useDispatch();

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const handlePostToilet = () => {
    const dataApprove = {
      username: JSON.parse(localStorage.getItem('dataAccount')).username,
      password: JSON.parse(localStorage.getItem('dataAccount')).password,
      name: JSON.parse(localStorage.getItem('dataInforCom')).toiletName,
      address: JSON.parse(localStorage.getItem('dataInforCom')).street,
      ward: JSON.parse(localStorage.getItem('dataInforCom')).commune,
      district: JSON.parse(localStorage.getItem('dataInforCom')).district,
      province: JSON.parse(localStorage.getItem('dataInforCom')).province,
      // latitude: JSON.parse(localStorage.getItem('dataInforCom')).lat,
      // longitude: JSON.parse(localStorage.getItem('dataInforCom')).long,
      logo: JSON.parse(localStorage.getItem('dataInforCom')).imgArr,
      phone: JSON.parse(localStorage.getItem('dataInforCom')).phone,
    };
    dispatch(postCompany(dataApprove));
  };

  useEffect(() => {
    if (showSuccess === 201) {
      localStorage.removeItem("dataAccount");
      localStorage.removeItem("dataInforCom");
      dispatch(deleteAddress());
      dispatch(resetCom());
      navigate("/managecompany");
    }
  }, [showSuccess]);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="create-new-toilet-box-container">
            <Breadcrumbs
              title="Quản lý nhà vệ sinh"
              pageName="Tạo nhà vệ sinh mới"
              onRemove={() => navigate(-1)}
            />
            <div className="create-new-toilet-step-container">
              {screenSize < 1440 ? (
                <Steps
                  size="small"
                  current={2}
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : (
                <Steps
                  current={2}
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              )}
              {screenSize < 768 ? <Row>
                <Col style={{ marginTop: "4%" }} span={24}>
                  <div className="create-new-approve-toilet-unit-img-container-box">
                    <p></p>
                    <div className="create-new-approve-toilet-image-container">
                      <ScrollContainer>
                        {JSON.parse(localStorage.getItem('dataInforCom')).imgArr !== null ? (
                          <img
                            className="create-new-approve-toilet-unit-left-image"
                            src={JSON.parse(localStorage.getItem('dataInforCom')).imgArr}
                            alt=""
                            srcset=""
                          />
                        ) : (
                          ""
                        )}
                      </ScrollContainer>
                    </div>
                  </div>
                </Col>
                <Col flex={2} className="create-new-approve-unit-container">
                  <div className="create-new-approve-toilet-unit">
                    <p>Tên đơn vị vệ sinh</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).toiletName}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Tài khoản</p>
                    <p>{JSON.parse(localStorage.getItem('dataAccount')).username}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Địa chỉ</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).street}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Phường/Xã</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).commune}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Quận/Huyện</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).district}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Tỉnh/Thành phố</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).province}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Số điện thoại</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).phone}</p>
                  </div>
                  <div className="create-new-toilet-footer-container">
                    <Button
                      type="default"
                      style={{ marginRight: "1%" }}
                      onClick={() => navigate(-1)}
                    >
                      Trở về
                    </Button>
                    <Button type="primary" onClick={handlePostToilet}>
                      Xác nhận
                    </Button>
                  </div>
                </Col>
              </Row> : <Row>
                <Col style={{ marginTop: "4%" }} flex={1}>
                  <div className="create-new-approve-toilet-unit-img-container-box">
                    <p></p>
                    <div className="create-new-approve-toilet-image-container">
                      <ScrollContainer>
                        {JSON.parse(localStorage.getItem('dataInforCom')).imgArr !== null ? (
                          <img
                            className="create-new-approve-toilet-unit-left-image"
                            src={JSON.parse(localStorage.getItem('dataInforCom')).imgArr}
                            alt=""
                            srcset=""
                          />
                        ) : (
                          ""
                        )}
                      </ScrollContainer>
                    </div>
                  </div>
                </Col>
                <Col flex={4} style={{marginLeft: '8%'}} className="create-new-approve-unit-container">
                  <div className="create-new-approve-toilet-unit">
                    <p>Tên đơn vị vệ sinh</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).toiletName}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Tài khoản</p>
                    <p>{JSON.parse(localStorage.getItem('dataAccount')).username}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Địa chỉ</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).street}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Phường/Xã</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).commune}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Quận/Huyện</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).district}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Tỉnh/Thành phố</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).province}</p>
                  </div>
                  <div className="create-new-approve-toilet-unit">
                    <p>Số điện thoại</p>
                    <p>{JSON.parse(localStorage.getItem('dataInforCom')).phone}</p>
                  </div>
                  <div className="create-new-toilet-footer-container">
                    <Button
                      type="default"
                      style={{ marginRight: "1%" }}
                      onClick={() => navigate(-1)}
                    >
                      Trở về
                    </Button>
                    <Button type="primary" onClick={handlePostToilet}>
                      Xác nhận
                    </Button>
                  </div>
                </Col>
              </Row>}
              
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateNewApproveUnit;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../asset/Toilet_Logo.png";
import { showRole, showStatus, userLogin } from "../../features/authSlice";
import { useCookies } from "react-cookie";
import { LoadingOutlined } from "@ant-design/icons";
import "../login/Login.scss";
import { Button, Input, Spin, message } from "antd";
import Loading from "../../asset/Loading.gif";
import { TITLE } from "../../Constant";


function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const user = useSelector(showRole);
  const status = useSelector(showStatus);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    document.title = TITLE;
  }, [])

  // var hashPassword = bcrypt.hashSync(password, 10);

  //actions
  useEffect(() => {
    if (user === "Manager") {
      navigate("/manage");
    } else if (user === "Admin") {
      navigate("/managecompany");
    } else if (user === "Staff") {
      navigate("/recharge");
    }
  }, [user]);

  useEffect(() => {
    if (
      localStorage.getItem("Exp") !== null &&
      localStorage.getItem("role") === "Manager" &&
      localStorage.getItem("remember")
    ) {
      navigate("/manage");
    } else if (
      localStorage.getItem("Exp") !== null &&
      localStorage.getItem("role") === "Admin" &&
      localStorage.getItem("remember")
    ) {
      navigate("/managecompany");
    } else if (
      localStorage.getItem("Exp") !== null &&
      localStorage.getItem("role") === "Staff" &&
      localStorage.getItem("remember")
    ) {
      navigate("/recharge");
    }
    if (localStorage.getItem("username") !== null) {
      setUsername(localStorage.getItem("username"));
      setPassword(localStorage.getItem("password"));
      setRemember(localStorage.getItem("remember"));
    }
  }, []);

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Sai tên tài khoản hoặc mật khẩu",
    });
  };

  useEffect(() => {
    if (status === "error") {
      error();
    }
  }, [status]);

  const loadingGif = (
    <img src={Loading} style={{ width: 100, height: 100, marginTop: "40px" }} />
  );

  return (
    <div>
      {status === "loading" ? (
        <Spin indicator={loadingGif}>
          <div className="login-container">
            {contextHolder}
            <div className="login-left-container">
              <img className="login-left-logo" src={Logo} alt="" />
              <p className="login-left-welcome">Chào mừng bạn đến với</p>
              <p className="login-left-title">Hệ thống nhà vệ sinh công cộng</p>
            </div>
            <div className="login-right-container">
              <p className="login-right-title">Đăng nhập</p>
              <div className="login-right-username-container">
                <p className="login-right-username">Tài khoản</p>
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  className="login-right-username-input"
                  type="text"
                  placeholder="Nhập tài khoản"
                  value={username}
                />
              </div>
              <div className="login-right-password-container">
                <p className="login-right-password">Mật khẩu</p>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  className="login-right-password-input"
                  type="password"
                  placeholder="Nhập mật khẩu"
                  value={password}
                />
              </div>
              <div className="login-right-remember-password-container">
                <input
                  className="login-right-remember-password-input"
                  type="checkbox"
                  name="remember"
                  id=""
                  onChange={() => setRemember(!remember)}
                  checked={remember}
                />
                <p className="login-right-remember-password">Nhớ mật khẩu</p>
              </div>
              <Button
                onClick={() =>
                  dispatch(userLogin({ username, password, remember }))
                }
                className="login-right-btn"
              >
                Đăng nhập
              </Button>
            </div>
          </div>
        </Spin>
      ) : (
        <div className="login-container">
          {contextHolder}
          <div className="login-left-container">
            <img className="login-left-logo" src={Logo} alt="" />
            <p className="login-left-welcome">Chào mừng bạn đến với</p>
            <p className="login-left-title">Hệ thống nhà vệ sinh công cộng</p>
          </div>
          <div className="login-right-container">
            <p className="login-right-title">Đăng nhập</p>
            <div className="login-right-username-container">
              <p className="login-right-username">Tài khoản</p>
              <input
                onChange={(e) => setUsername(e.target.value)}
                className="login-right-username-input"
                type="text"
                placeholder="Nhập tài khoản"
                value={username}
              />
            </div>
            <div className="login-right-password-container">
              <p className="login-right-password">Mật khẩu</p>
              <input
                onChange={(e) => setPassword(e.target.value)}
                className="login-right-password-input"
                type="password"
                placeholder="Nhập mật khẩu"
                value={password}
              />
            </div>
            <div className="login-right-remember-password-container">
              <input
                className="login-right-remember-password-input"
                type="checkbox"
                name="remember"
                id=""
                onChange={() => setRemember(!remember)}
                checked={remember}
              />
              <p className="login-right-remember-password">Nhớ mật khẩu</p>
            </div>
            <Button
              onClick={() =>
                dispatch(userLogin({ username, password, remember }))
              }
              className="login-right-btn"
            >
              Đăng nhập
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;

import {
  Button,
  Col,
  Popover,
  Radio,
  Row,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import Menu from "../../../components/menu/Menu";
import TagHeader from "../../../components/reuse/TagHeader";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import CreateNewAdvertisement from "./CreateNewAdvertisement";
import ModalReuse from "../../../components/reuse/ModalReuse";
import CreateNewInAppAdvertisement from "./CreateNewInAppAdvertisement";
import { useDispatch, useSelector } from "react-redux";
import {
  createExternalAdvertisement,
  createInternalAdvertisement,
  deleteAdvertisement,
  deleteExternalAdvertisement,
  deleteInternalAdvertisement,
  getExternalAdvertisement,
  getExternalAdvertisementById,
  getExternalAdvertisementCount,
  getExternalAppAdvertisement,
  getExternalAppAdvertisementCount,
  getInternalAdvertisement,
  getInternalAdvertisementById,
  getInternalAdvertisementCount,
  showDelExAdvertisement,
  showDelInAdvertisement,
  showExternalAdvertisement,
  showExternalAdvertisementCount,
  showExternalAppAdvertisement,
  showExternalAppAdvertisementCount,
  showInternalAdvertisement,
  showInternalAdvertisementCount,
  showPostExternalAdvertisement,
  showPostInternalAdvertisement,
  showSpecificExternalAdvertisement,
  showSpecificInternalAdvertisement,
  showUpdateExternalAdvertisement,
  showUpdateInternalAdvertisement,
  updateExternalAdvertisement,
  updateInternalAdvertisement,
} from "../../../features/staffSlice";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import swal from "sweetalert";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { TITLE11 } from "../../../Constant";

//PopoverInApp
const HandleContentInApp = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <p
        onClick={() =>
          navigate(`inapp/${props.id}`, {
            state: { id: props.id },
          })
        }
        className="manage-advertisement-popover"
      >
        Xem thông tin
      </p>
    </div>
  );
};

//PopoverExApp (2)
const HandleContentExApp = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <p
        onClick={() =>
          navigate(`exapp/${props.id}`, {
            state: { id: props.id },
          })
        }
        className="manage-advertisement-popover"
      >
        Xem thông tin
      </p>
    </div>
  );
};

//PopoverEx (3)
const HandleContentEx = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <p
        onClick={() =>
          navigate(`ex/${props.id}`, {
            state: { id: props.id },
          })
        }
        className="manage-advertisement-popover"
      >
        Xem thông tin
      </p>
    </div>
  );
};

//Main function
function ManageAdvertisement() {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [current, setCurrent] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const dataInApp = useSelector(showInternalAdvertisement);
  const dataExApp = useSelector(showExternalAdvertisement);
  const dataExWebApp = useSelector(showExternalAppAdvertisement);
  const dataInAppCount = useSelector(showInternalAdvertisementCount);
  const dataExAppCount = useSelector(showExternalAdvertisementCount);
  const dataExWebAppCount = useSelector(showExternalAppAdvertisementCount);
  const dataPostExApp = useSelector(showPostExternalAdvertisement);
  const dataPostInApp = useSelector(showPostInternalAdvertisement);
  const dataUpdateExApp = useSelector(showUpdateExternalAdvertisement);
  const dataUpdateInApp = useSelector(showUpdateInternalAdvertisement);
  const resDelInAds = useSelector(showDelInAdvertisement);
  const resDelExAds = useSelector(showDelExAdvertisement);
  const [imgUrl, setImgUrl] = useState(null);
  const [titleExAds, setTitleExAds] = useState("");
  const [descriptionExAds, setDescriptionExAds] = useState("");
  const [urlExAds, setUrlExAds] = useState("");
  const [titleInAds, setTitleInAds] = useState("");
  const [descriptionInAds, setDescriptionInAds] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [imgUrlUpdate, setImgUrlUpdate] = useState(null);
  const [titleExAdsUpdate, setTitleExAdsUpdate] = useState("");
  const [descriptionExAdsUpdate, setDescriptionExAdsUpdate] = useState("");
  const [urlExAdsUpdate, setUrlExAdsUpdate] = useState("");
  const [titleInAdsUpdate, setTitleInAdsUpdate] = useState("");
  const [descriptionInAdsUpdate, setDescriptionInAdsUpdate] = useState("");
  const [startDateUpdate, setStartDateUpdate] = useState("");
  const [endDateUpdate, setEndDateUpdate] = useState("");
  const [id, setId] = useState(0);

  const [value, setValue] = useState("Internal");
  const [valueUpdate, setValueUpdate] = useState("Internal");
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;
  const showData = useSelector(showSpecificInternalAdvertisement);
  const showExData = useSelector(showSpecificExternalAdvertisement);

  //Table for ex Ads (2)
  const manageExAdColumns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Mô tả",
      ellipsis: true,
      width: "32%",
      dataIndex: "description",
    },
    {
      title: "Liên kết",
      ellipsis: true,
      width: "20%",
      dataIndex: "url",
      render: (text) => (
        <a target="_blank" href={text}>
          {text}
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            style={{ cursor: "pointer" }}
            color="#0B79D9"
            icon="material-symbols:edit"
            onClick={() => handleGeExAdsById(item)}
          />
        );
      },
      width: "4%",
      align: "right",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            color="red"
            style={{ cursor: "pointer" }}
            icon="material-symbols:delete"
            onClick={() => handleDeleteExAds(item)}
          />
        );
      },
      width: "4%",
      align: "right",
    },
    {
      title: " ",
      dataIndex: "id",
      width: "8%",
      align: "right",
      render: (item, record) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={<HandleContentExApp id={item} />}
              trigger="click"
            >
              <Button className="manage-toilet-unit-btn-more">
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  //Table for ex Ads (3)
  const manageExColumns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Mô tả",
      ellipsis: true,
      width: "32%",
      dataIndex: "description",
    },
    {
      title: "Liên kết",
      ellipsis: true,
      width: "20%",
      dataIndex: "url",
      render: (text) => (
        <a target="_blank" href={text}>
          {text}
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            style={{ cursor: "pointer" }}
            color="#0B79D9"
            icon="material-symbols:edit"
            onClick={() => handleGeExAdsById(item)}
          />
        );
      },
      width: "4%",
      align: "right",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            color="red"
            style={{ cursor: "pointer" }}
            icon="material-symbols:delete"
            onClick={() => handleDeleteExAds(item)}
          />
        );
      },
      width: "4%",
      align: "right",
    },
    {
      title: " ",
      dataIndex: "id",
      width: "8%",
      align: "right",
      render: (item, record) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={<HandleContentEx id={item} />}
              trigger="click"
            >
              <Button className="manage-toilet-unit-btn-more">
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  //Table for in Ads
  const manageInAdColumns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Mô tả",
      ellipsis: true,
      width: "24%",
      dataIndex: "description",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "endDate",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            style={{ cursor: "pointer" }}
            color="#0B79D9"
            icon="material-symbols:edit"
            onClick={() => handleGeInAdsById(item)}
          />
        );
      },
      width: "4%",
      align: "right",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            color="red"
            style={{ cursor: "pointer" }}
            icon="material-symbols:delete"
            onClick={() => dispatch(handleDeleteInAds(item))}
          />
        );
      },
      width: "4%",
      align: "right",
    },
    {
      title: " ",
      dataIndex: "id",
      width: "8%",
      align: "right",
      render: (item, record) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={<HandleContentInApp id={item} />}
              trigger="click"
            >
              <Button className="manage-toilet-unit-btn-more">
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  //handle document title
  useEffect(() => {
    document.title = TITLE11;
  }, [])

  //Action upload img
  const uploadImageAsPromise = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const storageRef = ref(storage, `ads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        alert(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
        });
      }
    );
  };

  //Action upload img
  const uploadImageAsPromiseUpdate = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const storageRef = ref(storage, `ads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        alert(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrlUpdate(downloadURL);
        });
      }
    );
  };

  //Action delete img
  const deleteImage = (url) => {
    const storage = getStorage();
    // Create a reference to the file to delete
    const desertRef = ref(storage, url);
    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        console.log("ok");
        setImgUrl(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Action resize screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //Action for get pagination data when changing page
  const onChange = (page) => {
    setCurrent(page);
  };

  //Action get data in/ex app
  useEffect(() => {
    const dataInAds = {
      type: "Internal",
      pageIndex: current,
    };
    const dataExAds = {
      type: "External",
      pageIndex: current,
    };
    const dataExAppAds = {
      type: "External-App",
      pageIndex: current,
    };
    dispatch(getInternalAdvertisement(dataInAds));
    dispatch(getExternalAdvertisement(dataExAds));
    dispatch(getExternalAppAdvertisement(dataExAppAds));
    dispatch(getInternalAdvertisementCount("Internal"));
    dispatch(getExternalAdvertisementCount("External"));
    dispatch(getExternalAppAdvertisementCount("External-App"));
  }, [
    current,
    dataPostExApp,
    dataPostInApp,
    resDelInAds,
    resDelExAds,
    dataUpdateExApp,
    dataUpdateInApp,
  ]);

  //get value of radio button in create & Update new ads
  const onChangeValueAdd = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  //Action create new ExAds
  const handleCreateNewExAppAds = () => {
    const data = {
      title: titleExAds,
      imageSource: imgUrl,
      description: descriptionExAds,
      url: urlExAds,
      type: value,
    };
    dispatch(createExternalAdvertisement(data));
    setShowAdd(false);
    setTitleExAds("");
    setImgUrl(null);
    setDescriptionExAds("");
    setUrlExAds("");
  };

  //Action create new InAds
  const handleCreateNewInAppAds = () => {
    const data = {
      title: titleInAds,
      imageSource: imgUrl,
      description: descriptionInAds,
      startDate: startDate,
      endDate: endDate,
      type: value,
    };
    dispatch(createInternalAdvertisement(data));
    setShowAdd(false);
    setTitleInAds("");
    setImgUrl(null);
    setDescriptionInAds("");
    setStartDate("");
    setEndDate("");
  };

  //Action handle Date change in create In Ads
  const onChangeStartDate = (value, dateString) => {
    setStartDate(dateString.toString());
  };
  const onChangeEndDate = (value, dateString) => {
    setEndDate(dateString.toString());
  };

  //Action handle Date change in update In Ads
  const onChangeStartDateUpdate = (value, dateString) => {
    setStartDateUpdate(dateString.toString());
  };
  const onChangeEndDateUpdate = (value, dateString) => {
    setEndDateUpdate(dateString.toString());
  };

  //Action update new ExAds
  const handleUpdateExAppAds = () => {
    const data = {
      title: titleExAdsUpdate,
      imageSource: imgUrlUpdate,
      description: descriptionExAdsUpdate,
      url: urlExAdsUpdate,
      type: valueUpdate,
      id: id,
    };
    dispatch(updateExternalAdvertisement(data));
    setShowUpdate(false);
  };

  //Action update new InAds
  const handleUpdateInAppAds = () => {
    const data = {
      title: titleInAdsUpdate,
      imageSource: imgUrlUpdate,
      description: descriptionInAdsUpdate,
      startDate: startDateUpdate,
      endDate: endDateUpdate,
      type: valueUpdate,
      id: id,
    };
    dispatch(updateInternalAdvertisement(data));
    console.log(data);
    setShowUpdate(false);
  };

  //Action delete In Ads
  const handleDeleteInAds = (id) => {
    swal({
      title: "Xác nhận",
      text: "Bạn có chắc chắn muốn xóa quảng cáo này không?",
      icon: "warning",
      buttons: ["Hủy", "Xác nhận"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteInternalAdvertisement(id));
      } else {
      }
    });
  };

  //Action delete Ex Ads
  const handleDeleteExAds = (id) => {
    swal({
      title: "Xác nhận",
      text: "Bạn có chắc chắn muốn xóa quảng cáo này không?",
      icon: "warning",
      buttons: ["Hủy", "Xác nhận"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteInternalAdvertisement(id));
      } else {
      }
    });
  };

  //Action get In Ads by Id
  const handleGeInAdsById = (id) => {
    dispatch(getInternalAdvertisementById(id));
    setShowUpdate(true);
  };

  useEffect(() => {
    if (showData !== {}) {
      setImgUrlUpdate(showData.imageSource);
      setTitleInAdsUpdate(showData.title);
      setValueUpdate(showData.type);
      setDescriptionInAdsUpdate(showData.description);
      setStartDateUpdate(showData.startDate);
      setEndDateUpdate(showData.endDate);
      setId(showData.id);
    }
  }, [showData]);

  console.log(startDateUpdate);

  //Action get Ex Ads by Id
  const handleGeExAdsById = (id) => {
    dispatch(getExternalAdvertisementById(id));
    setShowUpdate(true);
  };

  useEffect(() => {
    if (showExData !== {}) {
      setImgUrlUpdate(showExData.imageSource);
      setTitleExAdsUpdate(showExData.title);
      setValueUpdate(showExData.type);
      setDescriptionExAdsUpdate(showExData.description);
      setUrlExAdsUpdate(showExData.url);
      setId(showExData.id);
    }
  }, [showExData]);

  //Validate create
  const handleCreateValidation = () => {
    if (value === "Internal") {
      if (titleInAds === "" || descriptionInAds === "" || startDate === "") {
        messageApi.open({
          type: "warning",
          content: "Không được bỏ trống các ô nhập có dấu *",
        });
      } else {
        handleCreateNewInAppAds();
      }
    } else {
      if (titleExAds === "" || descriptionExAds === "" || urlExAds === "") {
        messageApi.open({
          type: "warning",
          content: "Không được bỏ trống các ô nhập có dấu *",
        });
      } else {
        handleCreateNewExAppAds();
      }
    }
  };

  console.log(valueUpdate);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleAdvertise="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div>
            <TagHeader
              header="header-line"
              title="Quản lý quảng cáo"
              display="none"
              displaySearch="none"
              label1="Quảng cáo khuyến mãi"
              label2="Quảng cáo Website"
              label3="Quảng cáo ứng dụng"
              handleCreateNew={() => setShowAdd(true)}
              children2={
                <div>
                  {contextHolder}
                  {screenSize <= 1024 ? (
                    <Table
                      size="small"
                      scroll={{ x: "120vw" }}
                      style={{ marginTop: "0" }}
                      bordered
                      className="table"
                      columns={manageExAdColumns}
                      dataSource={dataExApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          url: item.url,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataExAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  ) : (
                    <Table
                      size="small"
                      style={{ marginTop: "0" }}
                      bordered
                      className="table"
                      columns={manageExAdColumns}
                      dataSource={dataExApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          url: item.url,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataExAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  )}
                </div>
              }
              children3={
                <div>
                  {contextHolder}
                  {screenSize <= 1024 ? (
                    <Table
                      size="small"
                      scroll={{x: '120vw'}}
                      bordered
                      className="table"
                      style={{ marginTop: "0" }}
                      columns={manageExColumns}
                      dataSource={dataExWebApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          url: item.url,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataExWebAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  ) : (
                    <Table
                      size="small"
                      bordered
                      className="table"
                      style={{ marginTop: "0" }}
                      columns={manageExColumns}
                      dataSource={dataExWebApp.map((item, index) => {
                        return {
                          stt:
                            current === 1
                              ? index + 1
                              : (current - 1) * 8 + (index + 1),
                          title: item.title,
                          description: item.description,
                          url: item.url,
                          id: item.id,
                        };
                      })}
                      pagination={{
                        current: current,
                        onChange: onChange,
                        showTotal: showTotal,
                        total: dataExWebAppCount,
                        position: ["bottomCenter"],
                        pageSize: 8,
                      }}
                    />
                  )}
                </div>
              }
              children1={
                <div>
                  {contextHolder}
                  {screenSize <= 1024 ? <Table
                    size="small"
                    scroll={{x: '120vw'}}
                    style={{ marginTop: "0" }}
                    bordered
                    className="table"
                    columns={manageInAdColumns}
                    dataSource={dataInApp.map((item, index) => {
                      return {
                        stt:
                          current === 1
                            ? index + 1
                            : (current - 1) * 8 + (index + 1),
                        title: item.title,
                        description: item.description,
                        startDate: item.startDate,
                        endDate: item.endDate,
                        id: item.id,
                      };
                    })}
                    pagination={{
                      current: current,
                      onChange: onChange,
                      showTotal: showTotal,
                      total: dataInAppCount,
                      position: ["bottomCenter"],
                      pageSize: 8,
                    }}
                  /> : <Table
                  size="small"
                  style={{ marginTop: "0" }}
                  bordered
                  className="table"
                  columns={manageInAdColumns}
                  dataSource={dataInApp.map((item, index) => {
                    return {
                      stt:
                        current === 1
                          ? index + 1
                          : (current - 1) * 8 + (index + 1),
                      title: item.title,
                      description: item.description,
                      startDate: item.startDate,
                      endDate: item.endDate,
                      id: item.id,
                    };
                  })}
                  pagination={{
                    current: current,
                    onChange: onChange,
                    showTotal: showTotal,
                    total: dataInAppCount,
                    position: ["bottomCenter"],
                    pageSize: 8,
                  }}
                />}
                  
                </div>
              }
            />
            <ModalReuse
              open={showAdd}
              handleCancel={() => [setShowAdd(false), deleteImage(imgUrl)]}
              handleOk={handleCreateValidation}
              titleOk="Tạo mới"
              title="Thêm quảng cáo mới"
              width={600}
              children={
                <div>
                  <Radio.Group onChange={onChangeValueAdd} value={value}>
                    <Radio value={"Internal"}>Khuyến mãi</Radio>
                    <Radio value={"External"}>Quảng cáo Website</Radio>
                    <Radio value={"External-App"}>Quảng cáo ứng dụng</Radio>
                  </Radio.Group>
                  {value === "Internal" ? (
                    <CreateNewInAppAdvertisement
                      title={titleInAds}
                      description={descriptionInAds}
                      imgUrl={imgUrl}
                      upload={uploadImageAsPromise}
                      onChangeTitle={(e) => setTitleInAds(e.target.value)}
                      onChangeDescription={(e) =>
                        setDescriptionInAds(e.target.value)
                      }
                      onChangeStartDate={onChangeStartDate}
                      onChangeEndDate={onChangeEndDate}
                    />
                  ) : (
                    <CreateNewAdvertisement
                      title={titleExAds}
                      description={descriptionExAds}
                      url={urlExAds}
                      imgUrl={imgUrl}
                      upload={uploadImageAsPromise}
                      onChangeTitle={(e) => setTitleExAds(e.target.value)}
                      onChangeDescription={(e) =>
                        setDescriptionExAds(e.target.value)
                      }
                      onChangeUrl={(e) => setUrlExAds(e.target.value)}
                    />
                  )}
                </div>
              }
            />

            <ModalReuse
              open={showUpdate}
              handleCancel={() => setShowUpdate(false)}
              handleOk={
                valueUpdate === "Internal"
                  ? () => handleUpdateInAppAds()
                  : () => handleUpdateExAppAds()
              }
              titleOk="Cập nhật"
              title="Cập nhật quảng cáo"
              width={600}
              children={
                <div>
                  {valueUpdate === "Internal" ? (
                    <CreateNewInAppAdvertisement
                      title={titleInAdsUpdate}
                      description={descriptionInAdsUpdate}
                      imgUrl={imgUrlUpdate}
                      upload={uploadImageAsPromiseUpdate}
                      onChangeTitle={(e) => setTitleInAdsUpdate(e.target.value)}
                      onChangeDescription={(e) =>
                        setDescriptionInAdsUpdate(e.target.value)
                      }
                      onChangeStartDate={onChangeStartDateUpdate}
                      defaultStartDate={dayjs(startDateUpdate, "DD-MM-YYYY")}
                      defaultEndDate={dayjs(endDateUpdate, "DD-MM-YYYY")}
                      onChangeEndDate={onChangeEndDateUpdate}
                    />
                  ) : valueUpdate === "External" ? (
                    <CreateNewAdvertisement
                      title={titleExAdsUpdate}
                      description={descriptionExAdsUpdate}
                      url={urlExAdsUpdate}
                      imgUrl={imgUrlUpdate}
                      upload={uploadImageAsPromiseUpdate}
                      onChangeTitle={(e) => setTitleExAdsUpdate(e.target.value)}
                      onChangeDescription={(e) =>
                        setDescriptionExAdsUpdate(e.target.value)
                      }
                      onChangeUrl={(e) => setUrlExAdsUpdate(e.target.value)}
                    />
                  ) : (
                    <CreateNewAdvertisement
                      title={titleExAdsUpdate}
                      description={descriptionExAdsUpdate}
                      url={urlExAdsUpdate}
                      imgUrl={imgUrlUpdate}
                      upload={uploadImageAsPromiseUpdate}
                      onChangeTitle={(e) => setTitleExAdsUpdate(e.target.value)}
                      onChangeDescription={(e) =>
                        setDescriptionExAdsUpdate(e.target.value)
                      }
                      onChangeUrl={(e) => setUrlExAdsUpdate(e.target.value)}
                    />
                  )}
                </div>
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ManageAdvertisement;

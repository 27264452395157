import { Col, Result, Row, Spin, Table } from "antd";
import React, { useEffect } from "react";
import Menu from "../../../components/menu/Menu";
import Breadcrumbs from "../../../components/reuse/Breadcrumbs.jsx";
import "../statisticToiletUnit/StatisticToiletUnit.scss";
import { DatePicker, Space, Input } from "antd";
import {
  StatisticToiletUnitColumn,
  StatisticToiletUnitColumnExportExcel,
} from "./StatisticToiletUnitColumn.js";
import { useDispatch, useSelector } from "react-redux";
import {
  countStatisticAd,
  getCountStatisticAdmin,
  getMonthStatistic,
  getMonthStatisticAdmin,
  getStatistic,
  getStatisticAdmin,
  getStatisticAll,
  showCountStatisticAd,
  showStatistic,
  showStatisticAd,
  showStatisticAll,
  showStatisticMonth,
  showStatisticMonthAd,
  statisticStatus,
} from "../../../features/statisticSlice";
import { Icon, InlineIcon } from "@iconify/react";
import SockJsClient from "react-stomp";
import { useRef } from "react";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { useState } from "react";
import {
  getStatisticCount,
  showStatisticCount,
} from "../../../features/toiletManagerSlice";
dayjs.locale("vi");

const TITLE = `Báo cáo số tiền tạm thu`;
const SOCKET_URL = "https://api.publictoilet.click/ws-message";

function StatisticToiletUnit() {
  //websocket
  const [message, setMessage] = useState("You server message here.");
  const customSubcribe = {
    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
  };
  //
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [current, setCurrent] = useState(1);
  const statistic = useSelector(showStatisticAd);
  const statisticAll = useSelector(showStatisticAll);
  const status = useSelector(statisticStatus);
  const statisticMonth = useSelector(showStatisticMonthAd);
  const statisticCount = useSelector(showCountStatisticAd);
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs().format("DD/MM/YYYY"));
  const [endDateMonth, setEndDateMonth] = useState(
    dayjs().format("DD/MM/YYYY")
  );
  const [startDateMonth, setStartDateMonth] = useState(
    dayjs().startOf("month").format("DD/MM/YYYY")
  );
  const format = "DD/MM/YYYY";
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //websocket
  let onConnected = () => {
    console.log("Connected!!");
  };
  let onMessageReceived = (msg) => {
    setMessage(msg);
  };
  //

  //action
  const onChange = (range) => {
    setStartDate(range[0].format(format));
    setEndDate(range[1].format(format));
  };
  const onSearch = (value) => console.log(value);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Tổng số tiền tạm thu nhà vệ sinh",
    sheet: "Revenue",
  });

  //get data API
  useEffect(() => {
    dispatch(getMonthStatisticAdmin());
    const data = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(getStatisticAll(data));
    // document.title = TITLE;
  }, [message]);

  useEffect(() => {
    const data = {
      startDate: startDate,
      endDate: endDate,
      pageIndex: current,
    };
    dispatch(getStatisticAdmin(data));
  }, [startDate, endDate, current, message]);

  useEffect(() => {
    dispatch(getCountStatisticAdmin());
  }, []);

  console.log(statisticCount)

  const onChangeNavigate = (value) => {
    setCurrent(value);
  };

  //config empty table
  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  console.log(statisticCount);

  //render
  return (
    <div className="container statistic-container">
      <Row>
        <Col className="statistic-left-container" span={4}>
          <Menu styleStatistic="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <SockJsClient
            url={SOCKET_URL}
            ref={(client) => console.log(client)}
            debug={true}
            topics={["/topic/check-in"]}
            onConnect={onConnected}
            onDisconnect={() => console.log("Disconnected!")}
            onMessage={(msg) => onMessageReceived(msg)}
            subscribeHeaders={customSubcribe}
            headers={customSubcribe}
            autoReconnect={true}
          />
          <div className="statistic-table-content-container">
            <div className="statistic-each-toilet">
              <div className="statistic-each-toilet-number-container">
                <div className="statistic-each-toilet-number-header">
                  <p className="statistic-each-toilet-count-number">
                    {statisticMonth.totalTurn !== undefined
                      ? statisticMonth.totalTurn.toLocaleString("en-US")
                      : ""}
                  </p>
                  <Icon
                    className="statistic-each-toilet-count-icon"
                    icon="material-symbols:person-celebrate-rounded"
                  />
                </div>
                <p className="statistic-each-toilet-number">
                  Tổng lượt khách trong tháng
                </p>
                <p className="statistic-each-toilet-rate">
                  {startDateMonth} - {endDateMonth}
                </p>
              </div>
              <div className="statistic-each-toilet-number-container">
                <div className="statistic-each-toilet-number-header">
                  <p className="statistic-each-toilet-count-number">
                    {statisticMonth.totalRevenue !== undefined
                      ? statisticMonth.totalRevenue.toLocaleString("en-US")
                      : ""}
                  </p>
                  <Icon
                    className="statistic-each-toilet-count-icon"
                    icon="material-symbols:person-celebrate-rounded"
                  />
                </div>
                <p className="statistic-each-toilet-number">
                  Tổng số tiền tạm thu trong tháng
                </p>
                <p className="statistic-each-toilet-rate">
                  {startDateMonth} - {endDateMonth}
                </p>
              </div>
            </div>
            <div className="statistic-toilet-content-excel-container">
              <RangePicker
                locale={locale}
                defaultValue={[
                  dayjs(startDate, format),
                  dayjs(endDate, format),
                ]}
                placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                className="statistic-table-header-date"
                format="DD/MM/YYYY"
                presets={[
                  {
                    label: "Tuần trước",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Tháng trước",
                    value: [dayjs().add(-1, "month"), dayjs()],
                  },
                  {
                    label: "Năm trước",
                    value: [dayjs().add(-1, "year"), dayjs()],
                  },
                ]}
                onChange={onChange}
              />

              <button
                onClick={onDownload}
                className="statistic-toilet-content-excel-btn"
                style={{ cursor: "pointer" }}
              >
                Xuất file excel số tiền tạm thu
              </button>
            </div>
            {screenSize < 768 ? (
              <Table
                size="small"
                bordered
                scroll={{
                  x: "120vw",
                }}
                locale={locale}
                loading={status === "loading" ? true : false}
                className="table"
                columns={StatisticToiletUnitColumn}
                dataSource={statistic.map((item, index) => {
                  return {
                    companyName: item.companyName,
                    toiletId: item.toiletId,
                    totalRevenue: item.totalRevenue.toLocaleString("en-US"),
                    walkInGuestRevenue:
                      item.walkInGuestRevenue.toLocaleString("en-US"),
                    walkInGuestCount:
                      item.walkInGuestCount.toLocaleString("en-US"),
                    usingTurnRevenue:
                      item.usingTurnRevenue.toLocaleString("en-US"),
                    usingTurnCount: item.usingTurnCount.toLocaleString("en-US"),
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 8 + (index + 1),
                  };
                })}
                pagination={{
                  current: current,
                  onChange: onChangeNavigate,
                  showTotal: showTotal,
                  total: statisticCount,
                  position: ["bottomCenter"],
                  pageSize: 8
                }}
              />
            ) : (
              <Table
                size="small"
                bordered
                locale={locale}
                loading={status === "loading" ? true : false}
                className="table"
                columns={StatisticToiletUnitColumn}
                dataSource={statistic.map((item, index) => {
                  return {
                    companyName: item.companyName,
                    toiletId: item.toiletId,
                    totalRevenue: item.totalRevenue.toLocaleString("en-US"),
                    walkInGuestRevenue:
                      item.walkInGuestRevenue.toLocaleString("en-US"),
                    walkInGuestCount:
                      item.walkInGuestCount.toLocaleString("en-US"),
                    usingTurnRevenue:
                      item.usingTurnRevenue.toLocaleString("en-US"),
                    usingTurnCount: item.usingTurnCount.toLocaleString("en-US"),
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 8 + (index + 1),
                  };
                })}
                pagination={{
                  current: current,
                  onChange: onChangeNavigate,
                  showTotal: showTotal,
                  total: statisticCount,
                  position: ["bottomCenter"],
                  pageSize: 8
                }}
              />
            )}

            <Table
              ref={tableRef}
              locale={locale}
              style={{ display: "none" }}
              columns={StatisticToiletUnitColumnExportExcel}
              dataSource={statisticAll.map((item, index) => {
                return {
                  companyName: item.companyName,
                  toiletId: item.toiletId,
                  totalRevenue: item.totalRevenue.toLocaleString("en-US"),
                  walkInGuestRevenue:
                    item.walkInGuestRevenue.toLocaleString("en-US"),
                  walkInGuestCount:
                    item.walkInGuestCount.toLocaleString("en-US"),
                  usingTurnRevenue:
                    item.usingTurnRevenue.toLocaleString("en-US"),
                  usingTurnCount: item.usingTurnCount.toLocaleString("en-US"),
                  stt:
                    current === 1
                      ? index + 1
                      : (current - 1) * 8 + (index + 1),
                };
              })}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default StatisticToiletUnit;
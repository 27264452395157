import { Button, Col, Row, Space } from "antd";
import React, { useRef } from "react";
import Menu from "../../../components/menu/Menu";
import Header from "../../../components/reuse/Header";
import { useNavigate } from "react-router-dom";
import NumericInput from "../../../components/reuse/NumericInput";
import { useState } from "react";
import QRCode from "react-qr-code";
import "./PrintCard.scss";
import Logo from "../../../asset/Toilet_Logo.png";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePhone,
  getInforByPhone,
  showInforByPhone,
  showStatusInfor,
} from "../../../features/rechargeSlice";
import { useEffect } from "react";

function PrintCard() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState(0);
  const showInfor = useSelector(showInforByPhone);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const showStatus = useSelector(showStatusInfor);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGetInforByPhone = (phone) => {
    dispatch(getInforByPhone(phone));
  };

  const handleDelPhone = (phone) => {
    if (phone.length < 10) {
      dispatch(deletePhone(phone));
    }
  };

  useEffect(() => {
    handleDelPhone(phone);
  }, [phone]);

  console.log(showInfor);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleCard="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="print-card-container">
            <Header
              header="header-line"
              title="Cấp thẻ cho người dùng"
              display="none"
              displaySearch="none"
            />
            <div className="print-card">
              <p className="print-card-phone">Số điện thoại (*)</p>
              <Space.Compact
                className="print-card-input"
                style={{
                  width: "400px",
                }}
              >
                <NumericInput
                  placeholder="Nhập số điện thoại"
                  value={phone}
                  handleChange={(e) => setPhone(e.target.value)}
                  maxlength="10"
                />
                {phone.length < 10 || phone.length === undefined ? (
                  <Button className="print-card-btn" disabled>
                    Tìm
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleGetInforByPhone(phone)}
                    className="print-card-btn"
                    type="primary"
                  >
                    Tìm
                  </Button>
                )}
              </Space.Compact>
            </div>
            {showStatus === "succeeded" ? (
              <div className="print-card-qr-container-box">
                <p>Thẻ thành viên</p>
                <div style={{display: 'flex'}} ref={componentRef}>
                  <div
                    style={{
                      marginRight: "2%",
                      marginBottom: "1%",
                      marginTop: "8px",
                      height: "5.4cm",
                      width: "9cm",
                      border: "0.5px solid #D8E9F9",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                    className="print-card-qr-container"
                  >
                    <div
                      style={{
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        top: "8px",
                        left: "8px",
                        fontSize: "12px",
                      }}
                      className="print-card-qr-img-container"
                    >
                      <img
                        style={{ width: "24px", marginRight: "8px" }}
                        className="print-card-qr-img"
                        src={Logo}
                        alt=""
                      />
                      <p style={{ fontWeight: "bold" }}>
                        Dịch vụ công ích quận 1
                      </p>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#0B79D9",
                        width: "120px",
                        height: "24px",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        borderRadius: "0 4px 0 32px",
                      }}
                      className="print-card-qr-right"
                    ></div>
                    <div
                      style={{
                        backgroundColor: "#0B79D9",
                        width: "120px",
                        height: "24px",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        borderRadius: "0 32px 0 4px",
                      }}
                      className="print-card-qr-left"
                    ></div>
                    <div className="print-card-qr-code">
                      <QRCode
                        size={80}
                        style={{marginBottom: '16px'}}
                        value={showInfor.accountId.toString()}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginRight: "2%",
                      marginBottom: "1%",
                      marginTop: "8px",
                      height: "5.4cm",
                      width: "9cm",
                      border: "0.5px solid #D8E9F9",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                    className="print-card-qr-container"
                  >
                    <div
                      style={{
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        top: "8px",
                        left: "8px",
                        fontSize: "12px",
                      }}
                      className="print-card-qr-img-container"
                    >
                    </div>
                    <div
                      style={{
                        backgroundColor: "#0B79D9",
                        width: "100%",
                        height: "24px",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        borderRadius: "4px",
                      }}
                      className="print-card-qr-right"
                    ></div>
                    <div
                      style={{
                        backgroundColor: "#0B79D9",
                        width: "100%",
                        height: "24px",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        borderRadius: "4px",
                      }}
                      className="print-card-qr-left"
                    ></div>
                    <div className="print-card-qr-code">
                      <QRCode
                        size={80}
                        style={{marginBottom: '16px'}}
                        value={showInfor.accountId.toString()}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  onClick={handlePrint}
                  type="primary"
                  className="print-card-qr-btn"
                >
                  In thẻ
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PrintCard;

import { Col, Row, Table } from "antd";
import React from "react";
import Menu from "../../../components/menu/Menu";
import TagHeader from "../../../components/reuse/TagHeader";
import ConfigAccountAdmin from "./ConfigAccountAdmin";
import Header from "../../../components/reuse/Header";


function ConfigInforAdmin() {
  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleConfig="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="config-infor-admin-container">
            <Header
              header="header-line"
              title="Cài đặt thông tin"
              display="none"
              displaySearch="none"
            />
            <ConfigAccountAdmin />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ConfigInforAdmin;

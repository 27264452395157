import React, { useEffect, useState } from "react";
import Menu from "../../../components/menu/Menu";
import { Button, Col, Popover, Row, Table } from "antd";
import Header from "../../../components/reuse/Header";
import { RatingColumn } from "./ManageRatingColumn";
import { FilterOutlined } from "@ant-design/icons";
import FilterRating from "../../../components/reuse/FilterRating";

function ManageRating() {
  const [screenSize, getDimension] = useState(window.innerHeight);
  const [current, setCurrent] = useState(1);

  //show total pagination
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //actions
  const setDimension = () => {
    getDimension(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //pagination
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  const FakeData = [
    {
      stt: 1,
      date: "14/04/2023 - 23:45:00",
      reason: "Nhà vệ sinh rất rất dơ, thái độ nhân viên không hề thân thiện",
      rating: "1",
      status: "Chưa giải quyết",
    },
  ];

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleRating="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="manage-toilet-container">
            <Header
              // onClick={() => navigate("/createinfortoilet")}
              header="header-no-line"
              title="Quản lý đánh giá bị báo cáo"
              display="none"
              displayPlus="none"
              displaySearch="none"
              displayPop="block"
              content={<FilterRating />}
            />
            {screenSize < 900 ? (
              <Table
                size="small"
                className="table"
                bordered
                columns={RatingColumn}
                style={{ marginTop: "2%" }}
                dataSource={FakeData}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  position: ["bottomCenter"],
                  total: 1,
                }}
              />
            ) : (
              <Table
                className="table"
                bordered
                columns={RatingColumn}
                style={{ marginTop: "2%" }}
                dataSource={FakeData}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  position: ["bottomCenter"],
                  total: 1,
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ManageRating;

import { Col, Row } from 'antd'
import React from 'react'
import Menu from '../../../components/menu/Menu'
import TagHeader from '../../../components/reuse/TagHeader'
import ConfigInfor from './ConfigInfor'
import ConfigAccount from './ConfigAccount'
import { TITLE5 } from '../../../Constant'
import { useEffect } from 'react'

function Config() {
  //handle document title
  useEffect(() => {
    document.title = TITLE5;
  }, [])
  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleConfig="menu-content-link-active" />
        </Col>
        <Col span={19}>
        <TagHeader
            label1='Tài khoản'
            label2='Thông tin đơn vị vệ sinh'
            children1={<ConfigAccount />}
            children2={<ConfigInfor />}
            title='Cài đặt thông tin'
            displayRightButton="none"
          />
        </Col>
      </Row>
    </div>
  )
}

export default Config
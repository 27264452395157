import { Icon } from "@iconify/react";
import { Button, Popover } from "antd";
import dayjs from "dayjs";

export const UserHistoryColumn = [
  {
    title: "STT",
    dataIndex: "stt",
    key: "stt",
    width: "6%",
    ellipsis: true,
    align: "center",
    fixed: "left",
    render: (id, record, index) => {
      ++index;
      return index;
    },
    showSorterTooltip: false,
  },
  {
    title: "Tên nhà vệ sinh",
    dataIndex: "toiletName",
    key: "toiletName",
    width: "20%",
    ellipsis: true,
    fixed: "left",
    render: (item) =>
      item === "0" ? (
        <p style={{ textAlign: "center", fontStyle: "italic", opacity: "80%" }}>
          Chưa có dữ liệu
        </p>
      ) : (
        <p>{item}</p>
      ),
    onCell: (item) => ({
      colSpan: item.toiletName !== "0" ? 1 : 3,
    }),
  },
  {
    title: "Loại dịch vụ",
    dataIndex: "serviceName",
    key: "serviceName",
    width: "10%",
    ellipsis: true,
    fixed: "left",
    render: (item) => {
      return item !== "0" ? item : "N/A";
    },
    onCell: (item) => ({
      colSpan: item.serviceName !== "0" ? 1 : 0,
    }),
  },
  {
    title: "Ngày giờ",
    dataIndex: "checkinTime",
    key: "checkinTime",
    width: "8%",
    ellipsis: true,
    fixed: "left",
    render: (item) => {
      return item !== "0" ? dayjs(item).format('DD/MM/YYYY HH:mm') : "N/A";
    },
    onCell: (item) => ({
      colSpan: item.checkinTime !== "0" ? 1 : 0,
    }),
  },
  {
    title: "Phương thức thanh toán",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    width: "10%",
    // ellipsis: true,
    align: "left",
    render: (item) => {
      return item !== "0" ? item : "N/A";
    },
    onCell: (item) => ({
      colSpan: item.paymentMethod !== "0" ? 1 : 0,
    }),
  },
];

export const UserPaymentHistoryColumn = [
  {
    title: "STT",
    dataIndex: "stt",
    key: "stt",
    width: "4%",
    ellipsis: true,
    align: "center",
    fixed: "left",
    render: (id, record, index) => {
      ++index;
      return index;
    },
  },
  {
    title: "Ngày nạp",
    dataIndex: "createdDate",
    key: "createdDate",
    width: "10%",
    ellipsis: true,
    fixed: "left",
    render: (item) => {
      return item !== "0" ? dayjs(item).format('DD/MM/YYYY HH:mm') : "N/A";
    },
    onCell: (item) => ({
      colSpan: item.checkinTime !== "0" ? 1 : 0,
    }),
  },
]
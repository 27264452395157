import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import StatisticApi from "../service/configApi.js";

export const getStatistic = createAsyncThunk(
  "statistic/getStatisticData",
  async (data) => {
    const response = await StatisticApi.get(
      `/statistics?company-id=${data.id}&from-date=${data.startDate}&to-date=${data.endDate}&pageIndex=${data.pageIndex}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );

    ;
    return response.data.data;
  }
);

export const getMonthStatistic = createAsyncThunk(
  "statistic/getMonthStatisticData",
  async (id) => {
    const response = await StatisticApi.get(
      `/statistics/total/month?company-id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );

    ;
    return response.data.data;
  }
);

export const getMonthStatisticAdmin = createAsyncThunk(
  "statistic/getMonthStatistic",
  async () => {
    const response = await StatisticApi.get(`/statistics/total/month`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const getStatisticAll = createAsyncThunk(
  "statistic/getStaAll",
  async (data) => {
    const response = await StatisticApi.get(`/statistics?from-date=${data.startDate}&to-date=${data.endDate}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const getStatisticAdmin = createAsyncThunk(
  "statistic/getStatisticAd",
  async (data) => {
    const response = await StatisticApi.get(
      `/statistics?from-date=${data.startDate}&to-date=${data.endDate}&pageIndex=${data.pageIndex}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.data;
  }
);

export const getCountStatisticAdmin = createAsyncThunk(
  "statistic/countStatisticAd",
  async () => {
    const response = await StatisticApi.get(`/statistics/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const statisticSlide = createSlice({
  name: "statistic",
  initialState: {
    data: [],
    dataMonth: {},
    dataMonthAd: {},
    dataStatistic: [],
    dataStatisticAll: [],
    status: "idle",
    count: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    // --- Xử lý trong reducer với case fulfilled ---
    builder
      .addCase(getStatistic.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatistic.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      });
    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getMonthStatisticAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataMonthAd = action.payload;
      });

    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getMonthStatistic.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataMonth = action.payload;
      });

    builder
      .addCase(getStatisticAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatisticAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataStatistic = action.payload;
      });

    builder.addCase(getStatisticAll.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataStatisticAll = action.payload;
    });

    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getCountStatisticAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.count = action.payload;
      });
  },
});

export const showStatistic = (state) => state.statistic.data;
export const showStatisticAll = (state) => state.statistic.dataStatisticAll;
export const showStatisticMonth = (state) => state.statistic.dataMonth;
export const showStatisticMonthAd = (state) => state.statistic.dataMonthAd;
export const showStatisticAd = (state) => state.statistic.dataStatistic;
export const statisticStatus = (state) => state.statistic.status;
export const showCountStatisticAd = (state) => state.statistic.count;
export default statisticSlide.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../service/configApi.js";

export const getToiletByDistrict = createAsyncThunk(
  "company/getDistrictToilet",
  async (district) => {
    const response = await api.get(`/toilets/district?district=${district}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const getAllToiletByComId = createAsyncThunk(
  "company/getAllToiletById",
  async (data) => {
    const response = await api.get(`/toilets?company-id=${data.id}&pageIndex=${data.pageIndex}&pageSize=8`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const getAllToiletByComIdCount = createAsyncThunk(
  "company/getAllToiletByIdCount",
  async (id) => {
    const response = await api.get(`/toilets/count?company-id=${id}`, {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyIiwiaWF0IjoxNjkyMTYyMDQ4LCJleHAiOjE2OTI3NjY4NDgsInVzZXJuYW1lIjoibWFuYWdlci0xIiwicm9sZSI6Ik1hbmFnZXIiLCJhdXRob3JpdGllcyI6W3siYXV0aG9yaXR5IjoiUk9MRV9NYW5hZ2VyIn1dfQ.OlMjK0_V2V90eqE4zMCTLBfTNZK7a_-xUSLE_Nol2eekikOzaaZvW-9OR04iBMySXWwZL8gR_YLEPSBLsD1__w`,
      },
    });
    return response.data.data;
  }
);

export const getFullPackagePrice = createAsyncThunk(
  "company/getFullPackage",
  async () => {
    const response = await api.get(`/combos`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const getPackagePriceById = createAsyncThunk(
  "company/getPackageById",
  async (id) => {
    const response = await api.get(`/combos/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const postPackagePrice = createAsyncThunk(
  "company/postPackage",
  async (data) => {
    const response = await api.post(`/combos`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data;
  }
);

export const updatePackagePrice = createAsyncThunk(
  "company/updatePackage",
  async (data) => {
    const response = await api.put(`/combos/${data.id}`, data,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data;
  }
);

export const deletePackagePrice = createAsyncThunk(
  "company/deletePackage",
  async (id) => {
    const response = await api.delete(`/combos/${id}`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data;
  }
);

export const getCompany = createAsyncThunk(
  "company/getCom",
  async (pageIndex) => {
    const response = await api.get(
      `/companies?pageIndex=${pageIndex}&pageSize=10`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.data;
  }
);

export const getToiletReportByCompanyId = createAsyncThunk(
  "company/getToiletReportByComId",
  async (id) => {
    const response = await api.get(
      `/reports?company-id=${id}&pageIndex=1&pageSize=10`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    ;
    return response.data.data;
  }
);

export const getCompanyById = createAsyncThunk(
  "company/getComById",
  async (id) => {
    const response = await api.get(`companies/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const getCompanyCount = createAsyncThunk(
  "company/getCountCom",
  async () => {
    const response = await api.get(`/companies/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getCheckinCom = createAsyncThunk(
  "company/getCheckin",
  async (pageIndex) => {
    const response = await api.get(
      `/check-in?pageIndex=${pageIndex}&pageSize=10`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.data;
  }
);

export const getCheckinComCount = createAsyncThunk(
  "company/getCheckinCount",
  async () => {
    const response = await api.get(`/check-in/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const postCompany = createAsyncThunk(
  "company/postNewCom",
  async (data) => {
    const response = await api.post(`/companies`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.status;
  }
);

export const updateStatusCompany = createAsyncThunk(
  "company/updateStatusCom",
  async (data) => {
    const response = await api.patch(`/companies/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const getRecommend = createAsyncThunk("company/getRec", async () => {
  const response = await api.get(`/suggestions`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return response.data.data;
});

export const getSensitiveWord = createAsyncThunk(
  "company/getWord",
  async (pageIndex) => {
    const response = await api.get(
      `/sensitive-words?pageIndex=${pageIndex}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.data;
  }
);

export const getSensitiveWordById = createAsyncThunk(
  "company/getWordById",
  async (id) => {
    const response = await api.get(`/sensitive-words/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const getSensitiveWordCount = createAsyncThunk(
  "company/getWordCount",
  async () => {
    const response = await api.get(`/sensitive-words/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const postSensitiveWord = createAsyncThunk(
  "company/postSenWord",
  async (data) => {
    const response = await api.post(`/sensitive-words`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const updateSensitiveWord = createAsyncThunk(
  "company/updateSenWord",
  async (data) => {
    const response = await api.put(`/sensitive-words/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data;
  }
);

export const getServiceToilet = createAsyncThunk(
  "company/getService",
  async (type) => {
    const response = await api.get(`/services`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const postServiceToilet = createAsyncThunk(
  "company/postService",
  async (data) => {
    const response = await api.post(`/services/batch-update`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data;
  }
);

export const updateTurnPriceServiceToilet = createAsyncThunk(
  "company/updateTurnPrice",
  async (data) => {
    const response = await api.put(`/services?turnPrice=${data.turnPrice}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data;
  }
);

export const deleteSensitiveWord = createAsyncThunk(
  "company/deleteSenWord",
  async (id) => {
    const response = await api.delete(`/sensitive-words/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const getToiletType = createAsyncThunk(
  "company/getType",
  async (type) => {
    const response = await api.get(`/facilities/type?type=${type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const postToiletType = createAsyncThunk(
  "company/postType",
  async (data) => {
    const response = await api.post(`/facilities`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const deleteToiletType = createAsyncThunk(
  "company/deleteType",
  async (id) => {
    const response = await api.delete(`/facilities/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const getFacilityType = createAsyncThunk(
  "company/getFaciType",
  async (type) => {
    const response = await api.get(`/facilities/type?type=${type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const getFacilityTypeById = createAsyncThunk(
  "company/getFaciTypeById",
  async (id) => {
    const response = await api.get(`/facilities/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const postFacilityType = createAsyncThunk(
  "company/postFacility",
  async (data) => {
    const response = await api.post(`/facilities`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const updateFacilityType = createAsyncThunk(
  "company/updateFacility",
  async (data) => {
    const response = await api.patch(`/facilities/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const deleteFacilityType = createAsyncThunk(
  "company/deleteFacility",
  async (id) => {
    const response = await api.delete(`/facilities/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const getCommonComment = createAsyncThunk(
  "company/getCommon",
  async () => {
    const response = await api.get(`/common-comments`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    return response.data.data;
  }
);

export const postCommonComment = createAsyncThunk(
  "company/postCommon",
  async (data) => {
    const response = await api.post(`/common-comments`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const updateCommonComment = createAsyncThunk(
  "company/updateCommon",
  async (data) => {
    const response = await api.patch(`/common-comments/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    ;
    return response.data;
  }
);

export const deleteCommonComment = createAsyncThunk(
  "company/delCommon",
  async (id) => {
    const response = await api.delete(`/common-comments/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(response.data.status);
    return response.data.status;
  }
);

export const searchCompany = createAsyncThunk(
  "company/searchCom",
  async (text) => {
    const response = await api.get(
      `/companies/search?search-text=${text}&pageIndex=1&pageSize=10`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    ;
    return response.data.data;
  }
);

export const searchCompanyCount = createAsyncThunk(
  "company/searchComCount",
  async (text) => {
    const response = await api.get(
      `/companies/search/count?search-text=${text}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    ;
    return response.data;
  }
);

export const adminSlice = createSlice({
  name: "company",
  initialState: {
    dataStatus: 0,
    countCom: 0,
    toiletDistrictData: [],
    dataCom: [],
    dataCombo: [],
    dataReportByComId: [],
    dataCheckin: [],
    dataCheckinCount: 0,
    dataComById: {},
    dataRecommend: [],
    status: "idle",
    sensitiveWord: [],
    dataService: [],
    dataPostService: {},
    dataUpdateTurnPrice: {},

    dataAllToilet: [],
    dataAllToiletCount: 0,

    sensitiveWordById: {},
    sensitiveWordCount: 0,
    toiletType: [],
    facilityType: [],
    dataFacilityById: {},
    dataUpdateFacility: {},
    commonComment: [],
    delCommonComment: {},
    newSenWord: {},
    delSenWord: {},
    updateSenWord: {},

    newPackagePrice: {},
    dataPackagePriceById: {},
    delPackagePrice: {},
    updatePackagePrice: {},

    updateStatusCom: {},
    newToiletType: {},
    delToiletType: {},
    newFacilityType: {},
    delFacilityType: {},
    newCommonComment: {},
    updateComment: {},
    searchData: [],
    searchCount: 0,
  },
  reducers: {
    resetCom: (state) => {
      state.dataStatus = 0;
    },
  },
  extraReducers: (builder) => {
    // --- Xử lý trong reducer với case fulfilled ---
    builder.addCase(getToiletByDistrict.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.toiletDistrictData = action.payload;
    });

    builder.addCase(getAllToiletByComId.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataAllToilet = action.payload;
    });

    builder.addCase(getAllToiletByComIdCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataAllToiletCount = action.payload;
    });

    builder.addCase(getToiletReportByCompanyId.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataReportByComId = action.payload;
    });

    builder.addCase(getCompany.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataCom = action.payload;
    });

    builder.addCase(getFullPackagePrice.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(getFullPackagePrice.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataCombo = action.payload;
    });

    builder.addCase(getPackagePriceById.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(getPackagePriceById.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataPackagePriceById = action.payload;
    });

    builder.addCase(postPackagePrice.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(postPackagePrice.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.newPackagePrice = action.payload;
    });

    builder.addCase(updateTurnPriceServiceToilet.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(updateTurnPriceServiceToilet.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataUpdateTurnPrice = action.payload;
    });

    builder.addCase(updatePackagePrice.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(updatePackagePrice.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updatePackagePrice = action.payload;
    });

    builder.addCase(deletePackagePrice.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(deletePackagePrice.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.delPackagePrice = action.payload;
    });
    

    builder.addCase(getServiceToilet.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(postServiceToilet.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataPostService = action.payload;
    });

    builder.addCase(getServiceToilet.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataService = action.payload;
    });

    builder.addCase(getCompanyById.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataComById = action.payload;
    });

    builder.addCase(getCheckinCom.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataCheckin = action.payload;
    });

    builder.addCase(getCheckinComCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataCheckinCount = action.payload;
    });

    builder.addCase(postCompany.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataStatus = action.payload;
    });

    builder.addCase(updateStatusCompany.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateStatusCom = action.payload;
    });

    builder.addCase(getCompanyCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.countCom = action.payload;
    });

    builder.addCase(getRecommend.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataRecommend = action.payload;
    });

    builder.addCase(getSensitiveWord.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.sensitiveWord = action.payload;
    });

    builder.addCase(getSensitiveWordById.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.sensitiveWordById = action.payload;
    });

    builder.addCase(getSensitiveWordCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.sensitiveWordCount = action.payload;
    });

    builder.addCase(postSensitiveWord.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.newSenWord = action.payload;
    });

    builder.addCase(getToiletType.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.toiletType = action.payload;
    });

    builder.addCase(postToiletType.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.newToiletType = action.payload;
    });

    builder.addCase(updateFacilityType.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataUpdateFacility = action.payload;
    });

    builder.addCase(deleteToiletType.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.delToiletType = action.payload;
    });

    builder.addCase(getFacilityType.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.facilityType = action.payload;
    });

    builder.addCase(getFacilityTypeById.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataFacilityById = action.payload;
    });

    builder.addCase(postFacilityType.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.newFacilityType = action.payload;
    });

    builder.addCase(deleteFacilityType.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.delFacilityType = action.payload;
    });

    builder.addCase(getCommonComment.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.commonComment = action.payload;
    });

    builder.addCase(postCommonComment.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.newCommonComment = action.payload;
    });

    builder.addCase(updateCommonComment.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateComment = action.payload;
    });

    builder.addCase(deleteCommonComment.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.delCommonComment = action.payload;
    });

    builder.addCase(deleteSensitiveWord.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.delSenWord = action.payload;
    });
    builder.addCase(updateSensitiveWord.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateSenWord = action.payload;
    });
    builder.addCase(searchCompany.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.searchData = action.payload;
    });
    builder.addCase(searchCompanyCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.searchCount = action.payload;
    });
  },
});

export default adminSlice.reducer;
export const showCom = (state) => state.company.dataStatus;
export const showComStatus = (state) => state.company.status;
export const showToiletByDistrict = (state) => state.company.toiletDistrictData;
export const showDataService = (state) => state.company.dataService;
export const showUpdateTurnPriceService = (state) => state.company.dataUpdateTurnPrice;
export const showStatusPostService = (state) => state.company.dataPostService;
export const showToiletReportByComId = (state) =>
  state.company.dataReportByComId;
export const showComById = (state) => state.company.dataComById;
export const showAllCom = (state) => state.company.dataCom;
export const showCount = (state) => state.company.countCom;

export const showAllToiletByComId = (state) => state.company.dataAllToilet;
export const showAllToiletByComIdCount = (state) => state.company.dataAllToiletCount;

export const showAllCheckin = (state) => state.company.dataCheckin;
export const showAllCheckinCount = (state) => state.company.dataCheckinCount;

export const showAllPackagePrice = (state) => state.company.dataCombo;
export const showPackagePriceById = (state) => state.company.dataPackagePriceById;
export const showUpdatePackagePrice = (state) => state.company.updatePackagePrice;
export const showDelPackagePrice = (state) => state.company.delPackagePrice;
export const showNewPackagePrice = (state) => state.company.newPackagePrice;

export const showRecommendData = (state) => state.company.dataRecommend;
export const showSensitiveWord = (state) => state.company.sensitiveWord;
export const showSensitiveWordById = (state) => state.company.sensitiveWordById;
export const showSensitiveWordCount = (state) =>
  state.company.sensitiveWordCount;
export const showToiletType = (state) => state.company.toiletType;
export const showNewToiletType = (state) => state.company.newToiletType;
export const showDelToiletType = (state) => state.company.delToiletType;

export const showFacilityType = (state) => state.company.facilityType;
export const showFacilityTypeId = (state) => state.company.dataFacilityById;
export const showUpdateFacilityType = (state) => state.company.dataUpdateFacility;
export const showNewFacilityType = (state) => state.company.newFacilityType;
export const showDelFacilityType = (state) => state.company.delFacilityType;

export const showCommonComment = (state) => state.company.commonComment;
export const showNewCommonComment = (state) => state.company.newCommonComment;
export const showUpdateCommonComment = (state) => state.company.updateComment;
export const showDelCommonComment = (state) => state.company.delCommonComment;
export const showNewSenWord = (state) => state.company.newSenWord;
export const showDelSenWord = (state) => state.company.delSenWord;
export const showUpdateSenWord = (state) => state.company.updateSenWord;
export const showUpdateStatusCom = (state) => state.company.updateStatusCom;
export const showSearchData = (state) => state.company.searchData;
export const showSearchDataCount = (state) => state.company.searchCount;
export const { resetCom } = adminSlice.actions;

import { Icon, InlineIcon } from "@iconify/react";
import React from "react";
import "../ManageToilet.scss";
import { reportColumns, reportData } from "./ReportEachToiletColumn";
import { DatePicker, Result, Table } from "antd";
import { useRef } from "react";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMonthReport,
  getReport,
  showMonthReport,
  showReport,
} from "../../../../features/toiletManagerSlice";
import { useLocation } from "react-router-dom";
import SockJsClient from "react-stomp";
dayjs.locale("vi");

const SOCKET_URL = "https://toilet-map.azurewebsites.net/ws-message";

function ReportEachToilet() {
  const dispatch = useDispatch();
  const dataReport = useSelector(showReport);
  const monthReport = useSelector(showMonthReport);
  const [screenSize, getDimension] = useState(window.innerWidth);
  const { state } = useLocation();
  const tableRefReport = useRef(null);
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs().format("DD/MM/YYYY"));
  const [endDateMonth, setEndDateMonth] = useState(
    dayjs().format("DD/MM/YYYY")
  );
  const [startDateMonth, setStartDateMonth] = useState(
    dayjs().startOf("month").format("DD/MM/YYYY")
  );
  const format = "DD/MM/YYYY";
  //websocket
  const [message, setMessage] = useState("You server message here.");
  const customSubcribe = {
    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
  };
  //

  //action
  const onChange = (range) => {
    setStartDate(range[0].format(format));
    setEndDate(range[1].format(format));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRefReport.current,
    filename: "Tổng số tiền tạm thu nhà vệ sinh",
    sheet: "Revenue",
  });

  //websocket
  let onConnected = () => {
    console.log("Connected!!");
  };
  let onMessageReceived = (msg) => {
    setMessage(msg);
  };

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  useEffect(() => {
    const data = {
      id: state.id,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(getMonthReport(state.id));
    dispatch(getReport(data));
  }, [startDate, endDate, message]);

  return (
    <div className="report-each-toilet-container">
      <SockJsClient
        url={SOCKET_URL}
        ref={(client) => console.log(client)}
        debug={true}
        topics={["/topic/check-in"]}
        onConnect={onConnected}
        onDisconnect={() => console.log("Disconnected!")}
        onMessage={(msg) => onMessageReceived(msg)}
        subscribeHeaders={customSubcribe}
        headers={customSubcribe}
        autoReconnect={true}
      />
      <div className="report-each-toilet">
        <div className="report-each-toilet-number-container">
          <div className="report-each-toilet-number-header">
            <p className="report-each-toilet-count-number">
              {monthReport.totalTurn !== undefined
                ? monthReport.totalTurn.toLocaleString("en-US")
                : ""}
            </p>
            <Icon
              className="report-each-toilet-count-icon"
              icon="material-symbols:person-celebrate-rounded"
            />
          </div>
          <p className="report-each-toilet-number">
            Tổng lượt khách trong tháng
          </p>
          <p className="report-each-toilet-rate">
            {startDateMonth} - {endDateMonth}
          </p>
        </div>
        <div className="report-each-toilet-number-container">
          <div className="report-each-toilet-number-header">
            <p className="report-each-toilet-count-number">
              {monthReport.totalRevenue !== undefined
                ? monthReport.totalRevenue.toLocaleString("en-US")
                : ""}{" "}
              VND
            </p>
            <Icon
              className="report-each-toilet-count-icon"
              icon="material-symbols:person-celebrate-rounded"
            />
          </div>
          <p className="report-each-toilet-number">
            Tổng số tiền tạm thu trong tháng
          </p>
          <p className="report-each-toilet-rate">
            {startDateMonth} - {endDateMonth}
          </p>
        </div>
      </div>
      <div className="report-each-toilet-content-excel-container">
        <RangePicker
          locale={locale}
          defaultValue={[dayjs(startDate, format), dayjs(endDate, format)]}
          placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
          className="report-table-header-date"
          format="DD/MM/YYYY"
          presets={[
            {
              label: "Tuần trước",
              value: [dayjs().add(-7, "d"), dayjs()],
            },
            {
              label: "Tháng trước",
              value: [dayjs().add(-1, "month"), dayjs()],
            },
            {
              label: "Năm trước",
              value: [dayjs().add(-1, "year"), dayjs()],
            },
          ]}
          onChange={onChange}
        />

        <button
          onClick={onDownload}
          className="report-each-toilet-content-excel-btn"
        >
          Xuất file excel số tiền tạm thu
        </button>
      </div>
      {screenSize < 1024 && screenSize >= 768 ? (
        <Table
          ref={tableRefReport}
          scroll={{
            x: "100vw",
          }}
          className="table"
          bordered
          locale={locale}
          columns={reportColumns}
          dataSource={dataReport.map((item, index) => {
            return {
              stt: index + 1,
              serviceName: item.serviceName,
              totalRevenue: item.totalRevenue.toLocaleString("en-US"),
              walkInGuestRevenue:
                item.walkInGuestRevenue.toLocaleString("en-US"),
              walkInGuestCount: item.walkInGuestCount.toLocaleString("en-US"),
              usingTurnRevenue: item.usingTurnRevenue.toLocaleString("en-US"),
              usingTurnCount: item.usingTurnCount.toLocaleString("en-US"),
            };
          })}
          pagination={false}
        />
      ) : screenSize < 768 ? (
        <Table
          ref={tableRefReport}
          className="table"
          size="small"
          scroll={{
            x: "120vw",
          }}
          bordered
          locale={locale}
          columns={reportColumns}
          dataSource={dataReport.map((item, index) => {
            return {
              stt: index + 1,
              serviceName: item.serviceName,
              totalRevenue: item.totalRevenue.toLocaleString("en-US"),
              walkInGuestRevenue:
                item.walkInGuestRevenue.toLocaleString("en-US"),
              walkInGuestCount: item.walkInGuestCount.toLocaleString("en-US"),
              usingTurnRevenue: item.usingTurnRevenue.toLocaleString("en-US"),
              usingTurnCount: item.usingTurnCount.toLocaleString("en-US"),
            };
          })}
          pagination={false}
        />
      ) : (
        <Table
          ref={tableRefReport}
          className="table"
          bordered
          locale={locale}
          columns={reportColumns}
          dataSource={dataReport.map((item, index) => {
            return {
              stt: index + 1,
              serviceName: item.serviceName,
              totalRevenue: item.totalRevenue.toLocaleString("en-US"),
              walkInGuestRevenue:
                item.walkInGuestRevenue.toLocaleString("en-US"),
              walkInGuestCount: item.walkInGuestCount.toLocaleString("en-US"),
              usingTurnRevenue: item.usingTurnRevenue.toLocaleString("en-US"),
              usingTurnCount: item.usingTurnCount.toLocaleString("en-US"),
            };
          })}
          pagination={false}
        />
      )}
    </div>
  );
}

export default ReportEachToilet;

import {
  Badge,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import Menu from "../../../components/menu/Menu";
import Header from "../../../components/reuse/Header";
import "./Script.scss";
import { Icon } from "@iconify/react";
import { useState } from "react";
import ScriptComponent from "../../../components/reuse/ScriptComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadingOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import {
  deleteToilet,
  getFirstScriptData,
  getFourthScriptData,
  getSecondScriptData,
  getThirdScriptData,
  showGetFirstScriptData,
  showGetFourthScriptData,
  showGetSecondScriptData,
  showGetThirdScriptData,
  showStatus,
} from "../../../features/scriptSlice";
import {
  getDistrictByProvince,
  showGetDistrictData,
} from "../../../features/districtSlice";
import {
  getToiletByDistrict,
  showToiletByDistrict,
} from "../../../features/adminSlice";
import { TITLE9 } from "../../../Constant";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";

function Script() {
  const [valueSelected, setValueSelected] = useState([]);
  // const [quantity, setQuantity] = useState([]);
  const [districtSelected, setDistrictSelected] = useState([]);
  const [toiletSelected, setToiletSelected] = useState([]);
  const [toiletId, setToiletId] = useState();
  const dataFirstScript = useSelector(showGetFirstScriptData);
  const dataGetDistrict = useSelector(showGetDistrictData);
  const status = useSelector(showStatus);
  const dispatch = useDispatch();
  const optionDistricts = [
    "Quận 1",
    "Quận 2",
    "Quận 3",
    "Quận 4",
    "Quận 5",
    "Quận 6",
    "Quận 7",
    "Quận 8",
    "Quận 9",
    "Quận 10",
    "Quận 11",
    "Quận 12",
    "Bình Chánh",
    "Bình Tân",
    "Bình Thạnh",
    "Cần Giờ",
    "Củ Chi",
    "Gò Vấp",
    "Hóc Môn",
    "Nhà Bè",
    "Phú Nhuận",
    "Tân Bình",
    "Tân Phú",
    "Thủ Đức",
  ];
  const [dataScriptFullToilet, setDataScriptFullToilet] = useState({});
  const [numberOfBathRoom, setNumberOfBathRoom] = useState();
  const [numberOfRestRoom, setNumberOfRestRoom] = useState();

  const [numberOfTurn, setNumberOfTurn] = useState();
  const [numberOfGuest, setNumberOfGuest] = useState();

  const dataToilet = useSelector(showToiletByDistrict);
  const dataFullToilet = useSelector(showGetSecondScriptData);
  const dataEmptyToilet = useSelector(showGetThirdScriptData);
  const dataDateToilet = useSelector(showGetFourthScriptData);
  const [screenSize, getDimension] = useState(window.innerWidth);

  const [toiletListData, setToiletListData] = useState([]);
  const [dateScript, setDateScript] = useState();

  //handle document title
  useEffect(() => {
    document.title = TITLE9;
  }, []);

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //change date
  const onChangeDate = (date, dateString) => {
    console.log(dateString);
    setDateScript(dateString);
  };

  //Choose script
  const options = [
    {
      value: "1",
      label: "Checkin nhà vệ sinh",
    },
    {
      value: "2",
      label: "Checkin nhiều người trong 1 nhà vệ sinh",
    },
    {
      value: "3",
      label: "Checkout nhiều người trong 1 nhà vệ sinh",
    },
    {
      value: "4",
      label:
        "Cập nhật dữ liệu hiển thị gợi ý cho các nhà vệ sinh trong hệ thống",
    },
  ];

  //change select box to other value
  const onChange = (value) => {
    dispatch(deleteToilet());
    setValueSelected(value);

    // if (value === "2") {
    //   setNumberOfBathRoom();
    //   setNumberOfRestRoom();
    // }

    if (value === "1") {
      setToiletId();
      setDistrictSelected([]);
      setToiletSelected([]);
    }
  };

  //change select box to other district value (fix done)
  const onChangeDistrict = (value) => {
    setDistrictSelected(value);
    setToiletSelected([]);
    dispatch(getToiletByDistrict(value));
  };

  //change select box to other district value
  const onChangeToilet = (value, options) => {
    dispatch(deleteToilet());
    setToiletSelected([]);
    setNumberOfBathRoom();
    setNumberOfRestRoom();

    setToiletSelected(options.label);
    setToiletId(value);
    setNumberOfBathRoom(options.numberOfBathRoom);
    setNumberOfRestRoom(options.numberOfRestRoom);
    setDataScriptFullToilet({
      toiletId: value,
      numberOfBathRoom: options.numberOfBathRoom,
      numberOfRestRoom: options.numberOfRestRoom,
    });

    if (valueSelected === "1") {
      setToiletListData([
        ...toiletListData,
        {
          toiletName: options.label,
          toiletId: value,
        },
      ]);
    }
  };

  //handle button with selected items
  const handleCallData = () => {
    if (valueSelected === "1") {
      const data = {
        numberOfUser: numberOfTurn,
        numberOfGuest: numberOfGuest,
        listToiletId: toiletListData.map((item) => {
          return item.toiletId;
        }),
      };
      dispatch(getFirstScriptData(data));
    }
    if (valueSelected === "2") {
      dispatch(
        getSecondScriptData({
          toiletId: toiletId,
          numberOfBathRoom: numberOfBathRoom,
          numberOfRestRoom: numberOfRestRoom,
        })
      );
    }
    if (valueSelected === "3") {
      dispatch(getThirdScriptData(toiletId));
    }

    if (valueSelected === "4") {
      const data = {
        date: dateScript,
      };
      dispatch(getFourthScriptData(data));
    }
  };

  // //handle delete tag
  const handleClose = (removedTag) => {
    const newToiletSelected = toiletListData.filter(
      (item) => item !== removedTag
    );
    console.log(newToiletSelected);
    setToiletListData(newToiletSelected);
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        color="blue"
        style={{ marginBottom: "8px" }}
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag.toiletName}
      </Tag>
    );
    return <span key={tag.toiletId}>{tagElem}</span>;
  };
  const tagChild = toiletListData.map(forMap);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleEmulator="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="manage-toilet-unit-container">
            <Header
              header="header-line"
              title="Giả lập hệ thống"
              display="none"
              displaySearch="none"
            />
            <div className="script-select-container">
              {screenSize <= 1024 ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Row gutter={[8, 8]} style={{ marginTop: "8px" }}>
                    <Col span={24}>
                      <Select
                        onChange={onChange}
                        placeholder="Chọn loại giả lập"
                        value={valueSelected}
                        allowClear
                        options={options}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Col>
                    {valueSelected !== "4" ? (
                      <>
                        <Col span={8}>
                          <Select
                            onChange={onChangeDistrict}
                            placeholder="Chọn quận"
                            value={districtSelected}
                            allowClear
                            options={optionDistricts.map((item) => {
                              return {
                                value: item,
                                label: item,
                              };
                            })}
                            style={{ width: "100%" }}
                          />
                        </Col>
                        <Col span={16}>
                          <Select
                            onChange={onChangeToilet}
                            placeholder="Chọn nhà vệ sinh"
                            value={toiletSelected}
                            allowClear
                            onClear={() => [
                              dispatch(deleteToilet()),
                              setToiletSelected([]),
                              setNumberOfBathRoom(),
                              setNumberOfRestRoom(),
                            ]}
                            options={dataToilet.map((item) => {
                              return {
                                value: item.id,
                                label: item.name,
                                numberOfBathRoom: item.numberOfBathRoom,
                                numberOfRestRoom: item.numberOfRestRoom,
                              };
                            })}
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={24}>
                          <DatePicker
                            onChange={onChangeDate}
                            locale={locale}
                            format="DD-MM-YYYY"
                          />
                        </Col>
                      </>
                    )}

                    {valueSelected === "1" ? (
                      <Col span={24}>
                        <div className="script-input-guest-number-container">
                          <h4>Nhà vệ sinh</h4>
                          <div>
                            {toiletListData.length !== 0 ? (
                              <div>{tagChild}</div>
                            ) : (
                              <Tag color="red" style={{ marginBottom: "12px" }}>
                                Chưa có
                              </Tag>
                            )}
                          </div>
                          <h4>Nhập số lượt khách</h4>
                          <div className="script-input-number">
                            <p>Khách sử dụng lượt</p>
                            <Input
                              value={numberOfTurn}
                              onChange={(e) =>
                                setNumberOfTurn(Number(e.target.value))
                              }
                              placeholder="0-100"
                              style={{ maxWidth: "80px", textAlign: "right" }}
                            />
                          </div>
                          <div className="script-input-number">
                            <p>Khách vãng lai</p>
                            <Input
                              value={numberOfGuest}
                              onChange={(e) =>
                                setNumberOfGuest(Number(e.target.value))
                              }
                              placeholder="0-100"
                              style={{ maxWidth: "80px", textAlign: "right" }}
                            />
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {valueSelected === "2" ? (
                      <Col span={24}>
                        <div className="script-input-number-container">
                          <h4>Nhập số lượng người (nếu muốn)</h4>
                          <div className="script-input-number">
                            <p>Số lượng người đi vệ sinh</p>
                            <Input
                              value={numberOfRestRoom}
                              onChange={(e) =>
                                setNumberOfRestRoom(Number(e.target.value))
                              }
                              placeholder="0-60"
                              style={{ maxWidth: "80px", textAlign: "right" }}
                            />
                          </div>
                          <div className="script-input-number">
                            <p>Số lượng người đi tắm</p>
                            <Input
                              value={numberOfBathRoom}
                              onChange={(e) =>
                                setNumberOfBathRoom(Number(e.target.value))
                              }
                              placeholder="0-60"
                              style={{ maxWidth: "80px", textAlign: "right" }}
                            />
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  {status === "loading" ? (
                    <Button className="script-button" disabled>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: "16px", color: "#00000062" }}
                            spin
                          />
                        }
                      />
                      <span>Chạy giả lập</span>
                    </Button>
                  ) : (
                    <Button
                      className="script-button"
                      type="primary"
                      onClick={() => handleCallData()}
                    >
                      <Icon icon="ph:play-fill" />
                      <span>Chạy giả lập</span>
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <Row gutter={[8, 8]} justify="space-between">
                    <Col span={12}>
                      <Select
                        onChange={onChange}
                        placeholder="Chọn loại giả lập"
                        allowClear
                        options={options}
                        value={valueSelected}
                        className="script-select"
                      />
                    </Col>
                    {valueSelected !== "4" ? (
                      <>
                        <Col span={4}>
                          <Select
                            onChange={onChangeDistrict}
                            placeholder="Chọn quận"
                            value={districtSelected}
                            allowClear
                            options={optionDistricts.map((item) => {
                              return {
                                value: item,
                                label: item,
                              };
                            })}
                            style={{ width: "100%" }}
                          />
                        </Col>
                        <Col span={8}>
                          <Select
                            onChange={onChangeToilet}
                            placeholder="Chọn nhà vệ sinh"
                            value={toiletSelected}
                            allowClear
                            onClear={() => [
                              dispatch(deleteToilet()),
                              setToiletSelected([]),
                              setNumberOfBathRoom(),
                              setNumberOfRestRoom(),
                            ]}
                            options={dataToilet.map((item) => {
                              return {
                                value: item.id,
                                label: item.name,
                                numberOfBathRoom: item.numberOfBathRoom,
                                numberOfRestRoom: item.numberOfRestRoom,
                              };
                            })}
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={12}>
                          <DatePicker
                            onChange={onChangeDate}
                            locale={locale}
                            format="DD-MM-YYYY"
                          />
                        </Col>
                      </>
                    )}

                    {valueSelected === "1" ? (
                      <Col span={10}>
                        <div className="script-input-guest-number-container">
                          <h4>Nhà vệ sinh</h4>
                          <div>
                            {toiletListData.length !== 0 ? (
                              <div>{tagChild}</div>
                            ) : (
                              <Tag color="red" style={{ marginBottom: "12px" }}>
                                Chưa có
                              </Tag>
                            )}
                          </div>
                          <h4>Nhập số lượt khách</h4>

                          <div className="script-input-number">
                            <p>Khách sử dụng lượt</p>
                            <Input
                              value={numberOfTurn}
                              onChange={(e) =>
                                setNumberOfTurn(Number(e.target.value))
                              }
                              placeholder="0-100"
                              style={{ maxWidth: "80px", textAlign: "right" }}
                            />
                          </div>
                          <div className="script-input-number">
                            <p>Khách vãng lai</p>
                            <Input
                              value={numberOfGuest}
                              onChange={(e) =>
                                setNumberOfGuest(Number(e.target.value))
                              }
                              placeholder="0-100"
                              style={{ maxWidth: "80px", textAlign: "right" }}
                            />
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {valueSelected === "2" ? (
                      <Col span={10}>
                        <div className="script-input-number-container">
                          <h4>Nhập số lượng người (nếu muốn)</h4>
                          <div className="script-input-number">
                            <p>Số lượng người đi vệ sinh</p>
                            <Input
                              value={numberOfRestRoom}
                              onChange={(e) =>
                                setNumberOfRestRoom(Number(e.target.value))
                              }
                              placeholder="0-60"
                              style={{ maxWidth: "80px", textAlign: "right" }}
                            />
                          </div>
                          <div className="script-input-number">
                            <p>Số lượng người đi tắm</p>
                            <Input
                              value={numberOfBathRoom}
                              onChange={(e) =>
                                setNumberOfBathRoom(Number(e.target.value))
                              }
                              placeholder="0-60"
                              style={{ maxWidth: "80px", textAlign: "right" }}
                            />
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  {status === "loading" ? (
                    <Button className="script-button" disabled>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: "16px", color: "#00000062" }}
                            spin
                          />
                        }
                      />
                      <span>Chạy giả lập</span>
                    </Button>
                  ) : (
                    <Button
                      className="script-button"
                      type="primary"
                      onClick={() => handleCallData()}
                    >
                      <Icon icon="ph:play-fill" />
                      <span>Chạy giả lập</span>
                    </Button>
                  )}
                </div>
              )}
            </div>
            {valueSelected === "1" ? (
              <ScriptComponent
                title={`1. Check in ${
                  numberOfTurn !== undefined ? numberOfTurn : "n"
                } khách sử dụng lượt và ${
                  numberOfGuest !== undefined ? numberOfGuest : "n"
                } khách vãng lai đi ${
                  toiletListData.length
                } nhà vệ sinh của các công ty khác nhau trong hệ thống.`}
                data={
                  dataFirstScript.length !== 0
                    ? dataFirstScript.listUserCheckIn
                    : dataFirstScript
                }
                displayDes="none"
                dataStatus={
                  dataFirstScript.length !== 0 ? (
                    <div>
                      {dataFirstScript.length !== 0 ? (
                        <p
                          style={{
                            borderBottom: "1px solid #000",
                            marginBottom: "8px",
                            paddingBottom: "8px",
                          }}
                        >
                          <CheckCircleFilled style={{ color: "green" }} /> Check
                          in thành công cho{" "}
                          <strong>{numberOfTurn} khách sử dụng lượt</strong> và{" "}
                          <strong>{numberOfGuest} khách vãng lai</strong>.
                        </p>
                      ) : (
                        ""
                      )}
                      {dataFirstScript.length !== 0
                        ? dataFirstScript.listTotal.map((item) => {
                            return (
                              <div>
                                <p>
                                  <CheckCircleFilled
                                    style={{ color: "green" }}
                                  />{" "}
                                  Check in thành công cho{" "}
                                  <strong>
                                    {item.numberOfUser} khách sử dụng lượt
                                  </strong>{" "}
                                  và{" "}
                                  <strong>
                                    {item.numberOfGuest} khách vãng lai
                                  </strong>{" "}
                                  tại {item.toiletName} gồm:
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p>* Đi vệ sinh (tiểu tiện)</p>
                                  <p>
                                    <strong>{item.pee}</strong>
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p>* Đi vệ sinh (Đại tiện)</p>
                                  <p>
                                    <strong>{item.poop}</strong>
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p>* Đi tắm</p>
                                  <p>
                                    <strong>{item.bath}</strong>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  ) : (
                    ""
                  )
                }
              />
            ) : (
              ""
            )}

            {valueSelected === "2" ? (
              <ScriptComponent
                text={
                  toiletSelected.length !== 0 ? (
                    JSON.stringify(dataFullToilet) !== "{}" ? (
                      <>
                        <Badge
                          status={"processing"}
                          text={`Phòng vệ sinh còn trống: ${dataFullToilet.data.total.toiletRoomEmpty} phòng`}
                          style={{ fontWeight: 500, marginTop: "8px" }}
                        />
                        <br />
                        <Badge
                          status={"processing"}
                          text={`Phòng tắm còn trống: ${dataFullToilet.data.total.bathRoomEmpty} phòng`}
                          style={{ fontWeight: 500 }}
                        />
                      </>
                    ) : (
                      <>
                        <Badge
                          status={"processing"}
                          text={`Phòng vệ sinh: ${dataScriptFullToilet.numberOfRestRoom} phòng`}
                          style={{ fontWeight: 500, marginTop: "8px" }}
                        />
                        <br />
                        <Badge
                          status={"processing"}
                          text={`Phòng tắm: ${dataScriptFullToilet.numberOfBathRoom} phòng`}
                          style={{ fontWeight: 500 }}
                        />
                      </>
                    )
                  ) : (
                    ""
                  )
                }
                title={`2. Check in ${
                  numberOfRestRoom !== undefined ? numberOfRestRoom : "n"
                } người đi vệ sinh và ${
                  numberOfBathRoom !== undefined ? numberOfBathRoom : "n"
                } người đi tắm tại ${
                  toiletSelected.length !== 0
                    ? toiletSelected
                    : "nhà vệ sinh cụ thể."
                }`}
                dataStatus={
                  dataFullToilet !== {} ? (
                    dataFullToilet.status === 200 ? (
                      <div>
                        <p>
                          <CheckCircleFilled style={{ color: "green" }} /> Check
                          in thành công:{" "}
                          {dataFullToilet.data.total.checkInSuccess} người
                        </p>
                        <p>
                          <CloseCircleFilled style={{ color: "red" }} /> Check
                          in thất bại: {dataFullToilet.data.total.checkInFail}{" "}
                          người
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                }
                data={
                  JSON.stringify(dataFullToilet) !== "{}"
                    ? dataFullToilet.data.listUserCheckIn
                    : ""
                }
              />
            ) : (
              ""
            )}

            {valueSelected === "3" ? (
              <ScriptComponent
                title={`3. Check out tất cả khách hàng tại ${
                  toiletSelected.length !== 0
                    ? toiletSelected
                    : "nhà vệ sinh cụ thể."
                }`}
                text={
                  toiletSelected.length !== 0 ? (
                    JSON.stringify(dataFullToilet) === "{}" ? (
                      <>
                        <Badge
                          status={"processing"}
                          text={`Phòng vệ sinh: ${dataScriptFullToilet.numberOfRestRoom} phòng`}
                          style={{ fontWeight: 500, marginTop: "8px" }}
                        />
                        <br />
                        <Badge
                          status={"processing"}
                          text={`Phòng tắm: ${dataScriptFullToilet.numberOfBathRoom} phòng`}
                          style={{ fontWeight: 500 }}
                        />
                      </>
                    ) : (
                      <>
                        <Badge
                          status={"processing"}
                          text={`Phòng vệ sinh: ${dataScriptFullToilet.numberOfRestRoom} phòng`}
                          style={{ fontWeight: 500, marginTop: "8px" }}
                        />
                        <br />
                        <Badge
                          status={"processing"}
                          text={`Phòng tắm: ${dataScriptFullToilet.numberOfBathRoom} phòng`}
                          style={{ fontWeight: 500 }}
                        />
                      </>
                    )
                  ) : (
                    ""
                  )
                }
                dataStatus={
                  JSON.stringify(dataEmptyToilet) !== "{}" &&
                  toiletSelected.length !== 0 ? (
                    dataEmptyToilet.status === 200 ? (
                      <>
                        <p>
                          <CheckCircleFilled style={{ color: "green" }} />{" "}
                          Checkout thành công:{" "}
                          <strong>{dataEmptyToilet.data.numberOfUserPoop} người đi vệ
                          sinh</strong>
                        </p>
                        <p>
                          <CheckCircleFilled style={{ color: "green" }} />{" "}
                          Checkout thành công:{" "}
                          <strong>{dataEmptyToilet.data.numberOfUserBath} người đi tắm</strong>
                        </p>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                }
                data={
                  JSON.stringify(dataEmptyToilet) !== "{}"
                    ? dataEmptyToilet.data.listUserCheckout
                    : ""
                }
              />
            ) : (
              ""
            )}

            {valueSelected === "4" ? (
              <ScriptComponent
                title="4. Cập nhật dữ liệu hiển thị gợi ý cho các nhà vệ sinh trong hệ thống."
                text=""
                resultStatisticDisplay="none"
                data={
                  JSON.stringify(dataDateToilet) !== "{}"
                    ? [`Cập nhật dữ liệu thành công đến ngày ${dateScript}`]
                    : ""
                }
              />
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Script;

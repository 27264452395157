import { Col, Flex, Result, Row, Table } from 'antd'
import './UserHistory.scss'
import Menu from '../../../components/menu/Menu'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  UserHistoryColumn,
  UserPaymentHistoryColumn
} from './UserHistoryColumn'
import { useDispatch, useSelector } from 'react-redux'
import Search from 'antd/es/input/Search'
import {
  getUserCheckinHistory,
  showUserCheckinHistory,
  userCheckinHistoryStatus
} from '../../../features/userCheckinHistory'

dayjs.locale('vi')
export const UserHistory = () => {
  const dispatch = useDispatch()

  const status = useSelector(userCheckinHistoryStatus)
  const data = useSelector(showUserCheckinHistory)

  const [userName, setUserName] = useState('0902741611')
  const [screenSize, getDimension] = useState(window.innerWidth)
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format('DD/MM/YYYY')
  )
  const [endDate, setEndDate] = useState(dayjs().format('DD/MM/YYYY'))

  const locale = {
    emptyText: (
      <Result
        title='Chưa có dữ liệu'
        subTitle='Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này'
      />
    )
  }

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth)
  }

  const handleSearch = () => {
    const data = {
      id: localStorage.getItem('comId'),
      userName,
      startDate: startDate,
      endDate: endDate
    }
    dispatch(getUserCheckinHistory(data))
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [screenSize])
  return (
    <div className='container statistic-container'>
      <Row>
        <Col className='statistic-left-container' span={4}>
          <Menu styleUserHistory='menu-content-link-active' />
        </Col>
        <Col className='manage-toilet-container' span={19}>
          <Flex
            className='header-no-line'
            align='center'
            justify='space-between'
            color='#3498db'
          >
            <p>Tra cứu thông tin khách hàng</p>
            <Row>
              <p>Số lượt còn: { data.numberOfTurn }</p>
            </Row>
            <Search
              className='header-search user-history-search-bar'
              enterButton
              placeholder='Tìm theo số ĐT'
              value={userName}
              onChange={e => setUserName(e.target.value)}
              onPressEnter={handleSearch}
              onSearch={handleSearch}
              style={{ width: 170 }}
            />
          </Flex>
          <Row gutter={16}>
            <Col span={19}>
              <Table
                size='small'
                locale={locale}
                className='table'
                loading={status === 'loading' ? true : false}
                bordered
                columns={UserHistoryColumn}
                dataSource={data.histories}
                pagination={false}
                // pagination={{
                //   current: current,
                //   onChange: onChangeNavigate,
                //   pageSize: 8,
                //   showTotal: showTotal,
                //   total: statisticCount,
                //   position: ["bottomCenter"],
                // }}

              />
            </Col>
            <Col span={5}>
              <Table
                size='small'
                locale={locale}
                className='table'
                loading={status === 'loading' ? true : false}
                bordered
                columns={UserPaymentHistoryColumn}
                dataSource={data.paymentDates}
                pagination={false}
                // pagination={{
                //   current: current,
                //   onChange: onChangeNavigate,
                //   pageSize: 8,
                //   showTotal: showTotal,
                //   total: statisticCount,
                //   position: ["bottomCenter"],
                // }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

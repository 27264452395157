import { Alert, Button, Col, Input, Row, Steps, message } from "antd";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import bcrypt from "bcryptjs-react";
import Menu from "../../../../components/menu/Menu";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";

function CreateNewAccount() {
  const [username, setUsername] = useState(
    JSON.parse(localStorage.getItem("accountData")) !== null
      ? JSON.parse(localStorage.getItem("accountData")).username
      : ""
  );
  const [password, setPassword] = useState("");
  const [err, setErr] = useState(false);
  const [errUsername, setErrUsername] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [screenSize, getDimension] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { state } = useLocation();

  const createAccount = () => {
    if (password === passwordConfirm) {
      setErr(false);
      if (username !== "") {
        var hashPassword = bcrypt.hashSync(password, 10);
        navigate("/createapprove");
        localStorage.setItem(
          "accountData",
          JSON.stringify({
            username: username,
            password: hashPassword,
          })
        );
      } else {
        setErrUsername(true);
      }
    } else {
      setErr(true);
    }
  };

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="create-new-toilet-box-container">
            <Breadcrumbs
              title="Quản lý nhà vệ sinh"
              pageName="Tạo nhà vệ sinh mới"
              onRemove={() => navigate(-1)}
            />
            <div className="create-new-toilet-step-container">
              {screenSize > 1440 ? (
                <Steps
                  current={2}
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : screenSize <= 1440 && screenSize > 1024 ? (
                <Steps
                  current={2}
                  size="small"
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : (
                <Steps
                  current={2}
                  size="small"
                  direction="vertical"
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              )}
              <div className="create-new-account-input-box-container">
                <div className="create-new-account-input-container">
                  <p>
                    Tài khoản<span style={{ color: "red" }}> *</span>
                  </p>
                  {errUsername ? (
                    <Input
                      status="error"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="create-new-account-input"
                      placeholder="Nhập tài khoản"
                      autoComplete="new-password"
                    />
                  ) : (
                    <Input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="create-new-account-input"
                      placeholder="Nhập tài khoản"
                      autoComplete="new-password"
                    />
                  )}
                </div>
                {errUsername ? (
                  <div className="create-new-account-input-container">
                    <p></p>
                    <p
                      style={{ fontSize: "12px", color: "red" }}
                      className="create-new-account-input"
                    >
                      Tài khoản không được bỏ trống
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <div className="create-new-account-input-container">
                  <p>
                    Mật khẩu<span style={{ color: "red" }}> *</span>
                  </p>
                  {err ? (
                    <Input.Password
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Nhập mật khẩu"
                      status="error"
                      className="create-new-account-input"
                      type="password"
                      autoComplete="new-password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  ) : (
                    <Input.Password
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Nhập mật khẩu"
                      className="create-new-account-input"
                      type="password"
                      autoComplete="new-password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  )}
                </div>
                <div className="create-new-account-input-container">
                  <p>
                    Xác nhận mật khẩu<span style={{ color: "red" }}> *</span>
                  </p>
                  {err ? (
                    <Input.Password
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      value={passwordConfirm}
                      placeholder="Xác nhận mật khẩu"
                      className="create-new-account-input"
                      type="password"
                      status="error"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  ) : (
                    <Input.Password
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      value={passwordConfirm}
                      placeholder="Xác nhận mật khẩu"
                      className="create-new-account-input"
                      type="password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  )}
                </div>
                {err ? (
                  <div className="create-new-account-input-container">
                    <p></p>
                    <p
                      style={{ fontSize: "12px", color: "red" }}
                      className="create-new-account-input"
                    >
                      Các mật khẩu đã nhập không khớp. Hãy thử lại.
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="create-new-toilet-footer-container">
                <Button
                  type="default"
                  style={{ marginRight: "1%" }}
                  onClick={() => navigate(-1)}
                >
                  Trở về
                </Button>
                <Button type="primary" onClick={() => createAccount()}>
                  Tiếp theo
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateNewAccount;

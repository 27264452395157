import { Badge, Col, Descriptions, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";

function ScriptComponent(props) {
  const bottomRef = useRef(null);
  const [data, setData] = useState([]);
  const [dataStatistic, setDataStatistic] = useState("");
  const [screenSize, getDimension] = useState(window.innerWidth);

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    setTimeout(() => setData(props.data), 1000);
    setTimeout(() => setDataStatistic(props.dataStatus), 1500);
  }, [props.data, props.dataStatus]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    if (data.length !== 0) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [data]);

  return (
    <div>
      {screenSize <= 1024 ? (
        <Row
          className="script-input-output-container-medium-screen"
          justify={"space-between"}
        >
          <Col span={23} className="script-input-container">
            <p>{props.title}</p>
            <p>{props.text}</p>
          </Col>
          <Col
            span={23}
            className="script-output-container"
            style={{ marginTop: "16px" }}
          >
            <p className="script-result-header">Kết quả</p>
            <div className="script-result">
              {data.length !== 0
                ? data.map((item, index) => {
                    return (
                      <p key={index}>
                        {index + 1}. {item}
                      </p>
                    );
                  })
                : ""}
              {/* <div ref={bottomRef} /> */}
              <div
                className="script-output-result-container"
                style={{ display: props.resultStatisticDisplay }}
              >
                {data.length !== 0 ? (
                  <div
                    style={{
                      paddingBottom: "16px",
                      paddingTop: "12px",
                    }}
                  > <h3>Thống kê kết quả:</h3>
                    {dataStatistic}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div ref={bottomRef} />
            </div>
          </Col>
        </Row>
      ) : (
        <Row
          className="script-input-output-container"
          justify={"space-between"}
        >
          <Col span={6} className="script-input-container">
            <p>{props.title}</p>
            <p>{props.text}</p>
            <div
              className="script-output-result-container"
              style={{ display: props.resultStatisticDisplay }}
            >
              <p className="script-output-result-header">Thống kê kết quả</p>
              <div className="script-output-result-content">
                {dataStatistic}
              </div>
            </div>
          </Col>
          <Col span={17} className="script-output-container">
            <p className="script-result-header">Kết quả</p>
            <div className="script-result">
              {data.length !== 0
                ? data.map((item, index) => {
                    return (
                      <p key={index}>
                        {index + 1}. {item}
                      </p>
                    );
                  })
                : ""}

              <div ref={bottomRef} />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ScriptComponent;

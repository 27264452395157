import { Button, Modal } from "antd";
import React from "react";
import { CloseCircleOutlined } from '@ant-design/icons';
import "./ModalReuse.scss";

function ModalReuse(props) {
  return (
    <div>
      <Modal
        open={props.open}
        title={props.title}
        onOk={props.handleOk}
        closeIcon={<CloseCircleOutlined className="modal-close"/>}
        width={props.width}
        onCancel={props.handleCancel}
        footer={[
          <Button key="back" onClick={props.handleCancel}>
            Hủy
          </Button>,
          <Button key="submit" type="primary" onClick={props.handleOk}>
            {props.titleOk}
          </Button>,
        ]}
      >
        {props.children}
      </Modal>
    </div>
  );
}

export default ModalReuse;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../service/configApi.js";

export const getInforByPhone = createAsyncThunk(
  "phoneInfor/getInfor",
  async (phone) => {
    const response = await API.get(
      `/accounts/user-infos?account-username=${phone}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getCombo = createAsyncThunk(
  "phoneInfor/getAllCombo",
  async () => {
    const response = await API.get(
      `/combos`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const postComboByPhone = createAsyncThunk(
  "phoneInfor/postCombo",
  async (data) => {
    const response = await API.post(
      `/orders`, data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const postInforByPhone = createAsyncThunk(
  "phoneInfor/postInfor",
  async (data) => {
    const response = await API.post(
      `/payments`, data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const rechargeSlice = createSlice({
  name: "phoneInfor",
  initialState: {
    data: {},
    dataPostInfor: {},
    dataCombo: [],
    dataPostCombo: [],
    status: "idle",
  },
  reducers: {
    deletePhone: (state) => {
      state.status = "idle";
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    // --- Xử lý trong reducer với case fulfilled ---
    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getInforByPhone.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(postInforByPhone.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataPostInfor = action.payload;
      })

      .addCase(getCombo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataCombo = action.payload;
      })

      .addCase(postComboByPhone.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataPostCombo = action.payload;
      })

  },
});

export default rechargeSlice.reducer;
export const { deletePhone } = rechargeSlice.actions;
export const showInforByPhone = (state) => state.phoneInfor.data;
export const showPostInforByPhone = (state) => state.phoneInfor.dataPostInfor;
export const showStatusInfor = (state) => state.phoneInfor.status;
export const showGetCombo = (state) => state.phoneInfor.dataCombo;
export const showPostComboByPhone = (state) => state.phoneInfor.dataPostCombo;

import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import CreateUpdateServicePrice from "./CreateUpdateServicePrice";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useEffect } from "react";
import ModalReuse from "../../../components/reuse/ModalReuse";
import {
  deleteToiletType,
  getToiletType,
  postToiletType,
  showDelToiletType,
  showNewToiletType,
  showToiletType,
} from "../../../features/adminSlice";
import { useDispatch, useSelector } from "react-redux";

function ConfigToiletType() {
  const dispatch = useDispatch();
  const toiletTypeData = useSelector(showToiletType);
  const newtoiletType = useSelector(showNewToiletType);
  const delToiletType = useSelector(showDelToiletType);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [current, setCurrent] = useState(1);

  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //get Toilet type
  useEffect(() => {
    dispatch(getToiletType("Phòng"));
  }, [newtoiletType, delToiletType]);

  // const handleClose = (removedTag, name) => {
  //   swal({
  //     title: "Xác nhận",
  //     text: `Xác nhận xóa "${name}"`,
  //     icon: "warning",
  //     buttons: ["Hủy", "Xác nhận"],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       dispatch(deleteToiletType(removedTag));
  //     } else {
  //     }
  //   });
  // };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "16%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Loại phòng",
      dataIndex: "name",
      ellipsis: true,
    },
  ];

  //handle modal change status
  const showModalChangeStatus = () => {
    setOpenChangeStatus(true);
  };

  const handleOkChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  const handleCancelChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  return (
    <div className="config-header">
      <p>Phòng vệ sinh</p>
      <div className="config-container" style={{ marginTop: '4%', minHeight: '290px' }}>
        <div className="config-add-new" style={{ marginTop: '8%' }}>
        <p className="config-infor-update">* Loại phòng vệ sinh sẽ được cố định trong hệ thống</p>
          <div className="config-add-new-button-container">
          </div>
        </div>
        <Table
          bordered
          className="table"
          size="small"
          pagination={{
            showTotal: showTotal,
            position: ["bottomCenter"],
          }}
          columns={columns}
          dataSource={toiletTypeData !== undefined ? toiletTypeData.map((item, index) => {
            return {
              id: item.id,
              name: item.name,
              stt:
                current === 1 ? index + 1 : (current - 1) * 8 + (index + 1),
            };
          })
        : ""}
        />
        {/* Modal change status*/}
        <ModalReuse
          open={openChangeStatus}
          title="Cập nhật giá dịch vụ"
          handleOk={handleOkChangeStatus}
          handleCancel={handleCancelChangeStatus}
          titleOk="Cập nhật"
          width={800}
          children={
            <div className="config-change-date-service-container">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Phòng vệ sinh</label>
                  <Input />
                </Col>
              </Row>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ConfigToiletType;

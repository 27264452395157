import { Col, Result, Row, Table } from "antd";
import React from "react";
import Menu from "../../components/menu/Menu";
import Header from "../../components/reuse/Header";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCheckinCom,
  getCheckinComCount,
  showAllCheckin,
  showAllCheckinCount,
} from "../../features/adminSlice";
import { TITLE3 } from "../../Constant";

function ManageCheckinCompany() {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [current, setCurrent] = useState(1);
  const dispatch = useDispatch();
  const dataCheckin = useSelector(showAllCheckin);
  const dataCheckinCount = useSelector(showAllCheckinCount);

  //handle document title
  useEffect(() => {
    document.title = TITLE3;
  }, [])

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //config empty table
  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  //
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : // : `${range[0]}-${range[1]} trong ${toiletCount}`;
        `${range[0]}-${range[1]} trong ${total}`;

  //pagination
  const onChange = (page) => {
    setCurrent(page);
  };

  //table
  //table field
  const ManageCheckinColumn = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Ngày",
      dataIndex: "dateTime",
      width: "14%",
      ellipsis: true,
    },
    {
      title: "Tên nhà vệ sinh",
      dataIndex: "toiletName",
      width: "24%",
      ellipsis: true,
    },
    {
      title: "Dịch vụ",
      dataIndex: "serviceName",
      width: "16%",
      ellipsis: true,
    },
    {
      title: "Số tiền thanh toán",
      dataIndex: "balance",
      width: "20%",
      align: "right",
      ellipsis: true,
      render: (item) => {
        return item === null ? "N/A" : `${item.toLocaleString('en')} VND`;
      },
    },
    {
      title: "Số lượt thanh toán",
      dataIndex: "turn",
      align: "right",
      width: "20%",
      ellipsis: true,
      render: (item) => {
        return item === null ? "N/A" : `${item} lượt`;
      },
    },
  ];

  useEffect(() => {
    dispatch(getCheckinCom(current));
    dispatch(getCheckinComCount());
  }, [current]);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleCheckin="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="manage-toilet-container">
            <Header
              header="header-no-line"
              title="Quản lý lượt khách"
              displaySearch="none"
              display="none"
            />
            {screenSize < 1200 && screenSize > 768 ? (
              <Table
                size="small"
                locale={locale}
                scroll={{
                  x: "120vw",
                }}
                style={{ marginTop: "16px" }}
                className="table"
                // loading={status === "loading" ? true : false}
                bordered
                columns={ManageCheckinColumn}
                dataSource={dataCheckin.map((item, index) => {
                  return {
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                    dateTime: item.dateTime,
                    toiletName: item.toiletName,
                    serviceName: item.serviceName,
                    balance: item.balance,
                    turn: item.turn,
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: dataCheckinCount,
                  position: ["bottomCenter"],
                  defaultCurrent: 1,
                }}
              />
            ) : screenSize <= 768 ? (
              <Table
                size="small"
                locale={locale}
                scroll={{
                  x: "120vw",
                }}
                style={{ marginTop: "16px" }}
                className="table"
                // loading={status === "loading" ? true : false}
                bordered
                columns={ManageCheckinColumn}
                dataSource={dataCheckin.map((item, index) => {
                  return {
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                    dateTime: item.dateTime,
                    toiletName: item.toiletName,
                    serviceName: item.serviceName,
                    balance: item.balance,
                    turn: item.turn,
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: dataCheckinCount,
                  position: ["bottomCenter"],
                  defaultCurrent: 1,
                }}
              />
            ) : (
              <Table
                className="table"
                bordered
                locale={locale}
                size="small"
                style={{ marginTop: "2%" }}
                // loading={status === "loading" ? true : false}
                columns={ManageCheckinColumn}
                dataSource={dataCheckin.map((item, index) => {
                  return {
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                    dateTime: item.dateTime,
                    toiletName: item.toiletName,
                    serviceName: item.serviceName,
                    balance: item.balance,
                    turn: item.turn,
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  total: dataCheckinCount,
                  position: ["bottomCenter"],
                  defaultCurrent: 1,
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ManageCheckinCompany;

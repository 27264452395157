import { Button, Input } from "antd";
import React, { useState } from "react";
import "../ManageToilet.scss";

function AccountEachToilet(props) {
  const [changePass, setChangePass] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  return (
    <div className="account-each-toilet-container">
      {changePass ? (
        <div>
          <div className="account-each-toilet-input-container">
            <p>Tài khoản</p>
            <Input
              className="account-each-toilet-input"
              value="dvvsq1"
              disabled
            />
          </div>
          <div className="account-each-toilet-input-container">
            <p>Mật khẩu mới</p>
            <Input.Password
              placeholder="Nhập mật khẩu mới"
              className="account-each-toilet-input"
              type="password"
            />
          </div>
          <div className="account-each-toilet-input-container">
            <p>Xác nhận mật khẩu mới</p>
            <Input.Password
              placeholder="Xác nhận mật khẩu mới"
              className="account-each-toilet-input"
              type="password"
            />
          </div>
          <div className="account-each-toilet-input-btn-container">
              <Button
                onClick={() => setChangePass(false)}
                className="account-each-toilet-btn account-each-toilet-save-btn"
              >
                Trở về
              </Button>
              <Button
                onClick={() => setChangePass(false)}
                className="account-each-toilet-btn account-each-toilet-save-btn"
                type="primary"
              >
                Lưu
              </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="account-each-toilet">
            <p>Tài khoản</p>
            <p>{props.username}</p>
          </div>
          <div className="account-each-toilet">
            <p>Mật khẩu</p>
            <p>****</p>
          </div>
          <Button
            onClick={() => setChangePass(true)}
            className="account-each-toilet-btn"
            type="primary"
          >
            Đổi mật khẩu
          </Button>
        </div>
      )}
    </div>
  );
}

export default AccountEachToilet;

import { Icon } from "@iconify/react";
import { Badge, Button, Col, Popconfirm, Popover, Row } from "antd";
import { useNavigate } from "react-router-dom";
// import { ExclamationCircleFilled } from "@ant-design/icons";

const HandleContent = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <p
        onClick={() =>
          navigate(`/manage/toilet/${props.id}`, { state: { id: props.id } })
        }
        style={{ cursor: "pointer" }}
        className="statistic-toilet-popover-see-infor"
      >
        Xem thông tin
      </p>
    </div>
  );
};

export const StatisticColumn = [
  {
    title: "STT",
    dataIndex: "stt",
    key: "stt",
    width: "6%",
    ellipsis: true,
    align: "center",
    fixed: "left",
  },
  {
    title: "Tên nhà vệ sinh",
    dataIndex: "toiletName",
    key: "toiletName",
    width: "20%",
    ellipsis: true,
    fixed: "left",
  },
  {
    title: "Tổng số tiền tạm thu (VND)",
    dataIndex: "totalRevenue",
    key: "totalRevenue",
    width: "15%",
    // ellipsis: true,
    align: "right",
    render: (item) =>
      item === "0" ? (
        <p style={{ textAlign: "center", fontStyle: "italic", opacity: "80%" }}>
          Chưa có dữ liệu
        </p>
      ) : (
        <p>{item}</p>
      ),
    onCell: (item) => ({
      colSpan:
        item.totalRevenue !== "0"
          ? 1
          : item.totalPaymentCollected !== "0"
          ? 5
          : 6,
      // console.log(item.totalRevenue)
    }),
  },
  {
    title: "Khách vãng lai",
    ellipsis: true,
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "walkInGuestRevenue",
        width: "14%",
        // ellipsis: true,
        key: "walkInGuestRevenue",
        align: "right",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
      {
        title: "Số lượt khách",
        width: "12%",
        dataIndex: "walkInGuestCount",
        // ellipsis: true,
        align: "right",
        key: "walkInGuestCount",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
    ],
  },
  {
    title: "Thành viên sử dụng gói",
    ellipsis: true,
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "usingTurnRevenue",
        // ellipsis: true,
        align: "right",
        width: "14%",
        key: "usingTurnRevenue",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
      {
        title: "Số lượt khách",
        dataIndex: "usingTurnCount",
        // ellipsis: true,
        width: "12%",
        key: "usingTurnCount",
        align: "right",
        render: (item) => {
          return item !== "0" ? item : "N/A";
        },
        onCell: (item) => ({
          colSpan: item.totalRevenue !== "0" ? 1 : 0,
        }),
      },
    ],
  },
  {
    title: "Tổng thu nạp ngày (VND)",
    dataIndex: "totalPaymentCollected",
    key: "totalPaymentCollected",
    width: "15%",
    ellipsis: true,
    align: "right",
    render: (item) => (item === "0" ? "N/A" : <p>{item}</p>),
    onCell: (item) => ({
      colSpan: item.totalPaymentCollected !== "0" ? 1 : 0,
    }),
  },
  {
    title: " ",
    dataIndex: "toiletId",
    ellipsis: true,
    width: "8%",
    align: "right",
    render: (item) => {
      return (
        <div>
          <Popover
            placement="bottomRight"
            content={<HandleContent id={item} />}
            trigger="click"
          >
            <Button
              style={{
                border: "none",
                outline: "none",
                boxShadow: "none",
                height: "22px",
              }}
            >
              <Icon icon="ic:outline-more-horiz" />
            </Button>
          </Popover>
        </div>
      );
    },
  },
];

//for export excel
export const StatisticColumnExportExcel = [
  {
    title: "STT",
    dataIndex: "stt",
    key: "stt",
    width: "6%",
    ellipsis: true,
    align: "center",
  },
  {
    title: "Tên nhà vệ sinh",
    dataIndex: "toiletName",
    key: "toiletName",
    width: "20%",
  },
  {
    title: "Tổng số tiền tạm thu (VND)",
    dataIndex: "totalRevenue",
    key: "totalRevenue",
    width: "15%",
    ellipsis: true,
    align: "right",
  },
  {
    title: "Khách vãng lai",
    ellipsis: true,
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "walkInGuestRevenue",
        width: "12%",
        ellipsis: true,
        key: "walkInGuestRevenue",
        align: "right",
      },
      {
        title: "Số lượt khách",
        width: "12%",
        dataIndex: "walkInGuestCount",
        ellipsis: true,
        align: "right",
        key: "walkInGuestCount",
      },
    ],
  },
  {
    title: "Thành viên sử dụng gói",
    ellipsis: true,
    children: [
      {
        title: "Số tiền tạm thu (VND)",
        dataIndex: "usingTurnRevenue",
        ellipsis: true,
        align: "right",
        width: "12%",
        key: "usingTurnRevenue",
      },
      {
        title: "Số lượt khách",
        dataIndex: "usingTurnCount",
        ellipsis: true,
        width: "12%",
        key: "usingTurnCount",
        align: "right",
      },
    ],
  },
  {
    title: "Tổng số tiền tạm thu (VND)",
    dataIndex: "totalPaymentCollected",
    key: "totalPaymentCollected",
    width: "15%",
    ellipsis: true,
    align: "right",
  },
];

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../service/configApi.js";

export const getFirstScriptData = createAsyncThunk(
  "script/getFirstScript",
  async (data) => {
    const response = await API.post(`/scripts/check-in`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getSecondScriptData = createAsyncThunk(
  "script/getSecondScript",
  async (data) => {
    const response = await API.post(
      `/scripts?toiletId=${data.toiletId}&numberOfBathroom=${data.numberOfBathRoom}&numberOfRestroom=${data.numberOfRestRoom}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    
    return response.data;
  }
);

export const getThirdScriptData = createAsyncThunk(
  "script/getThirdScript",
  async (toiletId) => {
    const response = await API.get(`/scripts/checkout?toiletId=${toiletId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    
    return response.data;
  }
);

export const getFourthScriptData = createAsyncThunk(
  "script/getFourthScript",
  async (data) => {
    const response = await API.post(`/scripts/manual-scheduler/${data.date}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const scriptSlice = createSlice({
  name: "script",
  initialState: {
    data: [],
    dataSecondScript: {},
    dataThirdScript: {},
    dataFourthScript: {},
    status: "idle",
  },
  reducers: {
    deleteToilet: (state) => {
      state.dataSecondScript = {};
      state.data = [];
      state.dataThirdScript = {};
      state.dataFourthScript = {};
      state.status = "idle"
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFirstScriptData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getFirstScriptData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(getSecondScriptData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getSecondScriptData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataSecondScript = action.payload;
    });
    builder.addCase(getThirdScriptData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getThirdScriptData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataThirdScript = action.payload;
    });

    builder.addCase(getFourthScriptData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getFourthScriptData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataFourthScript = action.payload;
    });
  },
});

export default scriptSlice.reducer;
export const showGetFirstScriptData = (state) => state.script.data;
export const showGetSecondScriptData = (state) =>
  state.script.dataSecondScript;
export const showGetThirdScriptData = (state) =>
  state.script.dataThirdScript;
export const showGetFourthScriptData = (state) =>
  state.script.dataFourthScript;
export const showStatus = (state) => state.script.status;
export const { deleteToilet } = scriptSlice.actions;

import { Avatar, Button, Checkbox, Popover, Result, Space, Table, Tag } from "antd";
import React, { useState } from "react";
import { FilterOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getRatingByRatingId,
  getRatingCountReport,
  getRatingFilterTag,
  getRatingReport,
  showDataRating,
  showDataRatingFilterTag,
  showDataRatingId,
  showRatingCount,
  showStatusRating,
  updateStatusRatingReport,
} from "../../../../features/toiletManagerSlice";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  StarFilled,
} from "@ant-design/icons";
import swal from "sweetalert";
import { Icon } from "@iconify/react";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import RatingDetailsEachToilet from "./RatingDetailsEachToilet";

function RatingEachToilet(props) {
  const [selectedTags, setSelectedTags] = useState(["Books"]);
  const showData = useSelector(showDataRating);
  const showCountRating = useSelector(showRatingCount);
  const showFilterTag = useSelector(showDataRatingFilterTag);
  const showStatus = useSelector(showStatusRating);
  const showRatingById = useSelector(showDataRatingId);
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [details, setDetails] = useState(false);
  const [widthSize, getWidthSize] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [current, setCurrent] = useState(1);
  const [showDetails, setShowDetails] = useState(false);
  const { CheckableTag } = Tag;

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  console.log(showRatingById);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setWidth = () => {
    getWidthSize(window.innerWidth);
  };

  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [widthSize]);

  useEffect(() => {
    const dataRating = {
      id: state.id,
      pageIndex: current,
    };
    dispatch(getRatingReport(dataRating));
  }, [current, showStatus]);

  useEffect(() => {
    dispatch(getRatingCountReport(state.id));
    dispatch(getRatingFilterTag());
  }, []);

  const HandleContent = (props) => {
    const navigate = useNavigate();
    return (
      <div>
        <p
          onClick={() => [
            setShowDetails(true),
            dispatch(getRatingByRatingId(props.id)),
            console.log(props.id)
          ]}
          className="rating-each-toilet-more-btn"
        >
          Xem chi tiết
        </p>
      </div>
    );
  };

  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  const HandleSolve = (props) => {
    const dispatch = useDispatch();
    const showStatus = useSelector(showStatusRating);
    const [status, setStatus] = useState(props.status);

    const handleUpdateRating = (newStatus) => {
      const data = {
        id: props.id,
        status: newStatus,
      };
      dispatch(updateStatusRatingReport(data));
    };

    return (
      <div>
        {status !== null ? (
          status === "Đã giải quyết" ? (
            <Tag color="processing">{status}</Tag>
          ) : (
            <Tag color="error">{status}</Tag>
          )
        ) : (
          <div>
            <CheckCircleOutlined
              className="rating-each-toilet-status-solve-icon"
              onClick={() => handleUpdateRating("Đã giải quyết")}
            />
            <CloseCircleOutlined
              className="rating-each-toilet-status-reject-icon"
              onClick={() =>
                swal({
                  title: "Xác nhận",
                  text: "Bạn có chắc chắn muốn từ chối giải quyết đánh giá này không?",
                  icon: "warning",
                  buttons: ["Hủy", "Xác nhận"],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    dispatch(handleUpdateRating("Từ chối giải quyết"));
                  } else {
                  }
                })
              }
            />
          </div>
        )}
      </div>
    );
  };

  const ratingColumns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      align: "center",
    },
    {
      title: "Ngày",
      dataIndex: "dateTime",
      key: "dateTime",
      ellipsis: true,
      width: "16%",
    },
    {
      title: "Nội dung",
      dataIndex: "comment",
      key: "comment",
      ellipsis: true,
      width: "40%",
    },
    {
      title: (
        <span>
          Đánh giá (<StarFilled style={{ fontSize: "12px" }} />)
        </span>
      ),
      dataIndex: "star",
      key: "star",
      width: "12%",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Giải quyết",
      dataIndex: "status",
      width: "18%",
      align: "center",
      render: (item, record) => {
        return <HandleSolve status={item} id={record.id} />;
      },
    },
    {
      title: "",
      align: "right",
      width: "8%",
      dataIndex: "id",
      render: (item) => {
        return (
          <div>
            <Popover
              placement="bottomRight"
              content={<HandleContent id={item} />}
              trigger="click"
            >
              <Button
                style={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  height: "22px",
                }}
              >
                <Icon icon="ic:outline-more-horiz" />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];

  const onChangeCheckBox = (e) => {
    if (e.target.checked) {
      console.log(`${e.target.value}`);
    }
  };

  const content = () => {
    const data = [];
    return (
      <div className="rating-each-toilet-filter-container">
        <p className="rating-each-toilet-filter-header-text">
          Lọc theo đánh giá kém
        </p>
        <div className="rating-each-toilet-filter-checkbox">
          <Checkbox
            value={
              showFilterTag !== undefined
                ? showFilterTag.map((item) => {
                    return data.concat(`"${item.name}"`);
                  })
                : ""
            }
            onChange={onChangeCheckBox}
          >
            Tất cả
          </Checkbox>
        </div>
        {showFilterTag !== undefined
          ? showFilterTag.map((item) => {
              return (
                <div className="rating-each-toilet-filter-checkbox">
                  <Checkbox value={item.name} onChange={onChangeCheckBox}>
                    {item.name}
                  </Checkbox>
                </div>
              );
            })
          : ""}

        <p className="rating-each-toilet-filter-header-text-star">
          Lọc theo số sao
        </p>
        <div className="rating-each-toilet-filter-checkbox-star">
          <Checkbox>1 sao</Checkbox>
          <Checkbox>2 sao</Checkbox>
          <Checkbox>3 sao</Checkbox>
          <Checkbox>4 sao</Checkbox>
          <Checkbox>5 sao</Checkbox>
        </div>
        <p className="rating-each-toilet-filter-header-text-star">
          Lọc theo hiện trạng
        </p>
        <div className="rating-each-toilet-filter-checkbox">
          <Checkbox>Đã giải quyết</Checkbox>
        </div>
        <div className="rating-each-toilet-filter-checkbox">
          <Checkbox>Chưa giải quyết</Checkbox>
        </div>
        <div className="rating-each-toilet-filter-btn-container">
          <Button type="text">Hủy</Button>
          <Button type="primary">Lọc</Button>
        </div>
      </div>
    );
  };

  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${showCountRating}`;

  //actions
  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  return (
    <div className="rating-each-toilet-container">
      {showDetails === false ? (
        <div>
          <div className="rating-each-toilet-header">
            {props.rating === 0 ? (
              <p>Chưa có đánh giá</p>
            ) : (
              <p>Tổng đánh giá: {props.rating} sao</p>
            )}
            {widthSize < 768 ? (
              <Popover placement="bottomLeft" content={content} trigger="click">
                <Button type="default">
                  <FilterOutlined /> Bộ lọc
                </Button>
              </Popover>
            ) : (
              <Popover placement="rightTop" content={content} trigger="click">
                <Button type="default">
                  <FilterOutlined /> Bộ lọc
                </Button>
              </Popover>
            )}
          </div>
          <div>
            {screenSize <= 1024 ? (
              <Table
                size="small"
                scroll={{ x: "140vw" }}
                locale={locale}
                className="table"
                bordered
                columns={ratingColumns}
                dataSource={showData.map((item, index) => {
                  return {
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                    dateTime: item.dateTime,
                    comment: item.comment,
                    star: item.star,
                    id: item.id,
                    status: item.status,
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  pageSize: 8,
                  onChange: onChange,
                  total: showCountRating,
                  position: ["bottomCenter"],
                }}
              />
            ) : (
              <Table
                className="table"
                size="small"
                bordered
                locale={locale}
                columns={ratingColumns}
                dataSource={showData.map((item, index) => {
                  return {
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 10 + (index + 1),
                    dateTime: item.dateTime,
                    comment: item.comment,
                    star: item.star,
                    id: item.id,
                    status: item.status,
                  };
                })}
                pagination={{
                  current: current,
                  showTotal: showTotal,
                  onChange: onChange,
                  pageSize: 8,
                  total: showCountRating,
                  position: ["bottomCenter"],
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <RatingDetailsEachToilet
          fullName={showRatingById.fullName}
          comment={showRatingById.comment}
          dateTime={showRatingById.dateTime}
          imageSources={showRatingById.imageSources}
          commonComments={showRatingById.commonComments}
          star={showRatingById.star}
          status={showRatingById.status}
          back={() => setShowDetails(false)}
        />
      )}
    </div>
  );
}

export default RatingEachToilet;

import { Icon } from "@iconify/react";
import { Button, Card, Col, Input, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import NumericInput from "../../../components/reuse/NumericInput";
import "./RechargeCom.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePhone,
  getCombo,
  getInforByPhone,
  postComboByPhone,
  showCombo,
  showGetCombo,
  showInforByPhone,
  showPostComboByPhone,
  showStatusInfor,
} from "../../../features/rechargeSlice";
import swal from "sweetalert";

function RechargeTurn(props) {
  const [selected, setSelected] = useState(-1);
  const [turn, setTurn] = useState(0);
  const [phone, setPhone] = useState(0);
  const showInfor = useSelector(showInforByPhone);
  const showStatus = useSelector(showStatusInfor);
  const showCombo = useSelector(showGetCombo);
  const showPostCombo = useSelector(showPostComboByPhone);
  const dispatch = useDispatch();
  const [screenSize, getDimension] = useState(window.innerWidth);
  const method = "Chuyển khoản";

  console.log(turn);

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const handleGetInforByPhone = (phone) => {
    dispatch(getInforByPhone(phone));
  };

  const handleDelPhone = (phone) => {
    if (phone.length < 10) {
      dispatch(deletePhone(phone));
    }
  };

  useEffect(() => {
    handleDelPhone(phone);
  }, [phone]);

  const handleGetCombo = () => {
    dispatch(getCombo());
  };

  const handlePostCombo = () => {
    const dataConfig = {
      accountId: showInfor.accountId,
      comboId: turn,
      paymentMethod: method,
    };
    dispatch(postComboByPhone(dataConfig));
  };

  return (
    <div>
      {screenSize <= 768 ? (
        <div>
          <div className="rechargeCom-phone-container">
            <p className="rechargeCom-phone">
              Số điện thoại<span style={{ color: "red" }}> *</span>
            </p>
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <NumericInput
                placeholder="Nhập số điện thoại"
                handleChange={(e) => setPhone(e.target.value)}
                value={phone}
                maxlength="10"
              />
              {phone.length < 10 || phone.length === undefined ? (
                <Button
                  className="rechargeCom-find-btn"
                  type="primary"
                  disabled
                >
                  Tìm
                </Button>
              ) : (
                <Button
                  onClick={() => [
                    handleGetInforByPhone(phone),
                    handleGetCombo(),
                  ]}
                  className="rechargeCom-find-btn"
                  type="primary"
                >
                  Tìm
                </Button>
              )}
            </Space.Compact>
          </div>
          {Object.keys(showInfor).length !== 0 ? (
            <Card
              title="Thông tin khách hàng"
              bordered={true}
              style={{
                width: "100%",
                marginTop: "16px",
              }}
            >
              <div className="rechargeCom-infor-container">
                <div className="rechargeCom-infor-phone-container">
                  <p className="rechargeCom-infor-phone-title">Số điện thoại</p>
                  <p className="rechargeCom-infor-phone">{phone}</p>
                </div>
                <div className="rechargeCom-infor-name-container">
                  <p className="rechargeCom-infor-name-title">Tên khách hàng</p>
                  <p className="rechargeCom-infor-name">{showInfor.fullName}</p>
                </div>
              </div>
              <div className="rechargeCom-infor-price-container">
                <p className="rechargeCom-infor-price-title">
                  Chọn số lượt<span style={{ color: "red" }}> *</span>
                </p>
                <div className="rechargeCom-infor-price-card-container">
                  <div className="rechargeCom-infor-price-card">
                    {showCombo.slice(0, 4).map((price, index) => {
                      return (
                        <div
                          onClick={
                            selected !== index || selected === -1
                              ? () => [setSelected(index), setTurn(price.id)]
                              : () => [setSelected(-1), setTurn(0)]
                          }
                        >
                          {index === selected ? (
                            <div className="rechargeCom-infor-price-active">
                              <Icon
                                className="rechargeCom-infor-icon"
                                icon="material-symbols:check-circle-rounded"
                              />
                              <p className="rechargeCom-infor-turn">
                                {price.totalTurn} lượt
                              </p>
                              <p>{price.price} VND</p>
                            </div>
                          ) : (
                            <div className="rechargeCom-infor-price">
                              <p className="rechargeCom-infor-turn">
                                {price.totalTurn} lượt
                              </p>
                              <p>{price.price} VND</p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="rechargeCom-infor-price-card">
                    {showCombo.slice(4, 8).map((price, index) => {
                      return (
                        <div
                          onClick={
                            selected !== index + 4 || selected === -1
                              ? () => [
                                  setSelected(index + 4),
                                  setTurn(price.id),
                                ]
                              : () => [setSelected(-1), setTurn(0)]
                          }
                        >
                          {index + 4 === selected ? (
                            <div className="rechargeCom-infor-price-active">
                              <Icon
                                className="rechargeCom-infor-icon"
                                icon="material-symbols:check-circle-rounded"
                              />
                              <p className="rechargeCom-infor-turn">
                                {price.totalTurn} lượt
                              </p>
                              <p>{price.price} VND</p>
                            </div>
                          ) : (
                            <div className="rechargeCom-infor-price">
                              <p className="rechargeCom-infor-turn">
                                {price.totalTurn} lượt
                              </p>
                              <p>{price.price} VND</p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {turn === 0 ? (
                <Button disabled>Nạp lượt</Button>
              ) : (
                <Button
                  onClick={() =>
                    swal({
                      title: `Xác nhận mua gói`,
                      text: `Mua gói ${turn} cho số điện thoại ${phone}`,
                      icon: "warning",
                      buttons: ["Hủy", "Xác nhận"],
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        handlePostCombo();
                        swal("Mua gói thành công", {
                          icon: "success",
                        });
                        setPhone(0);
                        dispatch(deletePhone());
                      } else {
                        swal("Lệnh mua gói bị hủy", {
                          icon: "error",
                        });
                      }
                    })
                  }
                  type="primary"
                >
                  Nạp lượt
                </Button>
              )}
            </Card>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Row>
          <Col span={8}>
            <div className="rechargeCom-phone-container">
              <p className="rechargeCom-phone">
                Số điện thoại<span style={{ color: "red" }}> *</span>
              </p>
              <Space.Compact
                style={{
                  width: "80%",
                }}
              >
                <NumericInput
                  placeholder="Nhập số điện thoại"
                  value={phone}
                  handleChange={(e) => setPhone(e.target.value)}
                  maxlength="10"
                />
                {phone.length < 10 || phone.length === undefined ? (
                  <Button
                    className="rechargeCom-find-btn"
                    type="primary"
                    disabled
                  >
                    Tìm
                  </Button>
                ) : (
                  <Button
                    onClick={() => [
                      handleGetInforByPhone(phone),
                      handleGetCombo(),
                    ]}
                    className="rechargeCom-find-btn"
                    type="primary"
                  >
                    Tìm
                  </Button>
                )}
              </Space.Compact>
            </div>
          </Col>
          {Object.keys(showInfor).length !== 0 ? (
            <Col span={16}>
              <Card
                title="Thông tin khách hàng"
                bordered={true}
                style={{
                  width: "100%",
                  marginTop: "16px",
                }}
              >
                <div className="rechargeCom-infor-container">
                  <div className="rechargeCom-infor-phone-container">
                    <p className="rechargeCom-infor-phone-title">
                      Số điện thoại
                    </p>
                    <p className="rechargeCom-infor-phone">{phone}</p>
                  </div>
                  <div className="rechargeCom-infor-name-container">
                    <p className="rechargeCom-infor-name-title">
                      Tên khách hàng
                    </p>
                    <p className="rechargeCom-infor-name">
                      {showInfor.fullName}
                    </p>
                  </div>
                </div>
                <div className="rechargeCom-infor-price-container">
                  <p className="rechargeCom-infor-price-title">
                    Chọn số lượt<span style={{ color: "red" }}> *</span>
                  </p>
                  <div className="rechargeCom-infor-price-card-container">
                    <div className="rechargeCom-infor-price-card">
                      {showCombo.slice(0, 4).map((price, index) => {
                        return (
                          <div
                            onClick={
                              selected !== index || selected === -1
                                ? () => [setSelected(index), setTurn(price.id)]
                                : () => [setSelected(-1), setTurn(0)]
                            }
                          >
                            {index === selected ? (
                              <div className="rechargeCom-infor-price-active">
                                <Icon
                                  className="rechargeCom-infor-icon"
                                  icon="material-symbols:check-circle-rounded"
                                />
                                <p className="rechargeCom-infor-turn">
                                  {price.totalTurn} lượt
                                </p>
                                <p>{price.price.toLocaleString("en-US")} VND</p>
                              </div>
                            ) : (
                              <div className="rechargeCom-infor-price">
                                <p className="rechargeCom-infor-turn">
                                  {price.totalTurn} lượt
                                </p>
                                <p>{price.price.toLocaleString("en-US")} VND</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="rechargeCom-infor-price-card">
                      {showCombo.slice(4, 8).map((price, index) => {
                        return (
                          <div
                            onClick={
                              selected !== index + 4 || selected === -1
                                ? () => [
                                    setSelected(index + 4),
                                    setTurn(price.id),
                                  ]
                                : () => [setSelected(-1), setTurn(0)]
                            }
                          >
                            {index + 4 === selected ? (
                              <div className="rechargeCom-infor-price-active">
                                <Icon
                                  className="rechargeCom-infor-icon"
                                  icon="material-symbols:check-circle-rounded"
                                />
                                <p className="rechargeCom-infor-turn">
                                  {price.totalTurn} lượt
                                </p>
                                <p>{price.price.toLocaleString("en-US")} VND</p>
                              </div>
                            ) : (
                              <div className="rechargeCom-infor-price">
                                <p className="rechargeCom-infor-turn">
                                  {price.totalTurn} lượt
                                </p>
                                <p>{price.price.toLocaleString("en-US")} VND</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {turn === 0 ? (
                  <Button disabled>Nạp lượt</Button>
                ) : (
                  <Button
                    onClick={() =>
                      swal({
                        title: `Xác nhận mua gói`,
                        text: `Mua gói ${turn} cho số điện thoại ${phone}`,
                        icon: "warning",
                        buttons: ["Hủy", "Xác nhận"],
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          handlePostCombo();
                          swal("Mua gói thành công", {
                            icon: "success",
                          });
                          setPhone(0);
                          dispatch(deletePhone());
                        } else {
                          swal("Lệnh mua gói bị hủy", {
                            icon: "error",
                          });
                        }
                      })
                    }
                    type="primary"
                  >
                    Nạp lượt
                  </Button>
                )}
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      )}
    </div>
  );
}

export default RechargeTurn;

import { Col, Result, Row, Spin, Table } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import Menu from "../../../components/menu/Menu";
import Breadcrumbs from "../../../components/reuse/Breadcrumbs.jsx";
import "../statistic/Statistic.scss";
import { DatePicker, Space, Input } from "antd";
import {
  StatisticColumn,
  StatisticColumnExportExcel,
  statisticData,
} from "./StatisticColumn";
import { useDispatch, useSelector } from "react-redux";
import SockJsClient from "react-stomp";

import {
  getMonthStatistic,
  getStatistic,
  showStatistic,
  showStatisticMonth,
  statisticStatus,
} from "../../../features/statisticSlice";
import { Icon } from "@iconify/react";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { useState } from "react";
import {
  getPaymentStatisticAllToilet,
  getStatisticAllToilet,
  getStatisticCount,
  showPaymentStatisticAllToilet,
  showStatisticAllToilet,
  showStatisticCount,
} from "../../../features/toiletManagerSlice";
dayjs.locale("vi");

const TITLE = `Báo cáo số tiền tạm thu - Nhà vệ sinh Nguyễn Huệ`;
const SOCKET_URL = "https://api.publictoilet.click/ws-message";

function Statistic() {
  //websocket
  const [message, setMessage] = useState("You server message here.");
  //
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [current, setCurrent] = useState(1);
  const statistic = useSelector(showStatistic);
  const dataStatisticAll = useSelector(showStatisticAllToilet);
  const dataPaymentStatisticAll = useSelector(showPaymentStatisticAllToilet);
  const statisticMonth = useSelector(showStatisticMonth);
  const statisticCount = useSelector(showStatisticCount);
  const status = useSelector(statisticStatus);
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs().format("DD/MM/YYYY"));
  const [endDateMonth, setEndDateMonth] = useState(
    dayjs().format("DD/MM/YYYY")
  );
  const [startDateMonth, setStartDateMonth] = useState(
    dayjs().startOf("month").format("DD/MM/YYYY")
  );
  const format = "DD/MM/YYYY";
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //action
  const onChange = (range) => {
    setStartDate(range?.[0].format(format));
    setEndDate(range?.[1].format(format));
  };
  const onSearch = (value) => console.log(value);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current?.nativeElement,
    filename: "Tổng số tiền tạm thu nhà vệ sinh",
    sheet: "Revenue",
  });

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //websocket
  let onConnected = () => {
    console.log("Connected!!");
  };
  let onMessageReceived = (msg) => {
    setMessage(msg);
  };
  //

  useEffect(() => {
    // console.log('tableRef', tableRef.current.nativeElement);
  }, [tableRef.current]);

  //get data API
  useEffect(() => {
    dispatch(getMonthStatistic(localStorage.getItem("comId")));
    const dataStatistic = {
      id: localStorage.getItem("comId"),
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(getStatisticCount(dataStatistic));
    dispatch(getStatisticAllToilet(dataStatistic));
    dispatch(getPaymentStatisticAllToilet(dataStatistic));
    document.title = TITLE;
  }, [message]);

  useEffect(() => {
    const data = {
      id: localStorage.getItem("comId"),
      startDate: startDate,
      endDate: endDate,
      pageIndex: current,
    };
    const dataStatistic = {
      id: localStorage.getItem("comId"),
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(getStatistic(data));
    dispatch(getStatisticCount(dataStatistic));
    dispatch(getPaymentStatisticAllToilet(dataStatistic));
  }, [startDate, endDate, current, message]);

  const onChangeNavigate = (value) => {
    setCurrent(value);
  };

  const customSubcribe = {
    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
  };

  const handleMapPaymentToStatistic = useCallback(
    (toiletId) => {
      // const res = dataPaymentStatisticAll.find(
      //   (d) => d.collectorId === toiletId
      // );
      const res = dataPaymentStatisticAll.reduce((acc, cur) => {
        if (cur.collectorId === toiletId) {
          return acc + cur.total;
        }
        return acc;
      }, 0);

      if (res) {
        return res;
      }
      return 0;
    },
    [dataPaymentStatisticAll]
  );

  const dataSource = useMemo(() => {
    return statistic.map((item, index) => {
      return {
        toiletName: item.toiletName,
        toiletId: item.toiletId,
        // message: item.message,
        totalRevenue: item.totalRevenue.toLocaleString("en-US"),
        totalPaymentCollected: handleMapPaymentToStatistic(
          item.toiletId
        ).toLocaleString("en-US"),
        walkInGuestRevenue: item.walkInGuestRevenue.toLocaleString("en-US"),
        walkInGuestCount: item.walkInGuestCount.toLocaleString("en-US"),
        usingTurnRevenue: item.usingTurnRevenue.toLocaleString("en-US"),
        usingTurnCount: item.usingTurnCount.toLocaleString("en-US"),
        stt: current === 1 ? index + 1 : (current - 1) * 8 + (index + 1),
      };
    });
  }, [statistic, handleMapPaymentToStatistic, current]); // dependencies;

  const dataSourceExportExcel = useMemo(
    () =>
      dataStatisticAll.map((item, index) => {
        return {
          toiletName: item.toiletName,
          toiletId: item.toiletId,
          // message: item.message,
          totalRevenue: item.totalRevenue.toLocaleString("en-US"),
          totalPaymentCollected: handleMapPaymentToStatistic(
            item.toiletId
          ),
          walkInGuestRevenue: item.walkInGuestRevenue.toLocaleString("en-US"),
          walkInGuestCount: item.walkInGuestCount.toLocaleString("en-US"),
          usingTurnRevenue: item.usingTurnRevenue.toLocaleString("en-US"),
          usingTurnCount: item.usingTurnCount.toLocaleString("en-US"),
          stt: current === 1 ? index + 1 : (current - 1) * 8 + (index + 1),
        };
      }),
    [dataStatisticAll, handleMapPaymentToStatistic, current]
  ); // dependencies;

  //config empty table
  let locale = {
    emptyText: (
      <Result
        title="Chưa có dữ liệu"
        subTitle="Hiện tại, hệ thống không ghi nhận dữ liệu của bảng này"
      />
    ),
  };

  //render
  return (
    <div className="container statistic-container">
      <Row>
        <Col className="statistic-left-container" span={4}>
          <Menu styleStatistic="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <SockJsClient
            url={SOCKET_URL}
            // ref={(client) => console.log(client)}
            debug={true}
            topics={["/topic/check-in"]}
            onConnect={onConnected}
            onDisconnect={() => console.log("Disconnected!")}
            onMessage={(msg) => onMessageReceived(msg)}
            subscribeHeaders={customSubcribe}
            headers={customSubcribe}
            autoReconnect={true}
          />
          <div className="statistic-table-content-container">
            <div className="statistic-each-toilet">
              <div className="statistic-each-toilet-number-container">
                <div className="statistic-each-toilet-number-header">
                  <p className="statistic-each-toilet-count-number">
                    {statisticMonth.totalTurn !== undefined
                      ? statisticMonth.totalTurn.toLocaleString("en-US")
                      : ""}
                  </p>
                  <Icon
                    className="statistic-each-toilet-count-icon"
                    icon="material-symbols:person-celebrate-rounded"
                  />
                </div>
                <p className="statistic-each-toilet-number">
                  Tổng lượt khách trong tháng
                </p>
                <p className="statistic-each-toilet-rate">
                  {startDateMonth} - {endDateMonth}
                </p>
              </div>
              <div className="statistic-each-toilet-number-container">
                <div className="statistic-each-toilet-number-header">
                  <p className="statistic-each-toilet-count-number">
                    {statisticMonth.totalRevenue !== undefined
                      ? statisticMonth.totalRevenue.toLocaleString("en-US")
                      : ""}
                  </p>
                  <Icon
                    className="statistic-each-toilet-count-icon"
                    icon="material-symbols:person-celebrate-rounded"
                  />
                </div>
                <p className="statistic-each-toilet-number">
                  Tổng số tiền tạm thu trong tháng
                </p>
                <p className="statistic-each-toilet-rate">
                  {startDateMonth} - {endDateMonth}
                </p>
              </div>
            </div>
            <div className="statistic-toilet-content-excel-container">
              <RangePicker
                locale={locale}
                defaultValue={[
                  dayjs(startDate, format),
                  dayjs(endDate, format),
                ]}
                placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                className="statistic-table-header-date"
                format="DD/MM/YYYY"
                presets={[
                  {
                    label: "Tuần trước",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Tháng trước",
                    value: [dayjs().add(-1, "month"), dayjs()],
                  },
                  {
                    label: "Năm trước",
                    value: [dayjs().add(-1, "year"), dayjs()],
                  },
                ]}
                onChange={onChange}
              />

              <button
                onClick={onDownload}
                className="statistic-toilet-content-excel-btn"
                style={{ cursor: "pointer" }}
              >
                Xuất file excel số tiền tạm thu
              </button>
            </div>

            {screenSize < 768 ? (
              <Table
                scroll={{
                  x: "120vw",
                }}
                size="small"
                locale={locale}
                className="table"
                loading={status === "loading" ? true : false}
                bordered
                columns={StatisticColumn}
                dataSource={dataSource}
                pagination={{
                  current: current,
                  onChange: onChangeNavigate,
                  pageSize: 8,
                  showTotal: showTotal,
                  total: statisticCount,
                  position: ["bottomCenter"],
                }}
              />
            ) : (
              <Table
                size="small"
                locale={locale}
                className="table"
                loading={status === "loading" ? true : false}
                bordered
                columns={StatisticColumn}
                dataSource={dataSource}
                pagination={{
                  current: current,
                  onChange: onChangeNavigate,
                  pageSize: 8,
                  showTotal: showTotal,
                  total: statisticCount,
                  position: ["bottomCenter"],
                }}
              />
            )}

            <Table
              ref={tableRef}
              locale={locale}
              className="table"
              size="small"
              bordered
              style={{ display: "none" }}
              columns={StatisticColumnExportExcel}
              dataSource={dataSourceExportExcel}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Statistic;

import { Badge, Tag } from "antd";
import { StarFilled } from "@ant-design/icons";

export const RatingColumn = [
  {
    title: "STT",
    dataIndex: "stt",
    ellipsis: true,
    width: "6%",
    sorter: (a, b) => a.date.length - b.date.length,
    align: 'center'
  },
  {
    title: "Ngày báo cáo",
    dataIndex: "date",
    ellipsis: true,
    width: "16%",
    sorter: (a, b) => a.date.length - b.date.length,
  },
  {
    title: "Lý do báo cáo",
    dataIndex: "reason",
    ellipsis: true,
    width: "40%",
  },
  {
    title: (
      <span>
        Đánh giá (<StarFilled style={{ fontSize: "12px" }} />)
      </span>
    ),
    dataIndex: "rating",
    ellipsis: true,
    align: 'right',
    width: "8%",
  },
  {
    title: "Trạng thái",
    width: '12%',
    dataIndex: "status",
    ellipsis: true,
    render: (item) => {
      return item === "Đã giải quyết" ? (
        <Tag color="processing">Đã giải quyết</Tag>
      ) : (
        <Tag color="error">Chưa giải quyết</Tag>
      );
    },
  },
];

import { Icon } from "@iconify/react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateUpdatePackagePrice from "./CreateUpdatePackagePrice";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import { InfoCircleOutlined } from "@ant-design/icons";
import ModalReuse from "../../../components/reuse/ModalReuse";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { deletePackagePrice, getFullPackagePrice, getPackagePriceById, postPackagePrice, showAllPackagePrice, showDelPackagePrice, showNewPackagePrice, showPackagePriceById, showUpdatePackagePrice, updatePackagePrice } from "../../../features/adminSlice";

function ConfigPackagePrice() {
  const [openNewPackage, setOpenNewPackage] = useState(false);
  const [openChangeDatePackage, setOpenChangeDatePackage] = useState(false);
  const [turn, setTurn] = useState();
  const [price, setPrice] = useState();
  const [id, setId] = useState();
  const [current, setCurrent] = useState(1);
  const dispatch = useDispatch();
  const dataPackage = useSelector(showAllPackagePrice)
  const dataNewPackage = useSelector(showNewPackagePrice)
  const dataUpdatePackage = useSelector(showUpdatePackagePrice)
  const dataDelPackage = useSelector(showDelPackagePrice)
  const dataPackageById = useSelector(showPackagePriceById)
  const format = "DD/MM/YYYY";

  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().add(7, "d").endOf("day");
  };

  const handleDelCmt = (id) => {
    swal({
      title: "Xác nhận",
      text: "Bạn có chắc chắn muốn xóa gói này không?",
      icon: "warning",
      buttons: ["Hủy", "Xác nhận"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deletePackagePrice(id));
      } else {
      }
    });
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      width: "12%",
      align: "center",
    },
    {
      title: "Tên gói",
      dataIndex: "STT",
      width: "24%",
      align: "center",
      render: (item) => {
        return `Gói ${item}`
      },
    },
    {
      title: "Số lượt",
      dataIndex: "totalTurn",
      align: "right",
      width: "24%",
    },
    {
      title: "Giá (VND)",
      dataIndex: "price",
      width: "24%",
      align: "right",
    },
    {
      title: " ",
      dataIndex: "id",
      render: (item) => {
        return <Icon style={{cursor: 'pointer'}} color="#0B79D9" onClick={() => showModalChangeDatePackage(item)} icon="material-symbols:edit" />;
      },
      width: "8%",
      align: "right",
    },
    {
      title: " ",
      dataIndex: "id",
      render: (item) => {
        return <Icon style={{cursor: 'pointer'}} color="red" icon="material-symbols:delete" onClick={() => handleDelCmt(item)}/>;
      },
      width: "8%",
      align: "right",
    },
  ];

  //handle get combo
  useEffect(() => {
    dispatch(getFullPackagePrice())
  }, [dataNewPackage, dataDelPackage, dataUpdatePackage])

  //handle modal new Package
  const showModalNewPackage = () => {
    setOpenNewPackage(true);
  };

  const handleOkNewPackage = () => {
    const data = {
      totalTurn: turn,
      price: price
    }
    dispatch(postPackagePrice(data))
    setOpenNewPackage(false);
  };

  const handleCancelNewPackage = () => {
    setOpenNewPackage(false);
  };

  //handle modal change date Package
  const showModalChangeDatePackage = (id) => {
    dispatch(getPackagePriceById(id))
    setOpenChangeDatePackage(true);
  };

  useEffect(() => {
    setTurn(dataPackageById.totalTurn);
    setPrice(dataPackageById.price);
    setId(dataPackageById.id)
  }, [dataPackageById])

  const handleOkChangeDatePackage = () => {
    const data = {
      id: id,
      totalTurn: turn,
      price: Number(price)
    }
    dispatch(updatePackagePrice(data))
    setOpenChangeDatePackage(false);
  };

  const handleCancelChangeDatePackage = () => {
    setOpenChangeDatePackage(false);
  };

  return (
    <div className="config-header" style={{ marginTop: "4%" }}>
      <p>Giá gói</p>
      <div className="config-container">
        <div className="config-add-new-more-row">
          <p></p>
          <div className="config-add-new-button-container">
            <Button onClick={showModalNewPackage} type="primary">
              Thêm gói
            </Button>
          </div>
        </div>
        <Table
          size="small"
          bordered
          className="table"
          pagination={{
            pageSize: 8,
            showTotal: showTotal,
            position: ["bottomCenter"],
          }}
          columns={columns}
          dataSource={dataPackage.map((item, index) => {
            return {
              id: item.id,
              totalTurn: item.totalTurn,
              price: item.price.toLocaleString('en'),
              STT: current === 1 ? (index + 1) : ((current - 1) * 8) + (index + 1)
            }
          })}
        />

        {/* Modal add new*/}
        <ModalReuse
          open={openNewPackage}
          title="Thêm gói mới"
          handleOk={handleOkNewPackage}
          handleCancel={handleCancelNewPackage}
          titleOk="Tạo mới"
          children={
            <div className="config-change-date-service-container">
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <label>Số lượt</label>
                  <Input value={turn} onChange={(e) => setTurn(e.target.value)} placeholder="Nhập số lượt"/>
                </Col>
                <Col span={18}>
                  <label>Giá</label>
                  <Input value={price} onChange={(e) => setPrice(e.target.value)} suffix="VND" placeholder="Nhập giá"/>
                </Col>
              </Row>
            </div>
          }
        />
        {/* Modal change date*/}
        <ModalReuse
          open={openChangeDatePackage}
          title="Cập nhật giá gói"
          handleOk={handleOkChangeDatePackage}
          handleCancel={handleCancelChangeDatePackage}
          titleOk="Cập nhật"
          width={500}
          children={
            <div className="config-change-date-service-container">
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <label>Số lượt</label>
                  <Input value={turn} onChange={(e) => setTurn(e.target.value)}/>
                </Col>
                <Col span={18}>
                  <label>Giá</label>
                  <Input value={price} onChange={(e) => setPrice(e.target.value)} suffix="VND" />
                </Col>
              </Row>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ConfigPackagePrice;

import React from "react";
import { useState } from "react";
import { Button, Input } from "antd";

function CreateUpdateFacility(props) {
  const [facility, setFacility] = useState(props.facility);
  const [check, setCheck] = useState(false);

  return (
    <div className="create-new-facility-price-container-box">
      <div className="create-new-facility-price-container">
        <p>Tiện ích</p>
        <Input
          value={facility}
          className="create-new-facility-price-input"
          onChange={(e) => setFacility(e.target.value)}
          placeholder="Nhập tiện ích"
        />
      </div>
      <div className="create-new-facility-price-container">
        <p>Trạng thái</p>
        <div className="create-new-facility-price-checkbox">
          <input
            type="checkbox"
            name="remember"
            onChange={() => setCheck(!check)}
            checked={check}
          />
          <p className="create-new-facility-price-text">Khả dụng</p>
        </div>
      </div>
      <div className="create-new-facility-price-btn-container">
        <Button onClick={props.goBack}>Trở về</Button>
        <Button type="primary">Tạo mới</Button>
      </div>
    </div>
  );
}

export default CreateUpdateFacility;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../service/configApi.js";

const FakeToken =
  "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjg1OTAzMDgxLCJleHAiOjE2ODY1MDc4ODEsInVzZXJuYW1lIjoiYWRtaW4tdG9pbGV0LW1hcCIsInJvbGUiOiJBZG1pbiIsImF1dGhvcml0aWVzIjpbeyJhdXRob3JpdHkiOiJST0xFX0FkbWluIn1dfQ.zZk72NqxeJvllcxTTF8KWPYDNsUPpVLJltWxhW1yQOx3PFmDdLjbk0NkJg5cgru0v_96qELsmUma6PaKD2YEWg";

export const getInternalAdvertisement = createAsyncThunk(
  "staff/getInAd",
  async (data) => {
    const response = await API.get(
      `/announcements?announcement-type=${data.type}&pageIndex=${data.pageIndex}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getExternalAdvertisement = createAsyncThunk(
  "staff/getExAd",
  async (data) => {
    const response = await API.get(
      `/announcements?announcement-type=${data.type}&pageIndex=${data.pageIndex}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getExternalAppAdvertisement = createAsyncThunk(
  "staff/getExAppAd",
  async (data) => {
    const response = await API.get(
      `/announcements?announcement-type=${data.type}&pageIndex=${data.pageIndex}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getExternalAppAdvertisementCount = createAsyncThunk(
  "staff/getExAppAdCount",
  async (type) => {
    const response = await API.get(
      `/announcements/count?announcement-type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getExternalAdvertisementById = createAsyncThunk(
  "staff/getExAdById",
  async (id) => {
    const response = await API.get(
      `/announcements/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getInternalAdvertisementById = createAsyncThunk(
  "staff/getInAdById",
  async (id) => {
    const response = await API.get(
      `/announcements/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getInternalAdvertisementCount = createAsyncThunk(
  "staff/getInAdCount",
  async (type) => {
    const response = await API.get(
      `/announcements/count?announcement-type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const getExternalAdvertisementCount = createAsyncThunk(
  "staff/getExAdCount",
  async (type) => {
    const response = await API.get(
      `/announcements/count?announcement-type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const createExternalAdvertisement = createAsyncThunk(
  "staff/postExAd",
  async (data) => {
    const response = await API.post(
      `/announcements`, data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const createInternalAdvertisement = createAsyncThunk(
  "staff/postInAd",
  async (data) => {
    const response = await API.post(
      `/announcements`, data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const updateExternalAdvertisement = createAsyncThunk(
  "staff/patchExAd",
  async (data) => {
    const response = await API.patch(
      `/announcements/${data.id}`, data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const updateInternalAdvertisement = createAsyncThunk(
  "staff/patchInAd",
  async (data) => {
    const response = await API.patch(
      `/announcements/${data.id}`, data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const deleteInternalAdvertisement = createAsyncThunk(
  "staff/deleteInAd",
  async (id) => {
    const response = await API.delete(
      `/announcements/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const deleteExternalAdvertisement = createAsyncThunk(
  "staff/deleteExAd",
  async (id) => {
    const response = await API.delete(
      `/announcements/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    // The value we return becomes the `fulfilled` action payload
    return response.data.data;
  }
);

export const staffSlice = createSlice({
  name: "staff",
  initialState: {
    dataInAd: [],
    dataExAd: [],
    dataExAppAd: [],
    dataSpecificInAd: {},
    dataSpecificExAd: {},
    dataInAdCount: 0,
    dataExAdCount: 0,
    dataExAppAdCount: 0,
    dataPostInAd: {},
    dataPostExAd: {},
    dataUpdateInAd: {},
    dataUpdateExAd: {},
    resDelInAd: {},
    resDelExAd: {},
  },
  extraReducers: (builder) => {
    // --- Xử lý trong reducer với case fulfilled ---
    builder.addCase(getInternalAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataInAd = action.payload;
    });
    builder.addCase(getExternalAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataExAd = action.payload;
    });
    builder.addCase(getExternalAppAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataExAppAd = action.payload;
    });
    builder.addCase(getInternalAdvertisementById.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataSpecificInAd = action.payload;
    });
    builder.addCase(getExternalAdvertisementById.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataSpecificExAd = action.payload;
    });
    builder.addCase(getInternalAdvertisementCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataInAdCount = action.payload;
    });
    builder.addCase(getExternalAdvertisementCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataExAdCount = action.payload;
    });
    builder.addCase(getExternalAppAdvertisementCount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataExAppAdCount = action.payload;
    });
    builder.addCase(createInternalAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataPostInAd = action.payload;
    });
    builder.addCase(createExternalAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataPostExAd = action.payload;
    });
    builder.addCase(updateInternalAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataUpdateInAd = action.payload;
    });
    builder.addCase(updateExternalAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.dataUpdateExAd = action.payload;
    });
    builder.addCase(deleteInternalAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.resDelInAd = action.payload;
    });
    builder.addCase(deleteExternalAdvertisement.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.resDelExAd = action.payload;
    });
  },
});

export default staffSlice.reducer;
export const showInternalAdvertisement = (state) => state.staff.dataInAd;
export const showExternalAdvertisement = (state) => state.staff.dataExAd;
export const showExternalAppAdvertisement = (state) => state.staff.dataExAppAd;
export const showSpecificInternalAdvertisement = (state) => state.staff.dataSpecificInAd;
export const showSpecificExternalAdvertisement = (state) => state.staff.dataSpecificExAd;
export const showPostInternalAdvertisement = (state) => state.staff.dataPostInAd;
export const showPostExternalAdvertisement = (state) => state.staff.dataPostExAd;
export const showUpdateInternalAdvertisement = (state) => state.staff.dataUpdateInAd;
export const showUpdateExternalAdvertisement = (state) => state.staff.dataUpdateExAd;
export const showInternalAdvertisementCount = (state) => state.staff.dataInAdCount;
export const showExternalAdvertisementCount = (state) => state.staff.dataExAdCount;
export const showExternalAppAdvertisementCount = (state) => state.staff.dataExAppAdCount;
export const showDelInAdvertisement = (state) => state.staff.resDelInAd;
export const showDelExAdvertisement = (state) => state.staff.resDelExAd;

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import statisticReducer from "../features/statisticSlice";
import inforReducer from "../features/managerSlice";
import toiletReducer from "../features/toiletManagerSlice";
import rechargeReducer from "../features/rechargeSlice";
import mapReducer from "../features/mapSlice";
import comReducer from "../features/adminSlice";
import staffReducer from "../features/staffSlice";
import scriptReducer from "../features/scriptSlice";
import districtReducer from "../features/districtSlice";
import userCheckinHistoryReducer from "../features/userCheckinHistory";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    statistic: statisticReducer,
    auth: authReducer,
    infor: inforReducer,
    toiletManager: toiletReducer,
    phoneInfor: rechargeReducer,
    map: mapReducer,
    company: comReducer,
    staff: staffReducer,
    script: scriptReducer,
    district: districtReducer,
    userCheckinHistory: userCheckinHistoryReducer,
  },
});

import { Col, Row } from "antd";
import React from "react";
import "./Config.scss";
import Ava from "../../../asset/Toilet_Image.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getAccountInfor, showInfor } from "../../../features/managerSlice";
import { useEffect } from "react";

function ConfigInfor() {
  const dispatch = useDispatch();
  const infor = useSelector(showInfor);

  //get data API
  useEffect(() => {
    dispatch(getAccountInfor());
  }, []);

  return (
    <div>
      <div className="config-infor-container">
        <div className="config-infor-ava-container">
          <img
            className="config-infor-ava"
            src={infor.logo}
            alt="Hình đại diện nhà vệ sinh"
          />
        </div>
        <div className="config-infor-toilet-container">
          <div className="config-infor-toilet">
            <p>Tên nhà vệ sinh</p>
            <p>{infor.name}</p>
          </div>
          <div className="config-infor-toilet">
            <p>Địa chỉ</p>
            <p>{infor.address}</p>
          </div>
          <div className="config-infor-toilet">
            <p>Phường/Xã</p>
            <p>{infor.ward}</p>
          </div>
          <div className="config-infor-toilet">
            <p>Quận/Huyện</p>
            <p>{infor.district}</p>
          </div>
          <div className="config-infor-toilet">
            <p>Tỉnh/Thành phố</p>
            <p>{infor.province}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigInfor;

import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import Menu from "../../../components/menu/Menu";
import TagHeader from "../../../components/reuse/TagHeader";
import { Outlet, useNavigate } from "react-router-dom";
import ConfigPackagePrice from "./ConfigPackagePrice";
import ConfigServicePrice from "./ConfigServicePrice";
import ConfigFacility from "./ConfigFacility";
import ConfigDiscount from "./ConfigDiscount";
import "./ConfigToiletUnit.scss";
import ConfigTag from "./ConfigTag";
import ConfigBanWord from "./ConfigBanWord";
import Header from "../../../components/reuse/Header";
import ConfigToiletType from "./ConfigToiletType";
import { TITLE8 } from "../../../Constant";

function ConfigToiletUnit() {
  const navigate = useNavigate();
  const [screenSize, getDimension] = useState(window.innerWidth);
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  //set screen
  const setDimension = () => {
    getDimension(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //handle document title
  useEffect(() => {
    document.title = TITLE8;
  }, [])

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleConfigSystem="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="config-toilet-unit-container">
            {screenSize <= 1024 ? (
              <>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <ConfigServicePrice />
                  </Col>
                  <Col span={24}>
                    <ConfigToiletType />
                  </Col>
                  <Col span={24}>
                    <ConfigFacility />
                  </Col>
                  <Col span={24}>
                    <ConfigTag />
                  </Col>
                  <Col span={24}>
                    <ConfigPackagePrice />
                  </Col>
                  <Col span={24}>
                    <ConfigBanWord />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={[24, 24]}>
                  <Col span={16}>
                    <ConfigServicePrice />
                  </Col>
                  <Col span={8}>
                    <ConfigToiletType />
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={12}>
                    <ConfigFacility />
                  </Col>
                  <Col span={12}>
                    <ConfigTag />
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={16}>
                    <ConfigPackagePrice />
                  </Col>
                  <Col span={8}>
                    <ConfigBanWord />
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ConfigToiletUnit;

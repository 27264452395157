import { Col, Row, Table } from "antd";
import React from "react";
import Menu from "../../../../components/menu/Menu";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import TagHeader from "../../../../components/reuse/TagHeader";
import ReportEachToiletUnit from "./ReportEachToiletUnit";
import AccountEachToiletUnit from "./AccountEachToiletUnit";
import InforEachToiletUnit from "./InforEachToiletUnit";
import { useLocation, useNavigate } from "react-router-dom";
import ReportLocationManageByAdmin from "./ReportLocationManageByAdmin";
import ManageToiletOfCompany from "./ManageToiletOfCompany";

function ManageEachToiletUnit() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <TagHeader
            displayRightButton="none"
            label1="Thông tin chung"
            // label2="Báo cáo"
            label2="Quản lý danh sách nhà vệ sinh"
            children1={<InforEachToiletUnit />}
            // children2={<ReportLocationManageByAdmin />}
            children2={<ManageToiletOfCompany />}
            title={
              <Breadcrumbs
                onRemove={() => navigate(-1)}
                title="Quản lý đơn vị vệ sinh"
                pageName={`${state.name}`}
              />
            }
          />
        </Col>
      </Row>
    </div>
  );
}

export default ManageEachToiletUnit;

import { Icon } from "@iconify/react";
import { Button, Col, Input, Row, Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSensitiveWord,
  getSensitiveWord,
  getSensitiveWordById,
  getSensitiveWordCount,
  postSensitiveWord,
  showDelSenWord,
  showNewSenWord,
  showSensitiveWord,
  showSensitiveWordById,
  showSensitiveWordCount,
  showUpdateSenWord,
  updateSensitiveWord,
} from "../../../features/adminSlice";
import { useEffect } from "react";
import { useState } from "react";
import ModalReuse from "../../../components/reuse/ModalReuse";
import swal from "sweetalert";

//Nếu từ ngữ ban được gỡ thì những comment bị ban trước đó sẽ ntn --> giải pháp
function ConfigBanWord() {
  const dispatch = useDispatch();
  const wordData = useSelector(showSensitiveWord);
  const wordDataById = useSelector(showSensitiveWordById);
  const wordDataCount = useSelector(showSensitiveWordCount);
  const newWord = useSelector(showNewSenWord);
  const delWord = useSelector(showDelSenWord);
  const updateWord = useSelector(showUpdateSenWord);
  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;
  const [current, setCurrent] = useState(1);
  const [openNewWord, setOpenNewWord] = useState(false);
  const [newSenWord, setNewSenWord] = useState("");
  const [openUpdateWord, setOpenUpdateWord] = useState(false);
  const [updateNewSenWord, setUpdateNewSenWord] = useState("");

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "6%",
      align: "center",
    },
    {
      title: "Từ nhạy cảm",
      dataIndex: "word",
      width: "50%",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            style={{ cursor: "pointer" }}
            onClick={() => handleUpdateSenWord(item)}
            color="#0B79D9"
            icon="material-symbols:edit"
          />
        );
      },
      width: "6%",
      align: "right",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelSenWord(item)}
            icon="material-symbols:delete"
          />
        );
      },
      width: "6%",
      align: "right",
    },
  ];

  //Actions Sensitive word
  useEffect(() => {
    dispatch(getSensitiveWord(current));
    dispatch(getSensitiveWordCount());
  }, [current, newWord, delWord, updateWord]);

  useEffect(() => {
    if (newWord.status === 201) {
      setOpenNewWord(false);
      setNewSenWord("");
    }
  }, [newWord]);

  const handleOkNewWord = () => {
    const dataNewWord = {
      word: newSenWord,
    };
    dispatch(postSensitiveWord(dataNewWord));
  };

  const handleDelSenWord = (id) => {
    swal({
      title: "Xác nhận",
      text: "Bạn có chắc chắn muốn xóa từ nhạy cảm này không?",
      icon: "warning",
      buttons: ["Hủy", "Xác nhận"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteSensitiveWord(id));
      } else {
      }
    });
  };

  const handleUpdateSenWord = (id) => {
    dispatch(getSensitiveWordById(id));
    setOpenUpdateWord(true);
  };

  useEffect(() => {
    setUpdateNewSenWord(wordDataById.word);
  }, [wordDataById]);

  //----------------------------------

  //Pagination navigate to other page
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  //handle modal new sen word
  const showModalNewWord = () => {
    setOpenNewWord(true);
  };

  const handleCancelNewWord = () => {
    setOpenNewWord(false);
  };

  const handleOkUpdateWord = () => {
    const dataUpdateWord = {
      id: wordDataById.id,
      word: updateNewSenWord,
    };
    dispatch(updateSensitiveWord(dataUpdateWord));
    setOpenUpdateWord(false);
  };

  const handleCancelUpdateWord = () => {
    setOpenUpdateWord(false);
  };

  return (
    <div className="config-header" style={{ marginTop: "8%" }}>
      <p>Từ nhạy cảm</p>
      <div className="config-container" style={{ marginTop: "4%" }}>
        <div className="config-add-new-more-row">
          <p></p>
          <div className="config-add-new-button-container" style={{ marginTop: "4%" }}>
            <Button onClick={showModalNewWord} type="primary">
              Thêm từ
            </Button>
          </div>
        </div>
        <Table
          bordered
          size="small"
          className="table"
          pagination={{
            current: current,
            showTotal: showTotal,
            onChange: onChange,
            total: wordDataCount,
            pageSize: 8,
            position: ["bottomCenter"],
          }}
          columns={columns}
          dataSource={
            wordData !== undefined
              ? wordData.map((item, index) => {
                  return {
                    id: item.id,
                    word: item.word,
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 8 + (index + 1),
                  };
                })
              : ""
          }
        />
        {/* Modal add new*/}
        <ModalReuse
          open={openNewWord}
          title="Thêm từ nhạy cảm mới"
          handleOk={handleOkNewWord}
          handleCancel={handleCancelNewWord}
          width={400}
          titleOk="Tạo mới"
          children={
            <div className="config-change-sen-word-container">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label>Từ nhạy cảm</label>
                  <Input
                    value={newSenWord}
                    onChange={(e) => setNewSenWord(e.target.value)}
                  />
                </Col>
              </Row>
            </div>
          }
        />

        {/* Modal update*/}
        <ModalReuse
          open={openUpdateWord}
          title="Cập nhật từ nhạy cảm"
          handleOk={handleOkUpdateWord}
          handleCancel={handleCancelUpdateWord}
          width={400}
          titleOk="Cập nhật"
          children={
            <div className="config-change-sen-word-container">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label>Từ nhạy cảm</label>
                  <Input
                    value={updateNewSenWord}
                    onChange={(e) => setUpdateNewSenWord(e.target.value)}
                  />
                </Col>
              </Row>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ConfigBanWord;

import { Col, Row } from "antd";
import React from "react";
import Menu from "../../../components/menu/Menu";
import TagHeader from "../../../components/reuse/TagHeader";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getExternalAdvertisementById,
  showSpecificExternalAdvertisement,
} from "../../../features/staffSlice";
import Breadcrumbs from "../../../components/reuse/Breadcrumbs";

function ManageExAdvertisementAppDetail() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showData = useSelector(showSpecificExternalAdvertisement);

  console.log(showData)

  useEffect(() => {
    dispatch(getExternalAdvertisementById(state.id));
  }, []);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleAdvertise="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <TagHeader
            displayRightButton="none"
            header="header-line"
            title="Quản lý quảng cáo"
            display="none"
            displaySearch="none"
            label1="Quảng cáo khuyến mãi"
            label2="Quảng cáo website"
            label3="Quảng cáo ứng dụng"
            defaultActiveKey="3"
            disabled1={true}
            disabled2={true}
            children3={
              <div>
                <Breadcrumbs onRemove={() => navigate(-1)} pageName="Thông tin quảng cáo" />
                <div className="manage-ads-details-container">
                  <div>
                    {showData !== {} ? (
                      <img
                        className="manage-ad-detail-img"
                        src={showData.imageSource}
                        alt="Hình quảng cáo"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div span={17}>
                    {showData !== {} ? (
                      <div>
                        <div className="manage-ad-detail-content">
                          <p>Tiêu đề</p>
                          <p>{showData.title}</p>
                        </div>
                        <div className="manage-ad-detail-content">
                          <p>Mô tả</p>
                          <p>{showData.description}</p>
                        </div>
                        <div className="manage-ad-detail-content">
                          <p>Liên kết</p>
                          <p>{showData.url}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
    </div>
  );
}

export default ManageExAdvertisementAppDetail;

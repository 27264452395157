import React, { useEffect, useState } from "react";
import ToiletImage from "../../../../asset/Toilet_Image.jpg";
import "../ManageToilet.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Col, Row, Steps, Tag } from "antd";
import Menu from "../../../../components/menu/Menu";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  postNewToilet,
  showDataPostNewToilet,
  updateRecommend,
  resetState,
} from "../../../../features/toiletManagerSlice";
import { deleteAddress } from "../../../../features/mapSlice";
import ScrollContainer from "react-indiana-drag-scroll";
import swal from "sweetalert";

function CreateNewApprove() {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const navigate = useNavigate();
  const showSuccess = useSelector(showDataPostNewToilet);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const dataInfor = JSON.parse(localStorage.getItem("inforData"));
  const dataConfig = JSON.parse(localStorage.getItem("configData"));
  const dataAccount = JSON.parse(localStorage.getItem("accountData"));

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //handle confirm update recommend
  const handleUpdateRecommend = (id1, id2, accepted) => {
    const data = {
      sugId1: id1,
      sugId2: id2,
      accepted: accepted,
    };
    dispatch(updateRecommend(data));
  };

  const handlePostToilet = () => {
    const dataApprove = {
      companyId: Number(localStorage.getItem("comId")),
      username: dataAccount.username,
      password: dataAccount.password,
      name: dataInfor.toiletName,
      address: dataInfor.street,
      ward: dataInfor.commune,
      district: dataInfor.district,
      province: dataInfor.province,
      latitude: dataInfor.lat,
      longitude: dataInfor.long,
      openTime: dataConfig.startTime,
      closeTime: dataConfig.endTime,
      status: dataConfig.status,
      nearBy: dataConfig.nearBy,
      toiletImages: dataInfor.imgArr,
      toiletFacilities: dataConfig.facilityTags,
      free: false,
    };
    dispatch(postNewToilet(dataApprove));
  };

  const handlePostToiletSuggestion = () => {
    const dataApprove = {
      companyId: Number(localStorage.getItem("comId")),
      username: dataAccount.username,
      password: dataAccount.password,
      name: dataInfor.toiletName,
      address: dataInfor.street,
      ward: dataInfor.commune,
      district: dataInfor.district,
      province: dataInfor.province,
      latitude: dataInfor.lat,
      longitude: dataInfor.long,
      openTime: dataConfig.startTime,
      closeTime: dataConfig.endTime,
      status: dataConfig.status,
      nearBy: dataConfig.nearBy,
      toiletImages: dataInfor.imgArr,
      toiletFacilities: dataConfig.facilityTags,
      free: false,
    };
    dispatch(postNewToilet(dataApprove));
    dispatch(
      handleUpdateRecommend(
        JSON.parse(localStorage.getItem("sugId")).id1,
        JSON.parse(localStorage.getItem("sugId")).id2,
        JSON.parse(localStorage.getItem("sugId")).status
      )
    );
  };

  useEffect(() => {
    if (showSuccess.status === 201) {
      localStorage.removeItem("inforData");
      localStorage.removeItem("configData");
      localStorage.removeItem("accountData");
      localStorage.removeItem("sugId");
      dispatch(deleteAddress());
      dispatch(resetState());
      navigate("/manage");
    }
  }, [showSuccess]);

  console.log(dataConfig.facilityTagsToShow);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="create-new-toilet-box-container">
            <Breadcrumbs
              title="Quản lý nhà vệ sinh"
              pageName="Tạo nhà vệ sinh mới"
              onRemove={() => navigate(-1)}
            />
            <div className="create-new-toilet-step-container">
              {screenSize > 1440 ? (
                <Steps
                  current={3}
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : screenSize <= 1440 && screenSize > 1024 ? (
                <Steps
                  current={3}
                  size="small"
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : (
                <Steps
                  current={3}
                  size="small"
                  direction="vertical"
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              )}
              <div style={{ marginRight: "16px" }}>
                <div className="create-new-approve-container">
                  <div>
                    <div className="create-new-approve-toilet">
                      <p>Tên nhà vệ sinh</p>
                      <p>{dataInfor.toiletName}</p>
                    </div>
                    <div className="create-new-approve-toilet">
                      <p>Tài khoản</p>
                      <p>{dataAccount.username}</p>
                    </div>
                    <div className="create-new-approve-toilet">
                      <p>Địa chỉ</p>
                      <p>{dataInfor.street}</p>
                    </div>
                    <div className="create-new-approve-toilet">
                      <p>Phường/Xã</p>
                      <p>{dataInfor.commune}</p>
                    </div>
                    <div className="create-new-approve-toilet">
                      <p>Quận/Huyện</p>
                      <p>{dataInfor.district}</p>
                    </div>
                    <div className="create-new-approve-toilet">
                      <p>Tỉnh/Thành phố</p>
                      <p>{dataInfor.province}</p>
                    </div>
                    <div className="create-new-approve-toilet">
                      <p>Hình ảnh</p>
                      <div className="create-new-approve-toilet-image-container">
                        <ScrollContainer>
                          {dataInfor.imgArr.length !== 0
                            ? dataInfor.imgArr.map((item) => {
                                return (
                                  <img
                                    className="create-new-approve-toilet-image"
                                    src={item}
                                    alt=""
                                    srcset=""
                                  />
                                );
                              })
                            : ""}
                        </ScrollContainer>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="create-new-approve-config-toilet">
                      <p>Giờ mở cửa</p>
                      <p>
                        {dataConfig.startTime} - {dataConfig.endTime}
                      </p>
                    </div>
                    {dataConfig.toiletTags.map((item) => {
                      return (
                        <div className="create-new-approve-config-toilet">
                          <p>{item.name}</p>
                          <p>{item.quantity} phòng</p>
                        </div>
                      );
                    })}
                    <div className="create-new-approve-config-toilet">
                      <p>Tiện ích bổ sung</p>
                      <p style={{ lineHeight: "28px" }}>
                        {dataConfig.facilityTagsToShow.length !== 0
                          ? dataConfig.facilityTagsToShow.map((item) => (
                              <Tag color="blue">{item}</Tag>
                            ))
                          : "Không có"}
                      </p>
                    </div>
                    <div className="create-new-approve-config-toilet">
                      <p>Tiện ích gần đây</p>
                      <p>
                        {dataConfig.nearBy === "" ? "Không có" : dataConfig.nearBy}
                      </p>
                    </div>
                    <div className="create-new-approve-config-toilet">
                      <p>Thiết lập nhà vệ sinh</p>
                      <p>
                        {dataConfig.free === false ? "Tính phí" : "Miễn phí"}
                      </p>
                    </div>
                    <div className="create-new-approve-config-toilet">
                      <p>Trạng thái hoạt động</p>
                      <p>{dataConfig.status}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="create-new-toilet-footer-container"
                  style={{ marginBottom: "16px" }}
                >
                  <Button
                    type="default"
                    style={{ marginRight: "1%" }}
                    onClick={() => navigate(-1)}
                  >
                    Trở về
                  </Button>
                  <Button
                    type="primary"
                    onClick={
                      localStorage.getItem("sugId") !== null
                        ? () => handlePostToiletSuggestion()
                        : () => handlePostToilet()
                    }
                  >
                    Xác nhận
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateNewApprove;

import { Button, Checkbox, Col, Input, Row, Space, Switch, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import "./ManageAdvertisement.scss";

function CreateNewAdvertisement(props) {
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <label for="inputPic">
            {props.imgUrl !== null ? (
              <img className="create-new-ad-pic-upload" src={props.imgUrl} alt="" />
            ) : (
              <div className="create-new-ad-pic-upload-empty">
                <UploadOutlined className="create-new-ad-pic-upload-empty-icon" />
                <p>Tải ảnh</p>
              </div>
            )}
            <Input
              id="inputPic"
              className="create-new-toilet-unit-image-right-container"
              type="file"
              accept="image/png, image/jpeg"
              onChange={props.upload}
            />
          </label>
        </Col>
        <Col span={14}>
          <div className="create-new-ad-content-container">
            <div className="create-new-ad-content"> 
              <p>Tiêu đề<span style={{ color: "red" }}> *</span></p>
              <Input value={props.title} onChange={props.onChangeTitle} placeholder="Nhập tiêu đề" required/>
            </div>
            <div className="create-new-ad-content">
              <p>Mô tả<span style={{ color: "red" }}> *</span></p>
              <Input.TextArea value={props.description} onChange={props.onChangeDescription} placeholder="Nhập mô tả" required/>
            </div>
            <div className="create-new-ad-content">
              <p>Liên kết<span style={{ color: "red" }}> *</span></p>
              <Input value={props.url} onChange={props.onChangeUrl} placeholder="Thêm liên kết" required/>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateNewAdvertisement;

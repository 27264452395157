import { Col, Row, Table } from "antd";
import React from "react";
import Menu from "../../../../components/menu/Menu";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import TagHeader from "../../../../components/reuse/TagHeader";
import ReportEachToilet from "./ReportEachToilet";
import AccountEachToilet from "./AccountEachToilet";
import InforEachToilet from "./InforEachToilet";
import ConfigEachToilet from "./ConfigEachToilet";
import RatingEachToilet from "./RatingEachToilet";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getToiletInfor,
  showToiletConfigUpdate,
  showToiletInfor,
  showToiletInforUpdate,
  updateToiletInfor,
} from "../../../../features/toiletManagerSlice";
import ReportLocation from "../../report/ReportLocation";

function ManageEachToilet() {
  const { state } = useLocation();
  const toiletInfor = useSelector(showToiletInfor);
  const toiletInforUpdate = useSelector(showToiletInforUpdate);
  const toiletConfigUpdate = useSelector(showToiletConfigUpdate);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //get data API
  useEffect(() => {
    dispatch(getToiletInfor(state.id));
  }, [toiletInforUpdate, toiletConfigUpdate]);

  return (
    <div className="container">
      {toiletInfor !== undefined ? (
        <Row>
          <Col span={4}>
            <Menu styleManage="menu-content-link-active" />
          </Col>
          <Col span={19}>
            <TagHeader
              label1="Báo cáo thống kê"
              label2="Tài Khoản"
              label3="Thông tin chung"
              label4="Thiết lập nhà vệ sinh"
              label5="Đánh giá"
              // label6="Báo cáo"
              displayRightButton='none'
              children1={<ReportEachToilet />}
              children2={<AccountEachToilet username={toiletInfor.username} />}
              children3={
                <InforEachToilet
                  id={state.id}
                  toiletName={toiletInfor.toiletName}
                  address={toiletInfor.address}
                  ward={toiletInfor.ward}
                  district={toiletInfor.district}
                  province={toiletInfor.province}
                  img={toiletInfor.toiletImageSources}
                />
              }
              children4={
                <ConfigEachToilet
                  id={state.id}
                  toiletFacilities={toiletInfor.toiletFacilities}
                  openTime={toiletInfor.openTime}
                  closeTime={toiletInfor.closeTime}
                  free={toiletInfor.free}
                  status={toiletInfor.status}
                  nearBy={toiletInfor.nearBy}
                />
              }
              children5={<RatingEachToilet rating={toiletInfor.ratingStar} />}
              // children6={<ReportLocation />}
              title={
                <Breadcrumbs
                  title="Quản lý nhà vệ sinh"
                  pageName={toiletInfor.toiletName}
                  onRemove={() => navigate(-1)}
                />
              }
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={4}>
            <Menu styleManage="menu-content-link-active" />
          </Col>
          <Col span={19}>
            <TagHeader
              label1="Báo cáo thống kê"
              label2="Tài Khoản"
              label3="Thông tin chung"
              label4="Thiết lập nhà vệ sinh"
              label5="Đánh giá"
              label6="Báo cáo"
              displayRightButton='none'
              children1={<ReportEachToilet />}
              children2={<AccountEachToilet username="Chưa có dữ liệu" />}
              children3={
                <InforEachToilet
                  toiletName="Chưa có dữ liệu"
                  address="Chưa có dữ liệu"
                  ward="Chưa có dữ liệu"
                  district="Chưa có dữ liệu"
                  province="Chưa có dữ liệu"
                />
              }
              children4={
                <ConfigEachToilet
                  openTime="Chưa có dữ liệu"
                  closeTime="Chưa có dữ liệu"
                  status="Chưa có dữ liệu"
                />
              }
              children5={<RatingEachToilet />}
              title={
                <Breadcrumbs
                  title="Quản lý nhà vệ sinh"
                  pageName="Chưa có dữ liệu"
                />
              }
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ManageEachToilet;

import { Button, Checkbox, Radio, Select, Space, Tag, TimePicker } from "antd";
import React from "react";
import { useState } from "react";
import dayjs from "dayjs";
import NumericInput from "../../../../components/reuse/NumericInput";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFacility,
  getToiletType,
  showFacility,
  showToiletConfigUpdate,
  showToiletType,
  updateToiletConfig,
} from "../../../../features/toiletManagerSlice";
import { REG } from "../../../../Constant";

function ConfigEachToilet(props) {
  const [changePass, setChangePass] = useState(false);
  const [startTime, setStartTime] = useState(props.openTime);
  const [endTime, setEndTime] = useState(props.closeTime);
  const [value, setValue] = useState(false);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [optionsToilet, setOptionsToilet] = useState([]);
  const [free, setFree] = useState(false);
  const facility = useSelector(showFacility);
  const toiletType = useSelector(showToiletType);
  const [facilityTags, setFacilityTags] = useState([]);
  const [facilityTagsToShow, setFacilityTagsToShow] = useState([]);
  const [status, setStatus] = useState(props.status);
  const [dataConfig, setDataConfig] = useState({
    openTime: startTime,
    closeTime: endTime,
    toiletFacilitiesById: [],
    isFree: value,
    status: "",
  });

  const format = "HH:mm";

  //actions
  const onChange = (e) => {
    if (e.target.checked) {
      setStartTime("00:00");
      setEndTime("23:59");
    } else {
      setStartTime(props.openTime);
      setEndTime(props.closeTime);
    }
  };

  const onChangeStatus = (e) => {
    if (e.target.checked) {
      setStatus("Đang hoạt động");
    } else {
      setStatus("Không hoạt động");
    }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setStartTime(dateStrings[0]);
      setEndTime(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setDataConfig({
      id: props.id,
      openTime: startTime,
      closeTime: endTime,
      toiletFacilitiesById: facilityTags.concat(
        optionsToilet.map((item) => {
          return {
            facilityId: item.facilityId,
            quantity: item.quantity,
          };
        })
      ),
      isFree: value,
      status: status,
    });
  }, [startTime, endTime, facilityTags, value, status]);

  const handleUpdateConfig = () => {
    const data = dataConfig;
    dispatch(updateToiletConfig(data));
    setChangePass(false);
  };

  const handleChange = (value, options) => {
    setFacilityTagsToShow(
      options.map((item) => {
        return item.label;
      })
    );
    const newValue = value.map((item) => {
      return { facilityId: item, quantity: 1 };
    });
    setFacilityTags(newValue);
  };

  useEffect(() => {
    dispatch(getFacility("Trang thiết bị"));
    dispatch(getToiletType("Phòng"));
  }, []);

  useEffect(() => {
    if (facility.length !== 0) {
      setOptions(
        facility.map((item, index) => ({
          label: `${item.name}`,
          value: item.id,
        }))
      );
    }
  }, [facility]);

  useEffect(() => {
    if (toiletType.length !== 0) {
      setOptionsToilet(
        toiletType.map((item, index) => ({
          facilityId: item.id,
          quantity: item.quantity,
          name: item.name,
        }))
      );
    }
  }, [toiletType]);

  const handleToilet = (e, facilityId) => {
    var result = [...optionsToilet];
    result = result.map((x) => {
      //<- use map on result to find element to update using id
      if (x.facilityId === facilityId) x.quantity = Number(e.target.value);
      return x;
    });
    setOptionsToilet(result); //<- update toilet with value edited
  };

  return (
    <div className="config-each-toilet-container">
      {changePass ? (
        <div>
          <div className="config-each-toilet-input-container">
            <p>Giờ mở cửa</p>
            <TimePicker.RangePicker
              value={[dayjs(startTime, format), dayjs(endTime, format)]}
              onChange={onRangeChange}
              format={format}
              className="config-each-toilet-input"
            />
          </div>
          <div className="config-each-toilet-input-container">
            <p></p>
            <Checkbox
              className="config-each-toilet-checkbox"
              onChange={onChange}
            >
              Mở cửa cả ngày
            </Checkbox>
          </div>
          {optionsToilet.length !== 0
            ? optionsToilet.map((item, index) => {
                return (
                  <div
                    key={item.facilityId}
                    className="config-each-toilet-input-container"
                  >
                    <p>{item.name}</p>
                    <NumericInput
                      textAlign="right"
                      // value={toiletRoom}
                      // handleChange={(e) =>
                      //   REG.test(e.target.value)
                      //     ? setToiletRoom(e.target.value)
                      //     : setToiletRoom("")
                      // }
                      value={item.quantity}
                      handleChange={(e) =>
                        REG.test(e.target.value)
                          ? handleToilet(e, item.facilityId)
                          : ""
                      }
                      addon="Phòng"
                      className="config-each-toilet-input-number"
                      placeholder="0-99"
                      maxLength="2"
                    />
                  </div>
                );
              })
            : ""}
          <div className="config-each-toilet-input-container">
            <p>Tiện ích bổ sung</p>
            <Space
              className="config-each-toilet-input-tag"
              direction="vertical"
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Chọn tiện ích"
                onChange={handleChange}
                options={options}
              />
            </Space>
          </div>
          <div className="config-each-toilet-input-container">
            <p>Thiết lập nhà vệ sinh</p>
            <Radio.Group onChange={onChangeValue} value={value}>
              <Radio value={true}>Tính phí</Radio>
              <Radio value={false}>Miễn phí</Radio>
            </Radio.Group>
          </div>
          <div className="config-each-toilet-input-container">
            <p>Trạng thái</p>
            {status === "Đang hoạt động" ? (
              <Checkbox checked onChange={onChangeStatus}>
                Đang hoạt động
              </Checkbox>
            ) : (
              <Checkbox onChange={onChangeStatus}>Đang hoạt động</Checkbox>
            )}
          </div>
          <div className="config-each-toilet-input-btn-container">
            <Button
              onClick={() => setChangePass(false)}
              className="config-each-toilet-save-btn"
            >
              Trở về
            </Button>
            <Button
              onClick={() => handleUpdateConfig()}
              className="config-each-toilet-save-btn"
              type="primary"
            >
              Lưu
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="config-each-toilet">
            <p>Giờ mở cửa</p>
            <p>
              {props.openTime} - {props.closeTime}
            </p>
          </div>
          {props.toiletFacilities !== undefined
            ? props.toiletFacilities.map((item) =>
                item.quantity !== 0 && item.facilityType === "Phòng" ? (
                  <div className="config-each-toilet">
                    <p>{item.facilityName}</p>
                    <p>{item.quantity} phòng</p>
                  </div>
                ) : (
                  ""
                )
              )
            : ""}

          <div className="config-each-toilet">
            <p>Thiết bị vệ sinh</p>
            <p style={{lineHeight: '32px'}}>
              {props.toiletFacilities !== undefined
                ? props.toiletFacilities.map((item) =>
                    item.quantity !== 0 &&
                    item.facilityType === "Trang thiết bị" ? (
                      <Tag color="blue">{item.facilityName}</Tag>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </p>
          </div>
          {/* <div className="config-each-toilet">
            <p>Tiện ích bổ sung</p>
            <p>{props.bathRoomForDisability}</p>
          </div> */}
          <div className="config-each-toilet">
            <p>Tiện ích xung quanh</p>
            <p>{props.nearBy === "" ? "Không có" : props.nearBy}</p>
          </div>
          <div className="config-each-toilet">
            <p>Thiết lập nhà vệ sinh</p>
            {props.free ? <p>Miễn phí</p> : <p>Tính phí</p>}
          </div>
          <div className="config-each-toilet">
            <p>Trạng thái</p>
            <p>{props.status}</p>
          </div>
          <Button
            onClick={() => setChangePass(true)}
            className="config-each-toilet-btn"
            type="primary"
          >
            Chỉnh sửa
          </Button>
        </div>
      )}
    </div>
  );
}

export default ConfigEachToilet;

import {
  AutoComplete,
  Button,
  Col,
  Input,
  Row,
  Space,
  Steps,
  Upload,
  message,
} from "antd";
import React from "react";
import { useState } from "react";
import "@goongmaps/goong-js/dist/goong-js.css";
import { CloseCircleFilled } from "@ant-design/icons";
import ReactMapGL, {
  FlyToInterpolator,
  Marker,
} from "@goongmaps/goong-map-react";
import { MdLocationPin } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddressByAutocomplete,
  getAutocompleteMap,
  showAddressBySearch,
  showGetApiMap,
} from "../../../../features/mapSlice";
import { useEffect } from "react";
import "@goongmaps/goong-geocoder-react/dist/goong-geocoder.css";
import TextArea from "antd/es/input/TextArea";
import Menu from "../../../../components/menu/Menu";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { storage } from "../../../../firebaseConfig.js";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import ScrollContainer from "react-indiana-drag-scroll";

const REACT_APP_GOONG_ACCESS_TOKEN = "cwSBYZP1nDTuYdMb1UM1nDniqkBnevyJVCFaQad0";
// function CreateNewInfor( {toiletName, street, latitude, longitude, commune, district, province} ) {
function CreateNewInfor() {
  const { state } = useLocation();
  const [current, setCurrent] = useState(0);
  const [screenSize, getDimension] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [toiletName, setToiletName] = useState(
    localStorage.getItem("inforData") !== null
      ? JSON.parse(localStorage.getItem("inforData")).toiletName !== undefined
        ? JSON.parse(localStorage.getItem("inforData")).toiletName
        : ""
      : ""
  );
  const [options, setOptions] = useState([]);
  const showAddress = useSelector(showGetApiMap);
  const address = useSelector(showAddressBySearch);
  const [street, setStreet] = useState(
    localStorage.getItem("inforData") !== null
      ? JSON.parse(localStorage.getItem("inforData")).address !== undefined
        ? JSON.parse(localStorage.getItem("inforData")).address
        : JSON.parse(localStorage.getItem("inforData")).street
      : ""
  );
  const [latitude, setLatitude] = useState(
    localStorage.getItem("inforData") !== null
      ? JSON.parse(localStorage.getItem("inforData")).latitude !== undefined
        ? JSON.parse(localStorage.getItem("inforData")).latitude
        : JSON.parse(localStorage.getItem("inforData")).lat
      : 10.7447788
  );
  const [longitude, setLongitude] = useState(
    localStorage.getItem("inforData") !== null
      ? JSON.parse(localStorage.getItem("inforData")).longitude !== undefined
        ? JSON.parse(localStorage.getItem("inforData")).longitude
        : JSON.parse(localStorage.getItem("inforData")).long
      : 106.6355914
  );
  const [commune, setCommune] = useState(
    localStorage.getItem("inforData") !== null
      ? JSON.parse(localStorage.getItem("inforData")).ward !== undefined
        ? JSON.parse(localStorage.getItem("inforData")).ward
        : JSON.parse(localStorage.getItem("inforData")).commune
      : ""
  );
  const [district, setDistrict] = useState(
    localStorage.getItem("inforData") !== null
      ? JSON.parse(localStorage.getItem("inforData")).district
      : ""
  );
  const [province, setProvince] = useState(
    localStorage.getItem("inforData") !== null
      ? JSON.parse(localStorage.getItem("inforData")).province
      : ""
  );
  const [imgUrl, setImgUrl] = useState("");
  const [imgUrlArr, setImgUrlArr] = useState(
    localStorage.getItem("inforData") !== null
      ? JSON.parse(localStorage.getItem("inforData")).imgArr !== undefined
        ? JSON.parse(localStorage.getItem("inforData")).imgArr
        : []
      : []
  );
  const navigate = useNavigate();
  const [percent, setPercent] = useState(0);
  const [dataInfor, setDataInfor] = useState({
    street: street,
    commune: commune,
    district: district,
    province: province,
    long: longitude,
    lat: latitude,
    toiletName: toiletName,
    imgArr: imgUrlArr,
  });

  const [viewport, setViewport] = useState({
    latitude: latitude,
    longitude: longitude,
    zoom: 16,
  });

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //render sau khi bấm
  const handleSearch = (value) => {
    let res = [];
    dispatch(getAutocompleteMap(value));
    res = showAddress.map((item) => ({
      label: `${item.description}`,
      value: `${item.structured_formatting.main_text}`,
      key: `${item.place_id}`,
    }));
    setOptions(res);
  };

  const onSelect = (value, options) => {
    dispatch(getAddressByAutocomplete(options.key));
  };

  const uploadImageAsPromise = (e) => {
    for (var i = 0; i < e.target.files.length; i++) {
      var imageFile = e.target.files[i];
      console.log(imageFile);

      const storageRef = ref(storage, `img/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      //Update progress bar
      uploadTask.on(
        "state_changed",
        function progress(snapshot) {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercent(percentage);
        },
        (error) => {
          alert(error);
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
            setImgUrlArr((prevState) => [...prevState, downloadURLs]);
          });
        }
      );
    }
  };

  const deleteImage = (url) => {
    const storage = getStorage();

    // Create a reference to the file to delete
    const desertRef = ref(storage, url);

    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        console.log("ok");
        setImgUrlArr(imgUrlArr.filter((image) => image !== url));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (address.length !== 0) {
      setLongitude(address.geometry.location.lng);
      setLatitude(address.geometry.location.lat);
      setStreet(address.name);
      setCommune(address.compound.commune);
      setDistrict(address.compound.district);
      setProvince(address.compound.province);
      setViewport({
        ...viewport,
        longitude: address.geometry.location.lng,
        latitude: address.geometry.location.lat,
        zoom: 16,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      });
      setDataInfor({
        toiletName: toiletName,
        imgArr: imgUrlArr,
        street: address.name,
        commune: address.compound.commune,
        district: address.compound.district,
        province: address.compound.province,
        long: address.geometry.location.lng,
        lat: address.geometry.location.lat,
      });
    } else if (localStorage.getItem("inforData") !== null) {
      setDataInfor({
        toiletName: toiletName,
        imgArr: imgUrlArr,
        street: street,
        commune: commune,
        district: district,
        province: province,
        long: longitude,
        lat: latitude,
      });
    }
  }, [dataInfor, address]);

  useEffect(() => {
    if (address.length === 0 && localStorage.getItem("inforData") === null) {
      setCommune("");
      setDistrict("");
      setProvince("");
      setLatitude(10.7447788);
      setLongitude(106.6355914);
    }
  }, []);

  return (
    <div className="container">
      <Row>
        <Col span={4}>
          <Menu styleManage="menu-content-link-active" />
        </Col>
        <Col span={19}>
          <div className="create-new-toilet-box-container">
            <Breadcrumbs
              title="Quản lý nhà vệ sinh"
              pageName="Tạo nhà vệ sinh mới"
              onRemove={() => navigate(-1)}
            />
            <div className="create-new-toilet-step-container">
              {screenSize > 1440 ? (
                <Steps
                  current={0}
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : screenSize <= 1440 && screenSize > 1024 ? (
                <Steps
                  current={0}
                  size="small"
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              ) : (
                <Steps
                  current={0}
                  size="small"
                  direction="vertical"
                  items={[
                    {
                      title: "Tạo thông tin",
                    },
                    {
                      title: "Tạo nhà vệ sinh",
                    },
                    {
                      title: "Tạo tài khoản",
                    },
                    {
                      title: "Xác nhận",
                    },
                  ]}
                />
              )}
              <div className="create-new-toilet-input-box-container">
                <div className="create-new-toilet-input-left-container">
                  <div className="create-new-toilet-input-container">
                    <p>
                      Tên nhà vệ sinh<span style={{ color: "red" }}> *</span>
                    </p>
                    <Input
                      value={toiletName}
                      onChange={(e) => setToiletName(e.target.value)}
                      className="create-new-toilet-input"
                      placeholder="Nhập tên nhà vệ sinh (tối đa 50 kí tự)"
                      required
                      maxLength={50}
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>
                      Địa chỉ<span style={{ color: "red" }}> *</span>
                    </p>
                    <AutoComplete
                      required
                      options={options}
                      defaultValue={street}
                      className="create-new-toilet-input"
                      onSelect={onSelect}
                      onChange={handleSearch}
                    >
                      <TextArea
                        placeholder="Nhập địa chỉ"
                        className="custom"
                        value={street}
                        style={{
                          height: 64,
                        }}
                      />
                    </AutoComplete>
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>Phường/Xã</p>
                    <Input
                      value={commune}
                      className="create-new-toilet-input"
                      disabled
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>Quận/Huyện</p>
                    <Input
                      value={district}
                      className="create-new-toilet-input"
                      disabled
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>Tỉnh/Thành phố</p>
                    <Input
                      value={province}
                      className="create-new-toilet-input"
                      disabled
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p>
                      Hình ảnh<span style={{ color: "red" }}> *</span>
                    </p>
                    <Input
                      className="create-new-toilet-image-container"
                      type="file"
                      multiple
                      accept="image/png, image/jpeg"
                      onChange={uploadImageAsPromise}
                    />
                  </div>
                  <div className="create-new-toilet-input-container">
                    <p></p>
                    <div className="create-new-toilet-image-container">
                      <ScrollContainer>
                        {imgUrlArr.length !== 0
                          ? imgUrlArr.map((item) => {
                              return (
                                <>
                                  <Space className="create-new-toilet-image-appear-container">
                                    <img
                                      className="create-new-toilet-image"
                                      src={item}
                                      alt=""
                                      srcset=""
                                    />
                                    <CloseCircleFilled
                                      onClick={() => deleteImage(item)}
                                      className="create-new-toilet-image-icon"
                                    />
                                  </Space>
                                </>
                              );
                            })
                          : ""}
                      </ScrollContainer>
                    </div>
                  </div>
                </div>
                <div className="create-new-toilet-input-right-container">
                  <ReactMapGL
                    className="create-new-toilet-map"
                    {...viewport}
                    width="100%"
                    height="100%"
                    goongApiAccessToken={REACT_APP_GOONG_ACCESS_TOKEN}
                    onViewportChange={setViewport}
                  >
                    <Marker
                      latitude={latitude}
                      longitude={longitude}
                      offsetLeft={-10}
                      offsetTop={-10}
                      draggable
                      onDragEnd={(e) => [
                        setLongitude(e.lngLat[0]),
                        setLatitude(e.lngLat[1]),
                        console.log(e.lngLat[0]),
                        console.log(e.lngLat[1]),
                      ]}
                    >
                      <MdLocationPin style={{ fontSize: 30, color: "red" }} />
                    </Marker>
                  </ReactMapGL>
                </div>
              </div>
              {toiletName !== null && imgUrlArr.length !== 0 ? (
                <div className="create-new-toilet-footer-container">
                  <Button
                    type="primary"
                    // onClick={() =>
                    //   navigate(
                    //     "/createconfigtoilet",
                    //     state !== null
                    //       ? {
                    //           state: { dataInfor: dataInfor, id: state.id },
                    //         }
                    //       : { state: { dataInfor: dataInfor } }
                    //   )
                    // }
                    onClick={() => [
                      navigate("/createconfigtoilet"),
                      localStorage.setItem(
                        "inforData",
                        JSON.stringify(dataInfor)
                      ),
                    ]}
                  >
                    Tiếp theo
                  </Button>
                </div>
              ) : (
                <div className="create-new-toilet-footer-container">
                  <Button
                    disabled
                    onClick={() => [
                      navigate("/createconfigtoilet"),
                      localStorage.setItem(
                        "inforData",
                        JSON.stringify(dataInfor)
                      ),
                    ]}
                  >
                    Tiếp theo
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateNewInfor;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AccountAPI from "../service/configApi.js";

export const getAccountInfor = createAsyncThunk("infor/getInfor", async () => {
  const response = await AccountAPI.get(
    `/companies/accounts/${localStorage.getItem("accountId")}/company`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    }
  );
  // The value we return becomes the `fulfilled` action payload
  localStorage.setItem("comId", response.data.data.id);
  return response.data.data;
});

export const inforSlice = createSlice({
  name: "infor",
  initialState: {
    data: {},
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    // --- Xử lý trong reducer với case fulfilled ---
    builder
      // .addCase(getStatisticAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getAccountInfor.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      });
  },
});

export default inforSlice.reducer;
export const showInfor = (state) => state.infor.data;
export const inforStatus = (state) => state.infor.status;

import React from "react";
import logo from "./logo.svg";
import { Routes, Route } from "react-router-dom";
import Menu from "./components/menu/Menu";
import Breadcrumbs from "./components/reuse/Breadcrumbs";
import "./App.scss";
import Statistic from "./pages/manager/statistic/Statistic";
import Login from "./pages/login/Login";
import ProtectedAdminRoute from "./ProtectedAdminRoute";
import ProtectedManagerRoute from "./ProtectedManagerRoute";
import Recharge from "./pages/manager/recharge/Recharge";
import ManageToilet from "./pages/manager/manage/ManageToilet";
import ManageEachToilet from "./pages/manager/manage/manageEachToilet/ManageEachToilet";
import CreateNewToilet from "./pages/manager/manage/createToilet/CreateNewToilet";
import Config from "./pages/manager/config/Config";
import ManageToiletUnit from "./pages/admin/manageToiletUnit/ManageToiletUnit";
import ConfigToiletUnit from "./pages/admin/configToiletUnit/ConfigToiletUnit";
import StatisticToiletUnit from "./pages/admin/statisticToiletUnit/StatisticToiletUnit";
import ConfigInforAdmin from "./pages/admin/configInfor/ConfigInforAdmin";
import CreateNewToiletUnit from "./pages/admin/manageToiletUnit/createToiletUnit/CreateNewToiletUnit";
import ManageEachToiletUnit from "./pages/admin/manageToiletUnit/manageEachToilet/ManageEachToiletUnit";
import PrintCard from "./pages/manager/card/PrintCard";
import CreateUpdatePackagePrice from "./pages/admin/configToiletUnit/CreateUpdatePackagePrice";
import CreateUpdateServicePrice from "./pages/admin/configToiletUnit/CreateUpdateServicePrice";
import CreateUpdateFacility from "./pages/admin/configToiletUnit/CreateUpdateFacility";
import CreateUpdateDiscount from "./pages/admin/configToiletUnit/CreateUpdateDiscount";
import ProtectedStaffRoute from "./ProtectedStaffRoute";
import RatingDetailsEachToilet from "./pages/manager/manage/manageEachToilet/RatingDetailsEachToilet";
import CreateNewInfor from "./pages/manager/manage/createToilet/CreateNewInfor";
import CreateNewConfig from "./pages/manager/manage/createToilet/CreateNewConfig";
import CreateNewAccount from "./pages/manager/manage/createToilet/CreateNewAccount";
import CreateNewApprove from "./pages/manager/manage/createToilet/CreateNewApprove";
import ManageRating from "./pages/manager/rating/ManageRating";
import CreateNewInforUnit from "./pages/admin/manageToiletUnit/createToiletUnit/CreateNewInforUnit";
import CreateNewAccountUnit from "./pages/admin/manageToiletUnit/createToiletUnit/CreateNewAccountUnit";
import CreateNewApproveUnit from "./pages/admin/manageToiletUnit/createToiletUnit/CreateNewApproveUnit";
import ManageAdvertisement from "./pages/admin/manageAd/ManageAdvertisement";
import CreateNewAdvertisement from "./pages/admin/manageAd/CreateNewAdvertisement";
import ManageInAppAdvertisementDetail from "./pages/admin/manageAd/ManageInAppAdvertisementDetail";
import ManageExAdvertisementDetail from "./pages/admin/manageAd/ManageExAdvertisementDetail";
import ViewAdvertisement from "./pages/manager/ads/ViewAdvertisement";
import ReportLocation from "./pages/manager/report/ReportLocation";
import Recommend from "./pages/admin/recommend/Recommend";
import Script from "./pages/admin/script/Script";
import ManageExAdvertisementAppDetail from "./pages/admin/manageAd/ManageExAdvertisementAppDetail";
import ManageCheckinToilet from "./pages/checkin/ManageCheckinToilet";
import ManageCheckinCompany from "./pages/checkin/ManageCheckinCompany";
import { UserHistory } from "./pages/manager/userHistory/UserHistory";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/" element={<ProtectedManagerRoute />}>
          <Route path="/statistic" element={<Statistic />} />
          <Route path="/user-history" element={<UserHistory />} />
          <Route path="/manage" element={<ManageToilet />} />
          <Route path="/manage/toilet/:id" element={<ManageEachToilet />} />
          <Route
            path="/manage/toilet/details/:id"
            element={<RatingDetailsEachToilet />}
          />
          <Route path="/createinfortoilet" element={<CreateNewInfor />} />
          <Route path="/createaccounttoilet" element={<CreateNewAccount />} />
          <Route path="/createconfigtoilet" element={<CreateNewConfig />} />
          <Route path="/createapprove" element={<CreateNewApprove />} />
          <Route path="/config" element={<Config />} />
          <Route path="/reportlocation" element={<ReportLocation />} />
          <Route path="/printCard" element={<PrintCard />} />
          <Route path="/viewads" element={<ViewAdvertisement />} />
          <Route path="/viewcheckintoilet" element={<ManageCheckinToilet />} />
          <Route
            path="/viewads/inapp/:id"
            element={<ManageInAppAdvertisementDetail />}
          />
          <Route
            path="/viewads/exapp/:id"
            element={<ManageExAdvertisementDetail />}
          />
          <Route
            path="/viewads/ex/:id"
            element={<ManageExAdvertisementAppDetail />}
          />
        </Route>
        <Route exact path="/" element={<ProtectedAdminRoute />}>
          <Route path="/managecompany" element={<ManageToiletUnit />} />
          <Route path="/script" element={<Script />} />
          <Route
            path="/managecompany/company/:id"
            element={<ManageEachToiletUnit />}
          />
          <Route path="/configtoiletunit" element={<ConfigToiletUnit />} />
          <Route
            path="/statistictoiletunit"
            element={<StatisticToiletUnit />}
          />
          <Route path="/viewcheckincompany" element={<ManageCheckinCompany />} />
          <Route path="/configinfor" element={<ConfigInforAdmin />} />
          <Route path="/recommend" element={<Recommend />} />
          <Route path="/createnewcompany" element={<CreateNewInforUnit />} />
          <Route path="/createnewaccount" element={<CreateNewAccountUnit />} />
          <Route
            path="/createnewapprovecompany"
            element={<CreateNewApproveUnit />}
          />
        </Route>
        <Route exact path="/" element={<ProtectedStaffRoute />}>
          <Route path="/managerating" element={<ManageRating />} />

          <Route path="/recharge" element={<Recharge />} />
          <Route
            path="/manageadvertisement"
            element={<ManageAdvertisement />}
          />
          <Route
            path="/createnewadvertisement"
            element={<CreateNewAdvertisement />}
          />
          <Route
            path="/manageadvertisement/inapp/:id"
            element={<ManageInAppAdvertisementDetail />}
          />
          <Route
            path="/manageadvertisement/exapp/:id"
            element={<ManageExAdvertisementDetail />}
          />
          <Route
            path="/manageadvertisement/ex/:id"
            element={<ManageExAdvertisementAppDetail />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  theme,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import {
  deleteFacilityType,
  getFacilityType,
  getFacilityTypeById,
  getToiletType,
  postFacilityType,
  showDelFacilityType,
  showFacilityType,
  showFacilityTypeId,
  showNewFacilityType,
  showToiletType,
  showUpdateFacilityType,
  updateFacilityType,
} from "../../../features/adminSlice";
import ModalReuse from "../../../components/reuse/ModalReuse";
import { Icon } from "@iconify/react";

function ConfigFacility() {
  const { token } = theme.useToken();
  const [tags, setTags] = useState(["Máy hong khô tay"]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const dispatch = useDispatch();
  const facilityTypeData = useSelector(showFacilityType);
  const facilityTypeDataById = useSelector(showFacilityTypeId);
  const newFacilityType = useSelector(showNewFacilityType);
  const delFacilityType = useSelector(showDelFacilityType);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openUpdateFaci, setOpenUpdateFaci] = useState(false);
  const [current, setCurrent] = useState(1);
  const [faciName, setFaciName] = useState("");
  const [updateFaciName, setUpdateFaciName] = useState("");
  const showUpdateFacility = useSelector(showUpdateFacilityType)

  const showTotal = (total, range) =>
    range[0] === range[1]
      ? `${range[0]} trong ${total}`
      : `${range[0]}-${range[1]} trong ${total}`;

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "16%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Thiết bị vệ sinh",
      dataIndex: "name",
      ellipsis: true,
      width: "50%",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            style={{ cursor: "pointer" }}
            // onClick={() =>  dispatch(deleteFacilityType(item)}
            onClick={() => handleUpdateFaci(item)}
            color="#0B79D9"
            icon="material-symbols:edit"
          />
        );
      },
      width: "6%",
      align: "right",
    },
    {
      title: "",
      dataIndex: "id",
      render: (item) => {
        return (
          <Icon
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelFaci(item)}
            icon="material-symbols:delete"
          />
        );
      },
      width: "6%",
      align: "right",
    },
  ];

  const handleUpdateFaci = (id) => {
    dispatch(getFacilityTypeById(id));
    setOpenUpdateFaci(true);
  };

  //set toilet id into state
  useEffect(() => {
    setUpdateFaciName(facilityTypeDataById.name);
  }, [facilityTypeDataById]);

  const handleDelFaci = (id) => {
    swal({
      title: "Xác nhận",
      text: "Bạn có chắc chắn muốn xóa thiết bị này không?",
      icon: "warning",
      buttons: ["Hủy", "Xác nhận"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteFacilityType(id));
      } else {
      }
    });
  };

  //get Toilet type
  useEffect(() => {
    dispatch(getFacilityType("Trang thiết bị"));
  }, [newFacilityType, delFacilityType, showUpdateFacility]);

  //handle modal change status
  const showModalChangeStatus = () => {
    setOpenChangeStatus(true);
  };

  const handleOkChangeStatus = () => {
    const data = {
      name: faciName,
      type: "Trang thiết bị",
    };
    dispatch(postFacilityType(data));
    setOpenChangeStatus(false);
  };

  //handle modal update faci
  const showModalUpdateFaci = () => {
    setOpenUpdateFaci(true);
  };

  const handleOkChangeFaci = () => {
    const data = {
      id: facilityTypeDataById.id,
      name: updateFaciName,
    };
    dispatch(updateFacilityType(data));
    setOpenUpdateFaci(false);
  };

  const handleCancelChangeFaci = () => {
    setOpenUpdateFaci(false);
  };

  const handleCancelChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  return (
    <div className="config-header" style={{ marginTop: "4%" }}>
      <p>Thiết bị vệ sinh</p>
      <div className="config-container">
        <div className="config-add-new">
          <p></p>
          <div className="config-add-new-button-container">
            <Button onClick={showModalChangeStatus} type="primary">
              Thêm thiết bị
            </Button>
          </div>
        </div>
        <Table
          bordered
          className="table"
          size="small"
          pagination={{
            showTotal: showTotal,
            position: ["bottomCenter"],
          }}
          columns={columns}
          dataSource={
            facilityTypeData !== undefined
              ? facilityTypeData.map((item, index) => {
                  return {
                    id: item.id,
                    name: item.name,
                    stt:
                      current === 1
                        ? index + 1
                        : (current - 1) * 8 + (index + 1),
                  };
                })
              : ""
          }
        />
        {/* Modal create faci*/}
        <ModalReuse
          open={openChangeStatus}
          title="Tạo thiết bị mới"
          handleOk={handleOkChangeStatus}
          handleCancel={handleCancelChangeStatus}
          titleOk="Tạo mới"
          width={400}
          children={
            <div className="config-change-date-service-container">
              <Row>
                <Col span={24}>
                  <label>Thiết bị</label>
                  <Input
                    value={faciName}
                    onChange={(e) => setFaciName(e.target.value)}
                    placeholder="Nhập thiết bị"
                  />
                </Col>
              </Row>
            </div>
          }
        />

        {/* Modal update faci*/}
        <ModalReuse
          open={openUpdateFaci}
          title="Cập nhật thiết bị"
          handleOk={handleOkChangeFaci}
          handleCancel={handleCancelChangeFaci}
          titleOk="Cập nhật"
          width={400}
          children={
            <div className="config-change-date-service-container">
              <Row>
                <Col span={24}>
                  <label>Thiết bị</label>
                  <Input
                    value={updateFaciName}
                    onChange={(e) => setUpdateFaciName(e.target.value)}
                  />
                </Col>
              </Row>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ConfigFacility;

import { Input, Tooltip } from "antd";
import { useState } from "react";
const formatNumber = (value) => new Intl.NumberFormat().format(value);

function NumericInput(props) {
  
  // const handleChange = (e) => {
  //   const { value: inputValue } = e.target;
  //   console.log(inputValue)
  //   const reg = /^-?\d*(\.\d*)?$/;
  //   if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
  //     onChange(inputValue);
  //   } else {
  //   }
  // };

  return (
    <Input
      {...props}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.handleChange}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      className={props.className}
      disabled={props.disabled}
      style={{textAlign: props.textAlign}}
    />
  );
}

export default NumericInput;

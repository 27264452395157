import { AutoComplete, Button, Col, Input, Row } from "antd";
import React from "react";
import { useState } from "react";
import { Upload } from "antd";
import ToiletImage from "../../../../asset/Toilet_Image.jpg";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useEffect } from "react";
import { CameraOutlined } from "@ant-design/icons";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  getAddressByAutocomplete,
  getAutocompleteMap,
  showAddressBySearch,
  showGetApiMap,
} from "../../../../features/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { updateToiletInfor } from "../../../../features/toiletManagerSlice";

function InforEachToilet(props) {
  const [changePass, setChangePass] = useState(false);
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [options, setOptions] = useState([]);
  const showAddress = useSelector(showGetApiMap);
  const address = useSelector(showAddressBySearch);
  const dispatch = useDispatch();
  //update toilet field
  const [toiletName, setToiletName] = useState(props.toiletName);
  const [ward, setWard] = useState(props.ward);
  const [district, setDistrict] = useState(props.district);
  const [province, setProvince] = useState(props.province);
  const [street, setStreet] = useState(props.address);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [commune, setCommune] = useState("");

  //render sau khi bấm
  const handleSearch = (value) => {
    let res = [];
    dispatch(getAutocompleteMap(value));
    res = showAddress
      // .map((item) => item.description)
      .map((item) => ({
        label: `${item.description}`,
        value: `${item.structured_formatting.main_text}`,
        key: `${item.place_id}`,
      }));
    setOptions(res);
  };

  const onSelect = (value, options) => {
    dispatch(getAddressByAutocomplete(options.key));
  };

  useEffect(() => {
    if (address.length !== 0) {
      setLongitude(address.geometry.location.lng);
      setLatitude(address.geometry.location.lat);
      setStreet(address.name);
      setWard(address.compound.commune);
      setDistrict(address.compound.district);
      setProvince(address.compound.province);
    }
  }, [address]);

  const handleUpdateToilet = () => {
    const data = {
      id: props.id,
      name: toiletName,
      address: street,
      ward: ward,
      district: district,
      province: province,
      latitude: latitude,
      longitude: longitude
    }
    dispatch(updateToiletInfor(data))
    setChangePass(false);
  }

  //screen size
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <div className="infor-each-toilet-container">
      {changePass ? (
        <div>
          <div className="infor-each-toilet-input-container">
            <p>Tên nhà vệ sinh</p>
            <Input
              className="infor-each-toilet-input"
              value={toiletName}
              onChange={(e) => setToiletName(e.target.value)}
            />
          </div>
          <div className="infor-each-toilet-input-container">
            <p>Địa chỉ</p>
            <AutoComplete
              required
              options={options}
              value={street}
              className="infor-each-toilet-input"
              onSelect={onSelect}
              onChange={handleSearch}
            >
              <TextArea
                placeholder="Nhập địa chỉ"
                onChange={(e) => setStreet(e.target.value)}
                className="custom"
                style={{
                  height: 64,
                }}
              />
            </AutoComplete>
          </div>
          <div className="infor-each-toilet-input-container">
            <p>Phường/Xã</p>
            <Input
              className="infor-each-toilet-input"
              value={
                Object.keys(address).length !== 0
                  ? address.compound.commune
                  : ward
              }
              onChange={(e) => setWard(e.target.value)}
              disabled
            />
          </div>
          <div className="infor-each-toilet-input-container">
            <p>Quận/Huyện</p>
            <Input
              className="infor-each-toilet-input"
              value={
                Object.keys(address).length !== 0
                  ? address.compound.district
                  : district
              }
              onChange={(e) => setDistrict(e.target.value)}
              disabled
            />
          </div>
          <div className="infor-each-toilet-input-container">
            <p>Tỉnh/Thành phố</p>
            <Input
              className="infor-each-toilet-input"
              value={
                Object.keys(address).length !== 0
                  ? address.compound.province
                  : province
              }
              onChange={(e) => setProvince(e.target.value)}
              disabled
            />
          </div>
          <div className="infor-each-toilet-input-container">
            <p>Hình ảnh</p>
            <ScrollContainer className="infor-each-toilet-image-container">
              <div className="infor-each-toilet-image-container">
                {props.img !== null
                  ? props.img.map((item) => {
                      return (
                        <img
                          className="infor-each-toilet-image"
                          src={item}
                          alt=""
                        />
                      );
                    })
                  : ""}

                <label for="inputTag">
                  <CameraOutlined className="infor-each-toilet-add-image" />
                  <Input
                    id="inputTag"
                    className="infor-each-toilet-upload-image"
                    type="file"
                    accept="image/png, image/jpeg"
                  />
                </label>
              </div>
            </ScrollContainer>
          </div>
          <div className="infor-each-toilet-input-btn-container">
            <Button
              onClick={() => setChangePass(false)}
              className="infor-each-toilet-save-btn"
            >
              Trở về
            </Button>
            <Button
              onClick={handleUpdateToilet}
              className="infor-each-toilet-save-btn"
              type="primary"
            >
              Lưu
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {screenSize < 800 ? (
            <Row gutter={[24, 8]}>
              <Col span={24}>
                <div className="infor-each-toilet-image-container">
                  {props.img === undefined ? (
                    "Không có hình ảnh"
                  ) : (
                    <ReactImageGallery
                      items={props.img.map((item) => {
                        return {
                          original: item,
                          thumbnail: item,
                        };
                      })}
                    />
                  )}
                </div>
              </Col>
              <Col span={24}>
                <div className="infor-each-toilet">
                  <p>Tên nhà vệ sinh</p>
                  <p>{props.toiletName}</p>
                </div>
                <div className="infor-each-toilet">
                  <p>Địa chỉ</p>
                  <p>{props.address}</p>
                </div>
                <div className="infor-each-toilet">
                  <p>Phường/Xã</p>
                  <p>{props.ward}</p>
                </div>
                <div className="infor-each-toilet">
                  <p>Quận/Huyện</p>
                  <p>{props.district}</p>
                </div>
                <div className="infor-each-toilet">
                  <p>Tỉnh/Thành phố</p>
                  <p>{props.province}</p>
                </div>
              </Col>
            </Row>
          ) : (
            <Row gutter={[24, 8]}>
              <Col span={8}>
                <div className="infor-each-toilet-image-container">
                  {props.img === undefined ? (
                    "Không có hình ảnh"
                  ) : (
                    <ReactImageGallery
                      items={props.img.map((item) => {
                        return {
                          original: item,
                          thumbnail: item,
                        };
                      })}
                    />
                  )}
                </div>
              </Col>
              <Col span={16}>
                <div className="infor-each-toilet">
                  <p>Tên nhà vệ sinh</p>
                  <p>{props.toiletName}</p>
                </div>
                <div className="infor-each-toilet">
                  <p>Địa chỉ</p>
                  <p>{props.address}</p>
                </div>
                <div className="infor-each-toilet">
                  <p>Phường/Xã</p>
                  <p>{props.ward}</p>
                </div>
                <div className="infor-each-toilet">
                  <p>Quận/Huyện</p>
                  <p>{props.district}</p>
                </div>
                <div className="infor-each-toilet">
                  <p>Tỉnh/Thành phố</p>
                  <p>{props.province}</p>
                </div>
              </Col>
            </Row>
          )}
          <div className="infor-each-toilet">
            <Button
              onClick={() => setChangePass(true)}
              className="infor-each-toilet-btn"
              type="primary"
            >
              Chỉnh sửa
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default InforEachToilet;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import UserCheckinHistoryApi from "../service/configApi.js";

export const getUserCheckinHistory = createAsyncThunk(
  "userCheckinHistory/getUserCheckinHistoryData",
  async (data) => {
    const response = await UserCheckinHistoryApi.get(
      `/statistics/user-checkin-history?company-id=${data.id}&user-name=${data.userName}&from-date=${data.startDate}&to-date=${data.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );

    return response.data.data;
  }
);

export const getCountUserCheckinHistoryAdmin = createAsyncThunk(
  "userCheckinHistory/countUserCheckinHistoryAd",
  async () => {
    const response = await UserCheckinHistoryApi.get(
      `/user-checkin-history/count`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    return response.data.data;
  }
);

export const userCheckinHistorySlide = createSlice({
  name: "userCheckinHistory",
  initialState: {
    userCheckinHistoryData: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    // --- Xử lý trong reducer với case fulfilled ---
    builder
      .addCase(getUserCheckinHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserCheckinHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userCheckinHistoryData = action.payload;
      })
      .addCase(getUserCheckinHistory.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const showUserCheckinHistory = (state) =>
  state.userCheckinHistory.userCheckinHistoryData;
export const userCheckinHistoryStatus = (state) =>
  state.userCheckinHistory.status;
export default userCheckinHistorySlide.reducer;

import React from "react";
import Breadcrumbs from "../../../../components/reuse/Breadcrumbs";
import { Avatar, Button, Rate } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function RatingDetailsEachToilet(props) {
  // const dispatch = useDispatch();
  // const showRatingDetails = useSelector();

  return (
    <div>
      <Breadcrumbs
        title="Đánh giá"
        pageName="Đánh giá chi tiết"
        onRemove={props.back}
      />
      <div className="rating-details-each-toilet-container">
        {/* <img src="" alt="" /> */}
        <div>
          <Avatar size="large" icon={<UserOutlined />} />
        </div>
        <div className="rating-details-each-toilet-content-container">
          <p className="rating-details-each-toilet-name">
            {props.fullName === null ? "Ẩn danh" : props.fullName}
          </p>
          <Rate disabled defaultValue={props.star} />
          <p className="rating-details-each-toilet-date">{props.dateTime}</p>
          {props.commonComments !== undefined
            ? props.commonComments !== null
              ? props.commonComments.join(" / ")
              : ""
            : ""}
          <p className="rating-details-each-toilet-content">
            <strong>Nội dung</strong>: {props.comment}
          </p>
          <div style={{ display: "inline-block" }}>
            {props.imageSources !== undefined
              ? props.imageSources.map((item) => {
                  return (
                    <img
                      className="rating-details-each-toilet-img"
                      src={item}
                      alt=""
                    />
                  );
                })
              : ""}
          </div>
          {/* <img
            src={props.imageSources}
            alt=""
            className="rating-details-each-toilet-img"
          /> */}
          {props.status !== undefined ? (
            props.status === null ? (
              <div style={{ display: "flex" }}>
                <Button>Từ chối</Button>
                <Button type="primary" style={{ marginLeft: "8px" }}>
                  Giải quyết
                </Button>
              </div>
            ) : (
              props.status
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default RatingDetailsEachToilet;

import React from "react";
import { Button, Tabs } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./TagHeader.scss";

function TagHeader(props) {
  const items = [
    {
      key: "1",
      label: props.label1,
      children: props.children1,
      disabled: props.disabled1,
    },
    {
      key: "2",
      label: props.label2,
      children: props.children2,
      disabled: props.disabled2,
    },
    {
      key: "3",
      label: props.label3,
      children: props.children3,
      disabled: props.disabled3,
    },
    {
      key: "4",
      label: props.label4,
      children: props.children4,
    },
    {
      key: "5",
      label: props.label5,
      children: props.children5,
    },
    {
      key: "6",
      label: props.label6,
      children: props.children6,
    },
  ];
  return (
    <div className="tagHeader-container">
      <p className="tagHeader-title">{props.title}</p>
      <Tabs
        className="tagHeader-tag"
        defaultActiveKey={props.defaultActiveKey}
        items={items}
        tabBarExtraContent={{
          right: (
            <Button
              style={{ display: props.displayRightButton }}
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={props.handleCreateNew}
            >
              Tạo mới
            </Button>
          ),
        }}
      />
    </div>
  );
}

export default TagHeader;
